<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import BooksStore from "@/modules/books/books-store";
import SchoolsStore from "@/modules/schools/schools-store";
import SchoolsContractsStore from "@/modules/schools-contracts/schools-contracts-store";
import BudgetsStore from "@/modules/budgets/budgets-store";
import Swal from "sweetalert2";

export default {
  data: () => {
    return {
      title: "Novo pedido",
      items: [
        {
          text: "Genesis",
          href: "/",
        },
        {
          text: "pedidos",
          active: true,
        },
      ],

      escolas: [],
      escola: null,
      optionsEscolas: [],
      optionsContratos: [],
      escolaSelecionada: null,
      livros: [],
      carrinho: [],
      contratos: [],
      isLoadLivros: false,
      perfil: false,

      form: {
        id_peoples: null,
        id_schools: null,
        id_schools_contracts: null,
        title: null,
        description: null,
        budgets_address: {
          address: null,
          address2: null,
          number: null,
          complement: null,
          neighborhood: null,
          zipcode: null,
        },
        budgets_books: []
      }

    }
  },
  components: {Layout, PageHeader},
  mounted() {
    const perfilJson = localStorage.getItem("perfil");
    this.perfil = JSON.parse(atob(perfilJson));

    this.load();
  },
  methods: {
    async load() {
      await this.loadLivros();
      await this.loadEscolas();
    },
    async loadEscolas() {
      this.isLoadLivros = true;

      await SchoolsStore.paginate({
        id_people: this.perfil.id_people,
        limit: 10000
      }).then((response) => {
        const {data} = response.data;
        this.escolas = data;
        this.optionsEscolas = data.map((escola) => {
          return {
            value: escola.id,
            text: escola.name
          }
        });

      }).catch((error) => {
        console.log(error);
      }).finally(() => {

      });


      await SchoolsContractsStore.all({
        id_people: this.perfil.id_people
      }).then((response) => {
        const {data} = response.data;
        this.contratos = data;
        this.optionsContratos = data.filter(x => x.status == 'publish').map((contrato) => {
          return {
            value: contrato.id,
            text: `${contrato.id} - ${contrato.schools.name}`
          }
        });
        this.isLoadLivros = false;
      }).catch((error) => {
        console.log(error);
        this.isLoadLivros = false;
      })

    },
    async loadLivros() {
      this.isLoadLivros = true;
      await BooksStore.all().then(response => {
        this.livros = response.data.data;
        this.livros = this.livros.map(livro => {
          return {
            ...livro,
            quantidade: 1
          }
        });
        this.isLoadLivros = false;
      }).catch(error => {
        console.log(error);
        this.isLoadLivros = false;
      });
    },
    adicionarLivro(livro, valor) {
      const livroCopy = JSON.parse(JSON.stringify(livro));
      livroCopy.valor = valor;
      this.carrinho.push(livroCopy);
    },
    removerLivro(livro) {
      this.carrinho = this.carrinho.filter(l => l !== livro);
    },
    incrementarQuantidade(livro) {
      livro.quantidade++;
    },
    decrementarQuantidade(livro) {
      if (livro.quantidade > 1) {
        livro.quantidade--;
      }
    },
    limparPedido() {
      this.carrinho = [];
      this.form = {
        id_peoples: null,
        id_schools: null,
        id_schools_contracts: null,
        title: null,
        description: null,
        budgets_address: {
          address: null,
          address2: null,
          number: null,
          complement: null,
          neighborhood: null,
          zipcode: null,
        },
        budgets_books: []
      }
      try {
        this.escola = this.escolas.find(e => e.id === this.escolaSelecionada);
      } catch (e) {
        this.escola = null;
      }

      try {
        this.form.budgets_address.address = this.escola.schools_infos.find(si => si.key == 'school_address').value;
      } catch (e) {
        this.form.budgets_address.address = null;
      }
      try {
        this.form.budgets_address.neighborhood = this.escola.schools_infos.find(si => si.key == 'school_neighborhood').value;
      } catch (e) {
        this.form.budgets_address.neighborhood = null;
      }
      try {
        this.form.budgets_address.address2 = this.escola.schools_infos.find(si => si.key == 'school_city').value + ' - ' + this.escola.schools_infos.find(si => si.key == 'school_state').value;
      } catch (e) {
        this.form.budgets_address.address2 = null;
      }

      //school_zip_code
      try {
        this.form.budgets_address.zipcode = this.escola.schools_infos.find(si => si.key == 'school_zip_code').value;
      } catch (e) {
        this.form.budgets_address.zipcode = null;
      }

      //school_complement

      try {
        this.form.budgets_address.complement = this.escola.schools_infos.find(si => si.key == 'school_complement').value;
      } catch (e) {
        this.form.budgets_address.complement = null;
      }

      //school_number

      try {
        this.form.budgets_address.number = this.escola.schools_infos.find(si => si.key == 'school_number').value;
      } catch (e) {
        this.form.budgets_address.number = null;
      }


    },
    totalPedido() {
      try {
        return parseFloat(this.carrinho.reduce((acc, item) => {
          return acc + (parseFloat(item.valor) * parseInt(item.quantidade));
        }, 0)).toFixed(2);
      } catch (e) {
        return parseFloat(0).toFixed(2);
      }
    },
    finalizarPedido(e) {
      e.preventDefault();
      console.log(this.carrinho);
      this.form.id_peoples = this.perfil.id_people;
      this.form.id_schools = this.escolaSelecionada;
      this.form.budgets_books = this.carrinho.map(item => {
        return {
          id_books: item.id,
          dtq: item.quantidade,
          value: item.valor
        }
      });
      console.log(this.form);
      this.isLoadLivros = true;
      BudgetsStore.create(this.form).then((response) => {
        console.log(response);
        Swal.fire({
          icon: "success",
          title: "Pedido criado",
          text: "Pedido criado com sucesso",
        }).then(() => {
          this.$router.push("/cliente/pedidos");
        });
        this.limparPedido()
        this.isLoadLivros = false;
      }).catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Erro ao criar pedido",
          text: "Erro ao criar pedido",
        });
        this.isLoadLivros = false;
      }).finally(() => {

      });

    },
    obterValor(livro) {
      try {
        const escola = this.escolas.find(e => e.id === this.escolaSelecionada);
        const books_values = livro.books_values.find(bv => bv.id_schools_types === escola.id_type);
        return parseFloat(parseFloat(books_values.value).toFixed(2)).toFixed(2);
      } catch (e) {
        return 0;
      }
    }

  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>


    <b-overlay :show="isLoadLivros">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h1>{{ title }}</h1>

              <br>
              <hr>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <h3>Selecione uma escola</h3>
                    <b-form-select @change="limparPedido" v-model="escolaSelecionada" :options="optionsEscolas">
                    </b-form-select>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <br>
          <br>


        </div>
      </div>
      <div class="row">
        <div class="col-8">
          <div class="card">
            <div class="card-body">

              <div v-if="!escolaSelecionada">
                <h3>Selecione uma escola</h3>
              </div>
              <div v-else>
                <h3>Livros disponíveis</h3>


                <div class="row">
                  <template v-for="livro in livros">
                    <div v-if="obterValor(livro)" class="col" :key="livro.id">
                      <div class="card">
                        <div class="card-body">
                          <img :src="livro.cover" width="200" alt="" class="float-left mr-5" style="margin-bottom: 40px">
                          <h2>{{ livro.label }}</h2>
                          <p>{{ livro.bookschool }}</p>
                          <p><strong class="fa-2x">Valor:</strong> <span class="fa-3x">R$ {{ obterValor(livro).replaceAll('.', ',') }}</span></p>
                          <div class="add-box">
                            <strong class="fa-2x">Quantidade: </strong>
                            <span class="add menos" @click="decrementarQuantidade(livro)">-</span>
                            <input type="text" placeholder="1" v-model="livro.quantidade">
                            <span class="add mais" @click="incrementarQuantidade(livro)">+</span>
                          </div>
                          <button type="button" @click="adicionarLivro(livro,obterValor(livro))" class="btn btn-primary">Adicionar ao carrinho</button>
                        </div>
                      </div>

                    </div>
                  </template>

                </div>
              </div>


            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="card">
            <div class="card-body">
              <h3>Itens do novo pedido</h3>
              <br>
              <hr>
              <br>

              <template v-for="item in carrinho">
                <div class="card" :key="item.id+'carrinoh'">
                  <div class="card-body">
                    <img :src="item.cover" width="70" alt="" class="float-left mr-3">
                    <h3>{{ item.label }}</h3>
                    <div class="row">
                      <div class="col"><br>
                        <p><strong class="fa-1x">Valor:</strong> <span class="fa-2x">R$ {{ item.valor.replaceAll('.', ',') }}</span></p></div>
                      <div class="col">
                        <div class="add-box">
                          <span class="add menos" @click="decrementarQuantidade(item)">-</span>
                          <input type="text" placeholder="1" v-model="item.quantidade">
                          <span class="add mais" @click="incrementarQuantidade(item)">+</span>
                        </div>
                      </div>
                      <div class="col-1">
                        <br>
                        <button @click="removerLivro(item)" class="btn btn-outline-danger"><i class="fa fa-trash"></i></button>
                      </div>
                    </div>


                  </div>
                </div>
              </template>

              <br>
              <h3>Total: R$ {{ totalPedido().replaceAll('.', ',') }}</h3>


              <b-form @submit="finalizarPedido">
                <br>

                <h5>Pedido</h5>

                <b-form-group label="Nome do seu novo pedido">
                  <b-form-input v-model="form.title" required></b-form-input>
                </b-form-group>

                <b-form-group label="Descrição do seu novo pedido">
                  <b-form-textarea v-model="form.description" required></b-form-textarea>
                </b-form-group>

                <hr>
                <h5>Contrato</h5>

                <b-form-group label="Escola">
                  <b-form-select @change="limparPedido" required v-model="escolaSelecionada" :options="optionsEscolas">
                  </b-form-select>
                </b-form-group>

                <b-form-group label="Contrato">
                  <b-form-select required v-model="form.id_schools_contracts" :options="optionsContratos">
                  </b-form-select>
                </b-form-group>
                <hr>
                <h5>Endereço</h5>

                <b-form-group label="Endereço">
                  <b-form-input v-model="form.budgets_address.address" required></b-form-input>
                </b-form-group>

                <b-form-group label="Número">
                  <b-form-input v-model="form.budgets_address.number" required></b-form-input>
                </b-form-group>

                <b-form-group label="Complemento">
                  <b-form-input v-model="form.budgets_address.complement" required></b-form-input>
                </b-form-group>

                <b-form-group label="Bairro">
                  <b-form-input v-model="form.budgets_address.neighborhood" required></b-form-input>
                </b-form-group>

                <b-form-group label="Cidade/Estado">
                  <b-form-input v-model="form.budgets_address.address2" required></b-form-input>
                </b-form-group>

                <b-form-group label="CEP">
                  <b-form-input v-model="form.budgets_address.zipcode" required></b-form-input>
                </b-form-group>


                <button class="btn btn-success btn-block btn-lg">Finalizar pedido</button>

              </b-form>


            </div>
          </div>
        </div>
      </div>
    </b-overlay>

  </Layout>
</template>

<style scoped lang="scss">
.add-box {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;

  .add {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    margin: 0 10px;
  }

  input {
    width: 50px;
    height: 50px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
  }
}

</style>
