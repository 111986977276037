
<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import TaxonomiesTypesStore from "@/modules/taxonomies-types/taxonomies-types-store";
import TaxonomiesTypesPaginate from "@/components/paginate.vue";
import Swal from "sweetalert2";
import TaxonomiesTypesDelete from "@/modules/taxonomies-types/components/taxonomies-types-delete.vue";
import TaxonomiesTypesRemove from "@/modules/taxonomies-types/components/taxonomies-types-remove.vue";
import TaxonomiesTypesShow from "@/modules/taxonomies-types/components/taxonomies-types-show.vue";

export default {
  name: "TaxonomiesTypes",
  data() {
    return {
      title: "Tipos de taxonomias",
      single: "Tipo de taxonomia",
      items: [
        {text: "Home", to: {name: "home"}},
        {text: "Tipos de taxonomias", active: true},
      ],
      table: [
        {label: "ID", key: "id"},
        {label: "Nome", key: "label_singular"},
        {label: "Identificador", key: "type"},
        {label: "Criação", key: "created_at"},
        {label: "Atualização", key: "updated_at"},
        {label: "...", key: "btn"},
      ],
      list: [],
      showNewForm: false,
      loadingForm: false,
      paginate: {
        total_pages: 1,
        page: 1,
        perPage: 10,
        total: 0,
      },
      loadingPage: false,
      buscar: "",
      form: {
        id: null,
        id_user: null,
        label_singular: null,
        label_plural: null,
        type: null,
      }
    }
  },
  components: {TaxonomiesTypesShow, TaxonomiesTypesRemove, TaxonomiesTypesDelete, TaxonomiesTypesPaginate, Layout, PageHeader},
  mounted() {
    this.getPaginate(this.paginate.page);
  },
  methods: {
    getPaginate(e = 1) {
      this.paginate.page = e;
      this.loadingPage = true;
      TaxonomiesTypesStore.paginate({
        page: this.paginate.page,
        limit: this.paginate.perPage,
        search: this.buscar,
      }).then((response) => {
        const {data, limit, page, total, total_pages} = response.data;
        this.list = data;
        this.paginate.total = total;
        this.paginate.perPage = limit;
        this.paginate.page = page;
        this.paginate.total_pages = total_pages;
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.loadingPage = false;
      });
    },
    cleanForm() {
      this.form = {
        id: null,
        name: null,
        iduuid: null,
      }
    },
    edit(item) {
      this.form = item;
      this.showNewForm = true;
    },
    save(event) {
      event.preventDefault();
      this.loadingForm = true;
      if (this.form.id) {
        TaxonomiesTypesStore.update(this.form).then((response) => {
          this.form = response.data;
          this.getPaginate(1);
          this.cleanForm();
          this.showNewForm = false;
          Swal.fire({
            icon: "success",
            title: "Atualizado",
            text: "TaxonomiesTypes atualizada com sucesso",
          });
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao atualizar",
            text: "Erro ao atualizar a escola",
          });
        }).finally(() => {
          this.loadingForm = false;
        });
      } else {
        TaxonomiesTypesStore.create(this.form).then((response) => {
          this.form = response.data;
          this.getPaginate(1);
          this.cleanForm();
          this.showNewForm = false;
          Swal.fire({
            icon: "success",
            title: "Criado",
            text: "TaxonomiesTypes criada com sucesso",
          });
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao criar",
            text: "Erro ao criar a escola",
          });
        }).finally(() => {
          this.loadingForm = false;
        });
      }
    }
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <b-overlay :show="loadingPage">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h1>{{ title }}</h1>

              <div class="row mb-4 align-items-center">
                <div class="col-md-5">
                  <div class="row align-items-center">
                    <div class="col-md-6">
                      <input
                          v-model="buscar"
                          type="text"
                          placeholder="Pesquisar...."
                          class="form-control"
                          @change="getPaginate(1)"
                      />
                      <span class="d-block d-md-none"></span>
                    </div>
                    <div class="col-6 col-md-3">
                      <button @click="getPaginate(1)" type="button" class="btn btn-light btn-block">
                        <i class="fa fa-search"></i> Buscar
                      </button>
                    </div>
                    <div class="col-6 col-md-3">
                      <button @click="buscar=null; getPaginate(1) " class="btn btn-light btn-block">
                        <i class="fa fa-broom"></i> Limpar
                      </button>
                    </div>
                  </div>
                </div>

                <div class="col-md-7 mb-2 text-right align-items-center">
                  <span class="d-block d-md-none p-2"> </span>
                  <div>
                    <button class="btn btn-primary" @click="showNewForm = true">
                      <i class="fa fa-plus"></i>
                      <span v-if="form.id">Editando</span>
                      <span v-if="!form.id">Novo</span>
                    </button>
                  </div>
                  <div v-show="showNewForm">
                    <div class="box-form text-left">
                      <div id="form-novo-edit">
                        <i @click="showNewForm = false; cleanForm();" class="fa float-right fa-2x fa-eye-slash"></i>
                        <h1 class="mt-5 mb-5" v-if="!form.id">Nova {{ single }}</h1>
                        <h1 class="mt-5 mb-5" v-if="form.id">Editando {{ single }}</h1>
                        <div>
                          <b-overlay :show="loadingForm">
                            <b-form @submit="save" class="was-validated">

                              <b-form-group label="Nome do tipo de taxonomia" label-for="input-1" description="Nome do tipo de taxonomia: Editar">
                                <b-form-input id="input-1" v-model="form.type" required></b-form-input>
                              </b-form-group>
                              <b-form-group label="Rótulo singular" label-for="input-1" description="Rótulo singular: rótulo">
                                <b-form-input id="input-1" v-model="form.label_singular" required></b-form-input>
                              </b-form-group>
                              <b-form-group label="Rótulo plural" label-for="input-1" description="Rótulo plural: rótulos">
                                <b-form-input id="input-1" v-model="form.label_plural" required></b-form-input>
                              </b-form-group>


                              <b-button type="submit" variant="primary">
                                <span v-if="form.id">Salvar alterações</span>
                                <span v-if="!form.id">Cadastrar novo</span>
                              </b-button>
                            </b-form>
                          </b-overlay>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <taxonomies-types-paginate :paginate="paginate" @change-page="getPaginate"></taxonomies-types-paginate>
              <b-table striped hover :items="list" :fields="table">
                <template v-slot:cell(updated_at)="data">
                  {{ data.item.created_at | f_date_time }}
                </template>
                <template v-slot:cell(created_at)="data">
                  {{ data.item.created_at | f_date_time }}
                </template>
                <template v-slot:cell(btn)="data">
                  <button @click="edit(data.item)"  class="btn pl-4 pr-4 btn-sm btn-info" ><i class="fa fa-edit"></i> Editar </button>
                  <taxonomies-types-delete v-if="data.item.deleted_at" :value="data.item" @change="getPaginate(1)"></taxonomies-types-delete>
                  <taxonomies-types-remove v-if="data.item.deleted_at == null" :value="data.item" @change="getPaginate(1)"></taxonomies-types-remove>
                  <taxonomies-types-show :value="data.item"></taxonomies-types-show>
                </template>
              </b-table>
              <taxonomies-types-paginate :paginate="paginate" @change-page="getPaginate"></taxonomies-types-paginate>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </Layout>
</template>

<style scoped>

</style>

