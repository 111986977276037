
import SchoolsContractsService from "@/modules/schools-contracts/schools-contracts-service";

export default {
    create(payload) {
        return new Promise((resolve, reject) => {
            SchoolsContractsService.create(payload).then(response => {
                resolve({
                    message: "SchoolsContracts criada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao criar SchoolsContracts",
                    success: false,
                    error
                });
            })
        })
    },
    update(payload) {
        console.log('update contrato', payload)
        return new Promise((resolve, reject) => {
            SchoolsContractsService.update(payload.id,{
                id: payload.id,
                id_schools: payload.id_schools,
                id_contracts: payload.id_contracts,
                id_peoples: payload.id_peoples,
                id_schoolS_contracts: payload.id_schoolS_contracts,
                id_schoolS_contracts_final: payload.id_schoolS_contracts_final,
                version: payload.version,
                description: payload.description,
                status: payload.status,
                date_start: payload.date_start,
                date_stop: payload.date_stop,
            }).then(response => {
                resolve({
                    message: "SchoolsContracts atualizada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao atualizar SchoolsContracts",
                    success: false,
                    error
                });
            })
        })
    },
    remove(id) {
        return new Promise((resolve, reject) => {
            SchoolsContractsService.remove(id).then(response => {
                resolve({
                    message: "SchoolsContracts removida com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao remover SchoolsContracts",
                    success: false,
                    error
                });
            })
        })
    },
    delete(id) {
        return new Promise((resolve, reject) => {
            SchoolsContractsService.delete(id).then(response => {
                resolve({
                    message: "SchoolsContracts deletada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao deletar SchoolsContracts",
                    success: false,
                    error
                });
            })
        })
    },
    get(id) {
        return new Promise((resolve, reject) => {
            SchoolsContractsService.get(id).then(response => {
                resolve({
                    message: "SchoolsContracts encontrada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar SchoolsContracts",
                    success: false,
                    error
                });
            })
        })
    },
    paginate(query) {
        return new Promise((resolve, reject) => {
            SchoolsContractsService.paginate(query).then(response => {
                resolve({
                    message: "SchoolsContracts encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar SchoolsContracts",
                    success: false,
                    error
                });
            })
        })
    },
    all(query) {
        return new Promise((resolve, reject) => {
            SchoolsContractsService.all(query).then(response => {
                resolve({
                    message: "SchoolsContracts encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar SchoolsContracts",
                    success: false,
                    error
                });
            })
        })
    }
}
