var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',[_c('div',{staticClass:"container-fluid p-0"},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"authentication-page-content p-4 d-flex align-items-center min-vh-100"},[_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-9"},[_c('div',[_vm._m(1),_c('div',{staticClass:"p-2 mt-5"},[_c('b-alert',{staticClass:"mt-3",attrs:{"variant":"success","dismissible":""},model:{value:(_vm.send),callback:function ($$v) {_vm.send=$$v},expression:"send"}},[_vm._v("Solicitação fetia com sucesso, verifique seu email.")]),_c('b-alert',{staticClass:"mb-4",attrs:{"variant":"danger","dismissible":""},model:{value:(_vm.isResetError),callback:function ($$v) {_vm.isResetError=$$v},expression:"isResetError"}},[_vm._v(_vm._s(_vm.error))]),_c('form',{staticClass:"form-horizontal",on:{"submit":function($event){$event.preventDefault();return _vm.tryToReset.apply(null, arguments)}}},[_c('div',{staticClass:"form-group auth-form-group-custom mb-4"},[_c('i',{staticClass:"ri-mail-line auti-custom-input-icon"}),_c('label',{attrs:{"for":"useremail"}},[_vm._v("E-mail")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-control",class:{
                              'is-invalid': _vm.submitted && _vm.$v.email.$error,
                            },attrs:{"type":"email","id":"useremail","placeholder":""},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}}),(_vm.submitted && _vm.$v.email.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.email.required)?_c('span',[_vm._v("Email is required.")]):_vm._e(),(!_vm.$v.email.email)?_c('span',[_vm._v("Please enter valid email.")]):_vm._e()]):_vm._e()]),_vm._m(2),_c('div',{staticClass:"mt-4 text-center"},[_c('router-link',{staticClass:"text-muted",attrs:{"to":"/login"}},[_c('i',{staticClass:"mdi mdi-lock mr-1"}),_vm._v(" Voltar para login ")])],1)])],1),_c('div',{staticClass:"mt-5 text-center"},[_c('p',[_vm._v("© "+_vm._s(_vm.ano)+" Gestio School.")])])])])])])])]),_vm._m(3)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-btn d-none d-sm-block"},[_c('a',{attrs:{"href":"/"}},[_c('i',{staticClass:"mdi mdi-home-variant h2 text-white"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('div',[_c('a',{staticClass:"logo",attrs:{"href":"/"}},[_c('img',{attrs:{"src":require("@/assets/images/logo-dark.png"),"height":"20","alt":"logo"}})])]),_c('h4',{staticClass:"font-size-18 mt-4"},[_vm._v("Reconfigurar senha")]),_c('p',{staticClass:"text-muted"},[_vm._v(" Informe seu e-mail abaixo para receber o e-mail com o link e código para configurar uma nova senha. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4 text-center"},[_c('button',{staticClass:"btn btn-primary w-md waves-effect waves-light",attrs:{"type":"submit"}},[_vm._v(" Receber e-mail ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-8"},[_c('div',{staticClass:"authentication-bg"},[_c('div',{staticClass:"bg-overlay"})])])
}]

export { render, staticRenderFns }