<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import BooksPagesStore from "@/modules/books-pages/books-pages-store";
import BooksStore from "@/modules/books/books-store";
import BooksPagesPaginate from "@/components/paginate.vue";
import Swal from "sweetalert2";
import BooksPagesDelete from "@/modules/books-pages/components/books-pages-delete.vue";
// import BooksPagesRemove from "@/modules/books-pages/components/books-pages-remove.vue";
import BooksPagesShow from "@/modules/books-pages/components/books-pages-show.vue";

export default {
  name: "Páginas de Livros",
  data() {
    return {
      title: "Páginas de Livros",
      single: "Página de Livro",
      items: [
        {text: "Home", to: {name: "home"}},
        {text: "Páginas de Livros", active: true},
      ],
      table: [
        {label: "ID", key: "id"},
        {label: "Livro", key: "books.label"},
        {label: "Pagina", key: "page"},
        {label: "Imagem", key: "image"},
        {label: "Texto", key: "text"},
        {label: "Criação", key: "created_at"},
        {label: "Atualização", key: "updated_at"},
        {label: "...", key: "btn"},
      ],
      list: [],
      showNewForm: false,
      loadingForm: false,
      paginate: {
        total_pages: 1,
        page: 1,
        perPage: 10,
        total: 0,
      },
      loadingPage: false,
      buscar: "",
        filtro_id_books: null,
      form: {
        id: null,
        id_books: null,
        page: null,
        image: null,
        text: null,
      },
      optionsBooks: []
    }
  },
  components: {BooksPagesShow, /*BooksPagesRemove,*/ BooksPagesDelete, BooksPagesPaginate, Layout, PageHeader},
  mounted() {
    this.getPaginate(this.paginate.page);
    this.getBooks();
  },
  watch: {
    'form.name': function (newValue) {
      this.form.identifier = this.$slug_format(newValue);
    }
  }, methods: {
    handlerUpload(event){
      this.form.image = event.target.files[0];
      console.log(this.form.image);
    },
    getBooks() {
      BooksStore.all().then((response) => {
          this.optionsBooks = [{value: null, text: "Selecione um Livro"}];
        response.data.data.forEach((item) => {
          this.optionsBooks.push({value: item.id, text: item.label});
        });
      }).catch((error) => {
        console.log(error);
      });
    },
    getPaginate(e = 1) {
      this.paginate.page = e;
      this.loadingPage = true;
      BooksPagesStore.paginate({
          id_books: this.filtro_id_books,
        page: this.paginate.page,
        limit: this.paginate.perPage,
        search: this.buscar,
      }).then((response) => {
        const {data, limit, page, total, total_pages} = response.data;
        this.list = data;
        this.paginate.total = total;
        this.paginate.perPage = limit;
        this.paginate.page = page;
        this.paginate.total_pages = total_pages;
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.loadingPage = false;
      });
    },
    cleanForm() {
      this.form = {
        id: null,
        id_books: null,
        page: null,
        image: null,
        text: null,
      }
    },
    edit(item) {
      this.form = JSON.parse(JSON.stringify(item));
      this.form.imagem_show = this.form.image;
      delete this.form.image;
      this.showNewForm = true;
    },
    save(event) {
      event.preventDefault();
      this.loadingForm = true;

      const formData = new FormData();
      if (this.form.id) {
        formData.append("id", this.form.id);
      }
      formData.append("id_books", this.form.id_books);
      formData.append("page", this.form.page);
      formData.append("text", this.form.text);
      if (this.form.image) {
        formData.append("image", this.form.image);
      }

      if (this.form.id) {
        BooksPagesStore.update(this.form.id,formData).then((response) => {
          this.form = response.data;
          this.getPaginate(1);
          this.cleanForm();
          this.showNewForm = false;
          Swal.fire({
            icon: "success",
            title: "Atualizado",
            text: "Página de Livro atualizada com sucesso",
          });
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao atualizar",
            text: "Erro ao atualizar a Página de Livro",
          });
        }).finally(() => {
          this.loadingForm = false;
        });
      } else {
        BooksPagesStore.create(formData).then((response) => {
          this.form = response.data;
          this.getPaginate(1);
          this.cleanForm();
          this.showNewForm = false;
          Swal.fire({
            icon: "success",
            title: "Criado",
            text: "Página de Livro criada com sucesso",
          });
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao criar",
            text: "Erro ao criar a Página de Livro",
          });
        }).finally(() => {
          this.loadingForm = false;
        });
      }
    }
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <b-overlay :show="loadingPage">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h1>{{ title }}</h1>

              <div class="row mb-4 align-items-center">
                <div class="col-md-5">
                  <div class="row align-items-center">
                      <div class="col-md-3">
                      <input
                          v-model="buscar"
                          type="text"
                          placeholder="Pesquisar...."
                          class="form-control"
                          @change="getPaginate(1)"
                      />
                      <span class="d-block d-md-none"></span>
                    </div>
                    <div class="col-6 col-md-3">
                        <b-form-select id="input-0" @input="getPaginate(1)" v-model="filtro_id_books" :options="optionsBooks"></b-form-select>
                    </div>
                      <div class="col-6 col-md-3">
                      <button @click="getPaginate(1)" type="button" class="btn btn-light btn-block">
                        <i class="fa fa-search"></i> Buscar
                      </button>
                    </div>
                    <div class="col-6 col-md-3">
                        <button @click="buscar=null; filtro_id_books =null; getPaginate(1) " class="btn btn-light btn-block">
                        <i class="fa fa-broom"></i> Limpar
                      </button>
                    </div>
                  </div>
                </div>

                <div class="col-md-7 mb-2 text-right align-items-center">
                  <span class="d-block d-md-none p-2"> </span>
                  <div>
                    <button class="btn btn-primary" @click="showNewForm = true">
                      <i class="fa fa-plus"></i>
                      <span v-if="form.id">Editando</span>
                      <span v-if="!form.id">Novo</span>
                    </button>
                  </div>
                  <div v-show="showNewForm">
                    <div class="box-form text-left">
                      <div id="form-novo-edit">
                        <i @click="showNewForm = false; cleanForm();" class="fa float-right fa-2x fa-eye-slash"></i>
                        <h1 class="mt-5 mb-5" v-if="!form.id">Nova {{ single }}</h1>
                        <h1 class="mt-5 mb-5" v-if="form.id">Editando {{ single }}</h1>
                        <div>
                          <b-overlay :show="loadingForm">
                            <b-form @submit="save" class="was-validated">

                              <b-form-group label="Livro" label-for="input-0" description="Livro dona da página">
                                <b-form-select id="input-0" v-model="form.id_books" :options="optionsBooks" required></b-form-select>
                              </b-form-group>

                              <!--                              page-->


                              <b-form-group label="Número Página" label-for="input-1" description="número da página ">
                                <b-form-input type="number" id="input-1" v-model="form.page" required></b-form-input>
                              </b-form-group>
                              <!--                              image-->
                              <b-form-group label="Imagem" label-for="input-1" description="imagem da página">
                                <b-form-file @change="handlerUpload" v-model="form.image" ></b-form-file>
                                <img width="100" :src="form.imagem_show" alt="">
                              </b-form-group>
                              <!--                              text-->


                              <b-form-group label="Descrição/Resuma da página" label-for="input-2" description="descrição">
                                <b-form-textarea id="input-2" v-model="form.text" required></b-form-textarea>
                              </b-form-group>
                              <b-button type="submit" variant="primary">
                                <span v-if="form.id">Salvar alterações</span>
                                <span v-if="!form.id">Cadastrar novo</span>
                              </b-button>
                            </b-form>
                          </b-overlay>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <books-pages-paginate :paginate="paginate" @change-page="getPaginate"></books-pages-paginate>
               <b-table striped hover :items="list" :fields="table" class="table-responsive">
                <template v-slot:cell(updated_at)="data">
                  {{ data.item.created_at | f_date_time }}
                </template>
                <template v-slot:cell(image)="data">
                  <a :href="data.item.image" target="_blank">
                    <img height="50" :src="data.item.image" alt="">
                  </a>
                </template>
                <template v-slot:cell(created_at)="data">
                  {{ data.item.created_at | f_date_time }}
                </template>
                <template v-slot:cell(btn)="data">
                  <button @click="edit(data.item)" class="btn pl-4 pr-4 btn-sm btn-info"><i class="fa fa-edit"></i> Editar</button>
                  <books-pages-delete :value="data.item" @change="getPaginate(1)"></books-pages-delete>
                  <!--                  <books-pages-remove :value="data.item" @change="getPaginate(1)"></books-pages-remove>-->
                  <books-pages-show :value="data.item"></books-pages-show>
                </template>
              </b-table>
              <books-pages-paginate :paginate="paginate" @change-page="getPaginate"></books-pages-paginate>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </Layout>
</template>

<style scoped>

</style>

