<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import PostsStore from "@/modules/posts/posts-store";
import PostsPaginate from "@/components/paginate.vue";
import Swal from "sweetalert2";
import PostsDelete from "@/modules/posts/components/posts-delete.vue";
import PostsRemove from "@/modules/posts/components/posts-remove.vue";
import PostsShow from "@/modules/posts/components/posts-show.vue";
import slugify from "slugify";

export default {
  name: "Posts",
  data() {
    return {
      title: "Posts",
      single: "Posts",
      items: [
        {text: "Home", to: {name: "home"}},
        {text: "Posts", active: true},
      ],
      table: [
        {label: "ID", key: "id"},
        {label: "Nome", key: "name"},
        {label: "Identificador", key: "iduuid"},
        {label: "Criação", key: "created_at"},
        {label: "Atualização", key: "updated_at"},
        {label: "...", key: "btn"},
      ],

      options: [
        {value: "ON", text: "Ativo"},
        {value: "OFF", text: "Inativo"},
      ],
      list: [],
      showNewForm: false,
      loadingForm: false,
      paginate: {
        total_pages: 1,
        page: 1,
        perPage: 10,
        total: 0,
      },
      loadingPage: false,
      buscar: "",
      post_type: null,
      form: {
        id: null,
        icon: null,
        color: null,
        order: 0,
        id_user: null,
        id_post_parent: null,
        content: null,
        name: null,
        slug: null,
        subname: null,
        id_post_type: null,
        tags: [],
        excerpt: null,
        image: null,
        video: null,
        audio: null,
        status: "ON",
        seo_description: null,
        seo_keys_words: null,
        seo_name: null,
      },
      type: null,
      configEditor : {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],
            ['link', 'image', 'video', 'formula'],

            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
            [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction

            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            // [{ 'font': [] }],
            [{ 'align': [] }],

            ['clean']                                         // remove formatting button
          ],
        },
        placeholder: 'Digite aqui...',
        theme: 'snow'
      }
    }
  },
  components: {PostsShow, PostsRemove, PostsDelete, PostsPaginate, Layout, PageHeader},
  mounted() {
    this.type = this.$route.params.type;
    const id = localStorage.getItem("post_type_" + this.type);
    this.form.id_post_type = id;
    const label = localStorage.getItem("post_type_label_" + this.type);
    this.title = label;
    this.single = label;

    this.getPaginate(this.paginate.page);
  },
  watch: {
    "form.name": function (newVal, oldVal) {
      this.form.slug = slugify(newVal);
    },
  },
  methods: {
    getPaginate(e = 1) {
      this.paginate.page = e;
      this.loadingPage = true;
      PostsStore.paginate({
        page: this.paginate.page,
        limit: this.paginate.perPage,
        search: this.buscar,
      }).then((response) => {
        const {data, limit, page, total, total_pages} = response.data;
        this.list = data;
        this.paginate.total = total;
        this.paginate.perPage = limit;
        this.paginate.page = page;
        this.paginate.total_pages = total_pages;
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.loadingPage = false;
      });
    },
    cleanForm() {
      this.form = {
        id: null,
        icon: null,
        color: null,
        order: 0,
        id_user: null,
        id_post_parent: null,
        content: null,
        name: null,
        slug: null,
        subname: null,
        id_post_type: null,
        tags: [],
        excerpt: null,
        image: null,
        video: null,
        audio: null,
        status: "ON",
        seo_description: null,
        seo_keys_words: null,
        seo_name: null,
      }
    },
    edit(item) {
      this.form = null;
      this.form = JSON.parse(JSON.stringify(item));
      this.form.image_view = this.form.image;
      this.form.image = null;
      this.form.video_view = this.form.video;
      this.form.video = null;
      this.form.audio_view = this.form.audio;
      this.form.audio = null;

      // try{
      //   this.form.content = {
      //     ops: [{insert: this.form.content}]
      //   }
      // }catch (e) {
      //   console.log(e)
      // }
      // try{
      //   this.form.excerpt = {
      //     ops: [{insert: this.form.excerpt}]
      //   }
      // }catch (e) {
      //   console.log(e)
      // }
      // try{
      //   this.form.seo_description = {
      //     ops: [{insert: this.form.seo_description}]
      //   }
      // }catch (e) {
      //   console.log(e)
      // }

      this.showNewForm = true;
      console.log('FORM EDIT: ',this.form);
    },

    save(event) {
      event.preventDefault();
      const id = localStorage.getItem("post_type_" + this.type);
      this.form.id_post_type = id;
      this.form.id_user = 1;
      this.form.order = 1;

      // try{ this.form.content = this.form.content.ops[0].insert; } catch (e) { console.log(e) }
      // try{ this.form.excerpt = this.form.excerpt.ops[0].insert; } catch (e) { console.log(e) }
      // try{ this.form.seo_description = this.form.seo_description.ops[0].insert; } catch (e) { console.log(e) }


      console.log('FORM XXX: ',this.form);
      this.loadingForm = true;
      if (this.form.id) {
        PostsStore.update(this.form).then((response) => {
          this.form = response.data;
          this.getPaginate(1);
          this.cleanForm();
          this.showNewForm = false;
          Swal.fire({
            icon: "success",
            title: "Atualizado",
            text: "Posts atualizada com sucesso",
          });
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao atualizar",
            text: "Erro ao atualizar a escola",
          });
        }).finally(() => {
          this.loadingForm = false;
        });
      } else {
        PostsStore.create(this.form).then((response) => {
          this.form = response.data;
          this.getPaginate(1);
          this.cleanForm();
          this.showNewForm = false;
          Swal.fire({
            icon: "success",
            title: "Criado",
            text: "Posts criada com sucesso",
          });
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao criar",
            text: "Erro ao criar a escola",
          });
        }).finally(() => {
          this.loadingForm = false;
        });
      }
    }
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <b-overlay :show="loadingPage">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h1>{{ title }}</h1>

              <div class="row mb-4 align-items-center">
                <div class="col-md-5">
                  <div class="row align-items-center">
                    <div class="col-md-6">
                      <input
                          v-model="buscar"
                          type="text"
                          placeholder="Pesquisar...."
                          class="form-control"
                          @change="getPaginate(1)"
                      />
                      <span class="d-block d-md-none"></span>
                    </div>
                    <div class="col-6 col-md-3">
                      <button @click="getPaginate(1)" type="button" class="btn btn-light btn-block">
                        <i class="fa fa-search"></i> Buscar
                      </button>
                    </div>
                    <div class="col-6 col-md-3">
                      <button @click="buscar=null; getPaginate(1) " class="btn btn-light btn-block">
                        <i class="fa fa-broom"></i> Limpar
                      </button>
                    </div>
                  </div>
                </div>

                <div class="col-md-7 mb-2 text-right align-items-center">
                  <span class="d-block d-md-none p-2"> </span>
                  <div>
                    <button class="btn btn-primary" @click="showNewForm = true">
                      <i class="fa fa-plus"></i>
                      <span v-if="form.id">Editando</span>
                      <span v-if="!form.id">Novo</span>
                    </button>
                  </div>
                  <div v-show="showNewForm">
                    <div class="box-form text-left">
                      <div id="form-novo-edit">
                        <i @click="showNewForm = false; cleanForm();" class="fa float-right fa-2x fa-eye-slash"></i>
                        <h1 class="mt-5 mb-5" v-if="!form.id">Nova {{ single }}</h1>
                        <h1 class="mt-5 mb-5" v-if="form.id">Editando {{ single }}</h1>
                        <div>
                          <b-overlay :show="loadingForm">
                            <b-form @submit="save" class="was-validated" :key="form">


                              <b-tabs>
                                <b-tab title="Principal" class="p-3">
                                  <div class="form__inputs">
                                    <label for="input-title">Titulo: </label>
                                    <b-form-input

                                        id="input-title"
                                        v-model="form.name"
                                        type="text"
                                        required
                                    ></b-form-input>
                                  </div>

                                  <div class="row justify-content-end mr-4">
                                    <small class="row mr-4">
                                      <strong>url:</strong>
                                      <p class="ml-2" contenteditable="true">
                                        {{ form.slug  }}
                                      </p>
                                    </small>
                                  </div>

                                  <div class="form__inputs">
                                    <label for="input-subtitle">Sub-Titulo: </label>
                                    <b-form-input

                                        id="input-subtitle"
                                        v-model="form.subname"
                                        type="text"
                                        required
                                    ></b-form-input>
                                  </div>

                                  <div class="form__inputs">
                                    <label for="input-status">Status: </label>
                                    <b-form-select

                                        id="input-status"
                                        v-model="form.status"
                                        :options="options"
                                        required
                                    />
                                  </div>

                                  <div class="form__inputs-editor">
                                    <label for="">Descrição: </label>
                                    <div class="editor">

                                      <quill output="html"   :config="configEditor"  v-model="form.content"></quill>




                                    </div>
                                  </div>
                                </b-tab>
                                <b-tab title="Extras" class="p-3">

                                  <template v-if="post_type && post_type.posts_configs">
                                    <div v-for="taxonomy in post_type.posts_configs" :key="taxonomy.id">
                                      <div class="form__inputs">
                                        <label for="input-categories">{{ taxonomy.taxonomies_types.label_plural }}: </label>
                                        <multiselect v-model="form[taxonomy.taxonomies_types.type]" :options="taxonomy.taxonomies_types.options" :multiple="true" label="text" track-by="value" :close-on-select="false" :clear-on-select="false" :preserve-search="true">
                                        </multiselect>

                                      </div>
                                    </div>
                                  </template>
                                  <div class="form__inputs">
                                    <label for="input-categories">Resumo: </label>
                                    <div class="editor">
                                    <quill output="html"  :config="configEditor"  v-model="form.excerpt"></quill>
                                    </div>

                                  </div>

                                  <div class="form__inputs">
                                    <label for="input-categories">Imagem: </label>

                                    <b-form-file
                                        v-model="form.image"
                                        placeholder="Escolha um arquivo ou arraste-o para cá..."
                                        drop-placeholder="Solte o arquivo aqui..."
                                    ></b-form-file>
                                  </div>

                                  <img v-if="form.image_view" :src="form.image_view" width="100" alt="">

                                  <div class="form__inputs">
                                    <label for="input-categories">Vídeo: </label>

                                    <b-form-file
                                        v-model="form.video"
                                        placeholder="Escolha um arquivo ou arraste-o para cá..."
                                        drop-placeholder="Solte o arquivo aqui..."
                                    ></b-form-file>
                                  </div>

                                  <video v-if="form.video_view" width="300" controls>
                                    <source :src="form.video_view" type="video/mp4">
                                  </video>

                                  <div class="form__inputs">
                                    <label for="input-categories">Áudio: </label>

                                    <b-form-file
                                        v-model="form.audio"
                                        placeholder="Escolha um arquivo ou arraste-o para cá..."
                                        drop-placeholder="Solte o arquivo aqui..."
                                    ></b-form-file>


                                  </div>
                                  <audio v-if="form.audio_view" controls>
                                    <source :src="form.audio_view" type="audio/mp3">
                                  </audio>

                                </b-tab>
                                <b-tab title="Configurações" class="p-3">
                                  <div class="form__inputs">
                                    <label for="input-status">Post parente: </label>
                                    <b-form-select
                                        id="input-status"
                                        v-model="form.id_post_parent"


                                    />
                                  </div>

                                  <div class="form__inputs">
                                    <label for="input-status">Usuário: </label>
                                    <b-form-select
                                        id="input-status"
                                        v-model="form.id_user"


                                    />
                                  </div>

                                  <div class="form__inputs">
                                    <label for="input-subtitle">Ordem: </label>
                                    <b-form-input

                                        v-model="form.order"
                                        type="number"

                                    ></b-form-input>
                                  </div>

                                  <div class="form__inputs">
                                    <label for="input-subtitle">Cor: </label>
                                    <b-form-input

                                        v-model="form.color"
                                        type="text"

                                    ></b-form-input>
                                  </div>

                                  <div class="form__inputs">
                                    <label for="input-subtitle">Ícone: </label>
                                    <b-form-input

                                        v-model="form.icon"
                                        type="text"

                                    ></b-form-input>
                                  </div>


                                </b-tab>
                                <b-tab title="SEO" class="p-3">
                                  <div class="form__inputs">
                                    <label for="input-subtitle">Título SEO: </label>
                                    <b-form-input
                                        id="input-subtitle"
                                        v-model="form.seo_name"
                                        type="text"

                                    ></b-form-input>
                                  </div>

                                  <div class="form__inputs">
                                    <label for="input-subtitle">Url/Slug: </label>
                                    <b-form-input
                                        id="input-subtitle"
                                        v-model="form.seo_keys_words"
                                        type="text"

                                    ></b-form-input>
                                  </div>

                                  <div class="form__inputs-editor">
                                    <label for="">Metadescrição: </label>
                                    <div class="editor">
                                      <quill output="html"  :config="configEditor"  v-model="form.seo_description"  ></quill>
                                    </div>
                                  </div>
                                </b-tab>
                              </b-tabs>

                              <b-button type="submit" variant="primary">
                                <span v-if="form.id">Salvar alterações</span>
                                <span v-if="!form.id">Cadastrar novo</span>
                              </b-button>
                            </b-form>
                          </b-overlay>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <posts-paginate :paginate="paginate" @change-page="getPaginate"></posts-paginate>
              <b-table striped hover :items="list" :fields="table">
                <template v-slot:cell(updated_at)="data">
                  {{ data.item.created_at | f_date_time }}
                </template>
                <template v-slot:cell(created_at)="data">
                  {{ data.item.created_at | f_date_time }}
                </template>
                <template v-slot:cell(btn)="data">
                  <button @click="edit(data.item)" class="btn pl-4 pr-4 btn-sm btn-info"><i class="fa fa-edit"></i> Editar</button>
                  <posts-delete :value="data.item" @change="getPaginate(1)"></posts-delete>
                  <posts-remove :value="data.item" @change="getPaginate(1)"></posts-remove>
                  <posts-show :value="data.item"></posts-show>
                </template>
              </b-table>
              <posts-paginate :paginate="paginate" @change-page="getPaginate"></posts-paginate>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </Layout>
</template>

<style >
.editor {
  height: 350px;
}
.editor .segment {
  height: 300px;
}
</style>

