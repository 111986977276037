
<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import BudgetsStepsStore from "@/modules/budgets-steps/budgets-steps-store";
import BudgetsStepsPaginate from "@/components/paginate.vue";
import Swal from "sweetalert2";
import BudgetsStepsDelete from "@/modules/budgets-steps/components/budgets-steps-delete.vue";
import BudgetsStepsRemove from "@/modules/budgets-steps/components/budgets-steps-remove.vue";
import BudgetsStepsShow from "@/modules/budgets-steps/components/budgets-steps-show.vue";

export default {
  name: "Etapas dos pedidos",
  data() {
    return {
      title: "Etapas dos pedidos",
      single: "Etapa do pedido",
      items: [
        {text: "Home", to: {name: "home"}},
        {text: "Etapas dos pedidos", active: true},
      ],
      table: [
        {label: "ID", key: "id"},
        {label: "Etapa", key: "label"},
        {label: "Identificador", key: "identifier"},
        {label: "Numero", key: "number"},
        {label: "Order", key: "order"},
        {label: "Criação", key: "created_at"},
        {label: "Atualização", key: "updated_at"},
        {label: "...", key: "btn"},
      ],
      list: [],
      showNewForm: false,
      loadingForm: false,
      paginate: {
        total_pages: 1,
        page: 1,
        perPage: 10,
        total: 0,
      },
      loadingPage: false,
      buscar: "",
      form: {
        id: null,
        label: null,
        identifier: null,
        number: null,
        order: null,
      }
    }
  },
  components: {BudgetsStepsShow, BudgetsStepsRemove, BudgetsStepsDelete, BudgetsStepsPaginate, Layout, PageHeader},
  mounted() {
    this.getPaginate(this.paginate.page);
  },
  watch: {
    'form.name': function (newValue) {
      this.form.identifier = this.$slug_format(newValue);
    }
  }, methods: {
    getPaginate(e = 1) {
      this.paginate.page = e;
      this.loadingPage = true;
      BudgetsStepsStore.paginate({
        page: this.paginate.page,
        limit: this.paginate.perPage,
        search: this.buscar,
      }).then((response) => {
        const {data, limit, page, total, total_pages} = response.data;
        this.list = data;
        this.paginate.total = total;
        this.paginate.perPage = limit;
        this.paginate.page = page;
        this.paginate.total_pages = total_pages;
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.loadingPage = false;
      });
    },
    cleanForm() {
      this.form = {
        id: null,

        label: null,
        identifier: null,
        number: null,
        order: null,
      }
    },
    edit(item) {
      this.form = JSON.parse(JSON.stringify(item));
      this.showNewForm = true;
    },
    save(event) {
      event.preventDefault();
      this.loadingForm = true;
      this.form.number = parseInt(this.form.number);
      this.form.order = parseInt(this.form.order);
      if (this.form.id) {
        BudgetsStepsStore.update(this.form).then((response) => {
          this.form = response.data;
          this.getPaginate(1);
          this.cleanForm();
          this.showNewForm = false;
          Swal.fire({
            icon: "success",
            title: "Atualizado",
            text: "Etapa do pedido atualizada com sucesso",
          });
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao atualizar",
            text: "Erro ao atualizar",
          });
        }).finally(() => {
          this.loadingForm = false;
        });
      } else {
        BudgetsStepsStore.create(this.form).then((response) => {
          this.form = response.data;
          this.getPaginate(1);
          this.cleanForm();
          this.showNewForm = false;
          Swal.fire({
            icon: "success",
            title: "Criado",
            text: "Etapa do pedido criada com sucesso",
          });
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao criar",
            text: "Erro ao criar",
          });
        }).finally(() => {
          this.loadingForm = false;
        });
      }
    }
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <b-overlay :show="loadingPage">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h1>{{ title }}</h1>

              <div class="row mb-4 align-items-center">
                <div class="col-md-5">
                  <div class="row align-items-center">
                    <div class="col-md-6">
                      <input
                          v-model="buscar"
                          type="text"
                          placeholder="Pesquisar...."
                          class="form-control"
                          @change="getPaginate(1)"
                      />
                      <span class="d-block d-md-none"></span>
                    </div>
                    <div class="col-6 col-md-3">
                      <button @click="getPaginate(1)" type="button" class="btn btn-light btn-block">
                        <i class="fa fa-search"></i> Buscar
                      </button>
                    </div>
                    <div class="col-6 col-md-3">
                      <button @click="buscar=null; getPaginate(1) " class="btn btn-light btn-block">
                        <i class="fa fa-broom"></i> Limpar
                      </button>
                    </div>
                  </div>
                </div>

                <div class="col-md-7 mb-2 text-right align-items-center">
                  <span class="d-block d-md-none p-2"> </span>
                  <div>
                    <button class="btn btn-primary" @click="showNewForm = true">
                      <i class="fa fa-plus"></i>
                      <span v-if="form.id">Editando</span>
                      <span v-if="!form.id">Novo</span>
                    </button>
                  </div>
                  <div v-show="showNewForm">
                    <div class="box-form text-left">
                      <div id="form-novo-edit">
                        <i @click="showNewForm = false; cleanForm();" class="fa float-right fa-2x fa-eye-slash"></i>
                        <h1 class="mt-5 mb-5" v-if="!form.id">Nova {{ single }}</h1>
                        <h1 class="mt-5 mb-5" v-if="form.id">Editando {{ single }}</h1>
                        <div>
                          <b-overlay :show="loadingForm">
                            <b-form @submit="save" class="was-validated">

                              <b-form-group label="Etapa" label-for="input-1" description="etapa do pedido">
                                <b-form-input id="input-1" v-model="form.label" required></b-form-input>
                              </b-form-group>
                              <b-form-group label="Identificador unico" label-for="input-2" description="Identificador">
                                <b-form-input id="input-2" v-model="form.identifier" required></b-form-input>
                              </b-form-group>
                              <b-form-group label="Número da etapa" label-for="input-2" description="número da etapa">
                                <b-form-input id="input-2" v-model="form.number" required></b-form-input>
                              </b-form-group>
                              <b-form-group label="Ordem" label-for="input-2" description="ordenação da etapa">
                                <b-form-input id="input-2" v-model="form.order" required></b-form-input>
                              </b-form-group>
                              <b-button type="submit" variant="primary">
                                <span v-if="form.id">Salvar alterações</span>
                                <span v-if="!form.id">Cadastrar novo</span>
                              </b-button>
                            </b-form>
                          </b-overlay>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <budgets-steps-paginate :paginate="paginate" @change-page="getPaginate"></budgets-steps-paginate>
               <b-table striped hover :items="list" :fields="table" class="table-responsive">
                <template v-slot:cell(updated_at)="data">
                  {{ data.item.created_at | f_date_time }}
                </template>
                <template v-slot:cell(created_at)="data">
                  {{ data.item.created_at | f_date_time }}
                </template>
                <template v-slot:cell(btn)="data">
                  <button @click="edit(data.item)"  class="btn pl-4 pr-4 btn-sm btn-info" ><i class="fa fa-edit"></i> Editar </button>
                  <budgets-steps-delete :value="data.item" @change="getPaginate(1)"></budgets-steps-delete>
                  <budgets-steps-remove :value="data.item" @change="getPaginate(1)"></budgets-steps-remove>
                  <budgets-steps-show :value="data.item"></budgets-steps-show>
                </template>
              </b-table>
              <budgets-steps-paginate :paginate="paginate" @change-page="getPaginate"></budgets-steps-paginate>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </Layout>
</template>

<style scoped>

</style>

