import store from "@/state/store";
import LoadRoute from "@/modules/load-route";

const menu = [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/pages/account/login"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "home" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/pages/account/register"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "home" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot-password",
    component: () => import("../views/pages/account/forgot-password"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "home" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: () => import("../views/pages/account/reset-password.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          store.dispatch("auth/logOut");
        } else {
          store.dispatch("authfack/logout");
        }
        const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
          route.push("/login")
        );
        // Navigate back to previous page, or home as a fallback
        next(authRequiredOnPreviousRoute ? { name: "home" } : { ...routeFrom });
      },
    },
  },
  {
    path: "/use-terms",
    name: "use-terms",
    component: () => import("../views/pages/terms/use-terms.vue"),
  },
  {
    path: "/policies-privacity",
    name: "policies-privacity",
    component: () => import("../views/pages/terms/policies-privacity.vue"),
  },
  {
    path: "/",
    name: "home",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/dashboard/index"),
  },
  {
    path: "/chat",
    name: "Chat",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/chat/index"),
  },
  {
    path: "/cliente/contratos",
    name: "contratos",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/genesis/cliente/cliente-contratos.vue"),
  },
  {
    path: "/cliente/contrato/:id",
    name: "contrato",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/genesis/cliente/cliente-contrato.vue"),
  },
  {
    path: "/cliente/escolas",
    name: "escolas",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/genesis/cliente/cliente-escolas.vue"),
  },
  {
    path: "/cliente/escola/:id",
    name: "escola",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/genesis/cliente/cliente-escola.vue"),
  },
  {
    path: "/minha-conta",
    name: "conta",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/genesis/cliente/minha-conta.vue"),
  },

  {
    path: "/cliente/pedido-novo",
    name: "novo",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/genesis/cliente/cliente-pedido-novo.vue"),
  },
  {
    path: "/cliente/pedidos",
    name: "pedidos",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/genesis/cliente/cliente-pedidos.vue"),
  },
  {
    path: "/cliente/pedido/:id",
    name: "pedido",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/genesis/cliente/cliente-pedido.vue"),
  },
  {
    path: "/exemplo-1",
    name: "Exemplo 1",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/exemplo-1"),
  },
  {
    path: "/exemplo-2",
    name: "Exemplo 2",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/exemplo-2"),
  },
  {
    path: "/sequence",
    name: "sequence",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/sequence"),
  },
];


export default menu.concat(LoadRoute);