var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',[_c('div',{staticClass:"container-fluid p-0"},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"authentication-page-content p-4 d-flex align-items-center min-vh-100"},[_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-9"},[_c('div',[_vm._m(1),_c('div',{staticClass:"p-2 mt-5"},[_c('b-alert',{staticClass:"mt-3",attrs:{"variant":"success","dismissible":""},model:{value:(_vm.registerSuccess),callback:function ($$v) {_vm.registerSuccess=$$v},expression:"registerSuccess"}},[_vm._v("Registration successfull.")]),(_vm.notification.message)?_c('b-alert',{staticClass:"mt-3",attrs:{"variant":"danger","show":"","dismissible":""}},[_vm._v(_vm._s(_vm.notification.message))]):_vm._e(),_c('form',{staticClass:"form-horizontal"},[_c('div',{staticClass:"form-group auth-form-group-custom mb-4"},[_c('i',{staticClass:"ri-lock-2-line auti-custom-input-icon"}),_c('label',{attrs:{"for":"username"}},[_vm._v("Senha")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.password),expression:"user.password"}],staticClass:"form-control",class:{
                              'is-invalid':
                                _vm.submitted && _vm.$v.user.password.$error,
                            },attrs:{"type":"password","id":"username","placeholder":"Digite sua senha"},domProps:{"value":(_vm.user.password)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "password", $event.target.value)}}}),(_vm.submitted && !_vm.$v.user.password.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Senha é obrigatória. ")]):_vm._e(),(_vm.submitted && !_vm.$v.user.password.minLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" A senha precisa ter no mínimo 8 dígitos. ")]):_vm._e()]),_c('div',{staticClass:"form-group auth-form-group-custom mb-4"},[_c('i',{staticClass:"ri-lock-2-line auti-custom-input-icon"}),_c('label',{attrs:{"for":"userpassword"}},[_vm._v("Confirme sua senha")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.confirmPassword),expression:"user.confirmPassword"}],staticClass:"form-control",class:{
                              'is-invalid':
                                _vm.submitted && _vm.$v.user.confirmPassword.$error,
                            },attrs:{"type":"password","id":"userpassword","placeholder":"Confirme sua senha"},domProps:{"value":(_vm.user.confirmPassword)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "confirmPassword", $event.target.value)}}}),(
                              _vm.submitted && !_vm.$v.user.confirmPassword.required
                            )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Confirmação de senha é obrigatória. ")]):_vm._e(),(
                              _vm.submitted &&
                              !_vm.$v.user.confirmPassword.sameAsPassword
                            )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" As senhas precisam ser iguais. ")]):_vm._e(),(
                              _vm.submitted && !_vm.$v.user.confirmPassword.minLength
                            )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" A senha precisa ter no mínimo 8 dígitos. ")]):_vm._e()]),_c('div',{staticClass:"text-center"},[_c('button',{staticClass:"btn btn-primary w-md waves-effect waves-light",attrs:{"disabled":this.$v.$invalid},on:{"click":function($event){$event.preventDefault();return _vm.sendNewPassword.apply(null, arguments)}}},[_vm._v(" Salvar senha ")])])])],1),_c('div',{staticClass:"mt-5 text-center"},[_c('p',[_vm._v(" Problemas com seu código ? Solicite outro código "),_c('router-link',{staticClass:"font-weight-medium text-primary",attrs:{"to":"/forgot-password"}},[_vm._v("aqui !")])],1),_c('p',[_vm._v(" Já possui uma conta ? "),_c('router-link',{staticClass:"font-weight-medium text-primary",attrs:{"to":"/login"}},[_vm._v("Login")])],1),_c('p',[_vm._v("© "+_vm._s(new Date().getFullYear())+" Gestio School")])])])])])])])]),_vm._m(2)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-btn d-none d-sm-block"},[_c('a',{attrs:{"href":"/"}},[_c('i',{staticClass:"mdi mdi-home-variant h2 text-white"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('div',[_c('a',{staticClass:"logo",attrs:{"href":"/"}},[_c('img',{attrs:{"src":require("@/assets/images/logo-dark.png"),"height":"20","alt":"logo"}})])]),_c('h4',{staticClass:"font-size-18 mt-4"},[_vm._v("Nova Senha")]),_c('p',{staticClass:"text-muted"},[_vm._v("Digite sua nova senha.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-8"},[_c('div',{staticClass:"authentication-bg"},[_c('div',{staticClass:"bg-overlay"})])])
}]

export { render, staticRenderFns }