<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import PostsConfigsStore from "@/modules/posts-configs/posts-configs-store";
import PostsConfigsPaginate from "@/components/paginate.vue";
import Swal from "sweetalert2";
import PostsConfigsDelete from "@/modules/posts-configs/components/posts-configs-delete.vue";
import PostsConfigsRemove from "@/modules/posts-configs/components/posts-configs-remove.vue";
import PostsConfigsShow from "@/modules/posts-configs/components/posts-configs-show.vue";
import TaxonomiesTypesStore from "@/modules/taxonomies-types/taxonomies-types-store";
import PostsTypesStore from "@/modules/posts-types/posts-types-store";

export default {
  name: "PostsConfigs",
  data() {
    return {
      title: "Configurações de posts",
      single: "Configurações de posts",
      items: [
        {text: "Home", to: {name: "home"}},
        {text: "Configurações de posts", active: true},
      ],
      table: [
        {label: "ID", key: "id"},
        {label: "Taxonomy", key: "taxonomies_types.label_singular"},
        {label: "Post", key: "posts_types.label_singular"},
        {label: "Criação", key: "created_at"},
        {label: "Atualização", key: "updated_at"},
        {label: "...", key: "btn"},
      ],
      list: [],
      showNewForm: false,
      loadingForm: false,
      paginate: {
        total_pages: 1,
        page: 1,
        perPage: 10,
        total: 0,
      },
      loadingPage: false,
      buscar: "",
      form: {
        id_taxonomy_type: null,
        id_post_type: null,
      },
      optionsTaxomies: [],
      optionsPosts: [],
    }
  },
  components: {PostsConfigsShow, PostsConfigsRemove, PostsConfigsDelete, PostsConfigsPaginate, Layout, PageHeader},
  mounted() {
    this.getPaginate(this.paginate.page);
  },
  methods: {
    getPaginate(e = 1) {
      this.paginate.page = e;
      this.loadingPage = true;

      TaxonomiesTypesStore.all().then((response) => {
        this.optionsTaxomies = response.data.data.map((item) => {
          return {
            value: item.id,
            text: item.label_plural,
          }
        });
      }).catch((error) => {
        console.log(error);
      });

      PostsTypesStore.all().then((response) => {
        this.optionsPosts = response.data.data.map((item) => {
          return {
            value: item.id,
            text: item.label_plural,
          }
        });
      }).catch((error) => {
        console.log(error);
      });


      PostsConfigsStore.paginate({
        page: this.paginate.page,
        limit: this.paginate.perPage,
        search: this.buscar,
      }).then((response) => {
        const {data, limit, page, total, total_pages} = response.data;
        this.list = data;
        this.paginate.total = total;
        this.paginate.perPage = limit;
        this.paginate.page = page;
        this.paginate.total_pages = total_pages;
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.loadingPage = false;
      });
    },
    cleanForm() {
      this.form = {
        id_taxonomy_type: null,
        id_post_type: null,
      }
    },
    edit(item) {
      this.form = item;
      this.showNewForm = true;
    },
    save(event) {
      event.preventDefault();
      this.loadingForm = true;
      if (this.form.id) {
        PostsConfigsStore.update(this.form).then((response) => {
          this.form = response.data;
          this.getPaginate(1);
          this.cleanForm();
          this.showNewForm = false;
          Swal.fire({
            icon: "success",
            title: "Atualizado",
            text: "PostsConfigs atualizada com sucesso",
          });
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao atualizar",
            text: "Erro ao atualizar a escola",
          });
        }).finally(() => {
          this.loadingForm = false;
        });
      } else {
        PostsConfigsStore.create(this.form).then((response) => {
          this.form = response.data;
          this.getPaginate(1);
          this.cleanForm();
          this.showNewForm = false;
          Swal.fire({
            icon: "success",
            title: "Criado",
            text: "PostsConfigs criada com sucesso",
          });
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao criar",
            text: "Erro ao criar a escola",
          });
        }).finally(() => {
          this.loadingForm = false;
        });
      }
    }
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <b-overlay :show="loadingPage">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h1>{{ title }}</h1>

              <div class="row mb-4 align-items-center">
                <div class="col-md-5">
                  <div class="row align-items-center">
                    <div class="col-md-6">
                      <input
                          v-model="buscar"
                          type="text"
                          placeholder="Pesquisar...."
                          class="form-control"
                          @change="getPaginate(1)"
                      />
                      <span class="d-block d-md-none"></span>
                    </div>
                    <div class="col-6 col-md-3">
                      <button @click="getPaginate(1)" type="button" class="btn btn-light btn-block">
                        <i class="fa fa-search"></i> Buscar
                      </button>
                    </div>
                    <div class="col-6 col-md-3">
                      <button @click="buscar=null; getPaginate(1) " class="btn btn-light btn-block">
                        <i class="fa fa-broom"></i> Limpar
                      </button>
                    </div>
                  </div>
                </div>

                <div class="col-md-7 mb-2 text-right align-items-center">
                  <span class="d-block d-md-none p-2"> </span>
                  <div>
                    <button class="btn btn-primary" @click="showNewForm = true">
                      <i class="fa fa-plus"></i>
                      <span v-if="form.id">Editando</span>
                      <span v-if="!form.id">Novo</span>
                    </button>
                  </div>
                  <div v-show="showNewForm">
                    <div class="box-form text-left">
                      <div id="form-novo-edit">
                        <i @click="showNewForm = false; cleanForm();" class="fa float-right fa-2x fa-eye-slash"></i>
                        <h1 class="mt-5 mb-5" v-if="!form.id">Nova {{ single }}</h1>
                        <h1 class="mt-5 mb-5" v-if="form.id">Editando {{ single }}</h1>
                        <div>
                          <b-overlay :show="loadingForm">
                            <b-form @submit="save" class="was-validated">

                              <b-form-group label="Post" label-for="input-2" description="Type post a ser configurado">
                                <b-form-select :options="optionsPosts" id="input-2" v-model="form.id_post_type" required></b-form-select>
                              </b-form-group>

                              <b-form-group label="Taxonomia" label-for="input-1" description="Taxomia a ser configurada">
                                <b-form-select :options="optionsTaxomies" id="input-1" v-model="form.id_taxonomy_type" required></b-form-select>
                              </b-form-group>



                              <b-button type="submit" variant="primary">
                                <span v-if="form.id">Salvar alterações</span>
                                <span v-if="!form.id">Cadastrar novo</span>
                              </b-button>
                            </b-form>
                          </b-overlay>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <posts-configs-paginate :paginate="paginate" @change-page="getPaginate"></posts-configs-paginate>
               <b-table striped hover :items="list" :fields="table" class="table-responsive">
                <template v-slot:cell(updated_at)="data">
                  {{ data.item.created_at | f_date_time }}
                </template>
                <template v-slot:cell(created_at)="data">
                  {{ data.item.created_at | f_date_time }}
                </template>
                <template v-slot:cell(btn)="data">
                  <button @click="edit(data.item)" class="btn pl-4 pr-4 btn-sm btn-info"><i class="fa fa-edit"></i> Editar</button>
                  <posts-configs-delete v-if="data.item.deleted_at" :value="data.item" @change="getPaginate(1)"></posts-configs-delete>
                  <posts-configs-remove v-if="!data.item.deleted_at" :value="data.item" @change="getPaginate(1)"></posts-configs-remove>
                  <posts-configs-show :value="data.item"></posts-configs-show>
                </template>
              </b-table>
              <posts-configs-paginate :paginate="paginate" @change-page="getPaginate"></posts-configs-paginate>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </Layout>
</template>

<style scoped>

</style>

