<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import PeoplesTypesStore from "@/modules/peoples-types/peoples-types-store";
import PeoplesRacesStore from "@/modules/peoples-races/peoples-races-store";
import PeoplesStore from "@/modules/peoples/peoples-store";
import UsersStore from "@/modules/users/users-store";

export default {
  data: () => {
    return {
      title: "Minha conta",
      items: [
        {
          text: "Escola",
          href: "/",
        },
        {
          text: "Minha conta",
          active: true,
        },
      ],
      perfil: null,
      isLoadShow: false,
      loadingForm: false,
      formUser: {
        id: null,
        id_people: null,
        name: null,
        email: null,
        type: null,
        password: null,
        document: null,
        password_confirmation: null,
      },
      formPeople: {
        id: null,
        id_people_type: null,
        id_people_race: null,
        sex: null,
        name: null,
        document: null,
        photo: null,
        date_of_birth: null,
      },
      optionsPeopleTypes: [{text: "Selecione", value: null}],
      optionsPeopleRaces: [{text: "Selecione", value: null}],
      optionsSex: [
        {text: "Masculino", value: "M"},
        {text: "Feminino", value: "F"},
        {text: "Juridica", value: "PJ"},
      ],
    }
  },
  components: {Layout, PageHeader},
  mounted() {
    const perfilJson = localStorage.getItem("perfil");
    this.perfil = JSON.parse(atob(perfilJson));
    delete this.perfil.password;
    this.formUser = this.perfil;
    console.log('this.formUser', this.formUser);

    this.load();

  },
  methods: {
    save(e) {
      e.preventDefault();
      this.loadingForm = true;
      UsersStore.update(this.formUser).then((response) => {
        this.loadingForm = false;
        this.formUser = response.data;
        delete this.formUser.password;
        const base64 = btoa(JSON.stringify(this.formUser));
        localStorage.setItem("perfil", base64);
        localStorage.setItem("type_user", this.formUser.type);

        this.$bvToast.toast('Usuário atualizado com sucesso', {
          title: 'Sucesso',
          variant: 'success',
          solid: true,
        });
      }).catch((error) => {
        this.loadingForm = false;
        this.$bvToast.toast('Erro ao atualizar usuário', {
          title: 'Erro',
          variant: 'danger',
          solid: true,
        });
      });
    },
    handlerUpload(event) {
      this.formPeople.photo = event.target.files[0];
      console.log(this.formPeople.photo);
    },
    savePeople(e) {

      e.preventDefault();

      const formData = new FormData();
      if (this.formPeople.id) {
        formData.append("id", this.formPeople.id);
      }

      formData.append("id_school", this.formPeople.id);
      formData.append("id_people_type", this.formPeople.id_people_type);
      formData.append("id_people_race", this.formPeople.id_people_race);
      formData.append("sex", this.formPeople.sex);
      formData.append("name", this.formPeople.name);
      formData.append("document", this.formPeople.document);
      formData.append("date_of_birth", this.formPeople.date_of_birth);

      if (this.formPeople.photo) {
        formData.append("photo", this.formPeople.photo);
      }

      this.loadingForm = true;
      PeoplesStore.update(this.formPeople.id, formData).then((response) => {
        this.loadingForm = false;
        this.formPeople = response.data;
        this.formPeople.date_of_birth = this.formPeople.date_of_birth.split('T')[0];
        this.$bvToast.toast('Pessoa atualizada com sucesso', {
          title: 'Sucesso',
          variant: 'success',
          solid: true,
        });
      }).catch((error) => {
        this.loadingForm = false;
        this.$bvToast.toast('Erro ao atualizar pessoa', {
          title: 'Erro',
          variant: 'danger',
          solid: true,
        });
      });

    },
    async load() {

      this.isLoadShow = true;
      await this.loadOptionsPeoplesTypes();
      await this.loadOptionsPeoplesRaces();

      await PeoplesStore.get(this.perfil.id_people).then((response) => {
        this.formPeople = response.data;
        this.formPeople.date_of_birth = this.formPeople.date_of_birth.split('T')[0];
      }).catch((error) => {
        console.log(error);
      });

      this.isLoadShow = false;
    },
    async loadOptionsPeoplesTypes() {
      await PeoplesTypesStore.all().then((response) => {
        this.optionsPeopleTypes.push(...response.data.map((item) => {
          return {text: item.label, value: item.id}
        }));
      }).catch((error) => {
        console.log(error);
      });
    },
    async loadOptionsPeoplesRaces() {
      await PeoplesRacesStore.all().then((response) => {
        this.optionsPeopleRaces.push(...response.data.map((item) => {
          return {text: item.label, value: item.id}
        }));
      }).catch((error) => {
        console.log(error);
      });
    },
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <b-overlay :show="isLoadShow">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h1>{{ title }}</h1>
              <br>
              <hr>
              <br>


              <div class="row">
                <div class="col-md-6">
                  <h4>Meus pessoais</h4>

                  <b-overlay :show="loadingForm">
                    <b-form @submit="savePeople" class="was-validated row">

                      <b-form-group label="Raça pessoa" description="Selecione raça" class="col-md-6">
                        <b-form-select


                            v-model="formPeople.id_people_race"
                            :options="optionsPeopleRaces"
                            required
                        ></b-form-select>
                      </b-form-group>
                      <!--                              sex-->
                      <b-form-group label="Sexo" description="Selecione o sexo" class="col-md-6">
                        <b-form-select
                            v-model="formPeople.sex"
                            :options="optionsSex"
                            required
                        ></b-form-select>
                      </b-form-group>
                      <!--                              name-->
                      <b-form-group label="Nome" label-for="name" description="Nome da pessoa" class="col-md-6">
                        <b-form-input id="name" v-model="formPeople.name" required></b-form-input>
                      </b-form-group>
                      <!--                              document-->
                      <b-form-group label="Documento" label-for="document" description="Documento da pessoa" class="col-md-6">
                        <b-form-input id="document" v-model="formPeople.document" required></b-form-input>
                      </b-form-group>
                      <!--                              photo-->
                      <b-form-group label="Foto" label-for="photo" description="Foto da pessoa" class="col-md-4">
                        <div class="row">
                          <div class="col-md-3"><img v-if="formPeople.photo_view" :src="formPeople.photo_view" width="40" height="40"></div>
                          <div class="col">
                            <b-form-file @change="handlerUpload" id="photo" v-model="formPeople.photo"></b-form-file>
                          </div>
                        </div>
                      </b-form-group>
                      <!--                              date_of_birth-->
                      <b-form-group label="Data de nascimento" label-for="date_of_birth" description="Data de nascimento da pessoa" class="col-md-4">
                        <b-form-input type="date" id="date_of_birth" v-model="formPeople.date_of_birth" required></b-form-input>
                      </b-form-group>


                      <div class="col-md-4 mt-4">
                        <b-button type="submit" variant="primary" class="btn-block" style="margin-top: 5px">
                          <span v-if="formPeople.id">Salvar alterações pessoais</span>
                          <span v-if="!formPeople.id">Cadastrar novo</span>
                        </b-button>
                        <br>
                        <br>
                        <br>
                        <br>
                      </div>

                    </b-form>
                  </b-overlay>

                </div>
                <div class="col-md-1">

                </div>
                <div class="col-md-5">
                  <h4>Dados de usuário</h4>

                  <b-overlay :show="loadingForm">
                    <b-form @submit="save" class="was-validated row">


                      <b-form-group label="Nome" label-for="input-1" description="Nome completo" class="col-md-6">
                        <b-form-input v-model="formUser.name" required></b-form-input>
                      </b-form-group>
                      <b-form-group label="Documento" label-for="input-1" description="Documento (CPF)" class="col-md-6">
                        <b-form-input v-model="formUser.document" required></b-form-input>
                      </b-form-group>
                      <b-form-group label="E-mail" label-for="input-2" description="E-mail" class="col-md-6">
                        <b-form-input v-model="formUser.email" required></b-form-input>
                      </b-form-group>
                      <b-form-group label="Senha" label-for="input-2" description="********" class="col-md-6">

                        <b-form-input v-if="parseInt(formUser && formUser.id ? formUser.id : 0) > 0" type="password" v-model="formUser.password"></b-form-input>
                        <b-form-input v-if="parseInt(formUser && formUser.id ? formUser.id : 0) <= 0" type="password" v-model="formUser.password" required></b-form-input>
                      </b-form-group>
                      <b-form-group label="Confirmação de senha" label-for="input-2" description="********" class="col-md-6">
                        <b-form-input v-if="parseInt(formUser && formUser.id ? formUser.id : 0) > 0" type="password" v-model="formUser.password_confirmation"></b-form-input>
                        <b-form-input v-if="parseInt(formUser && formUser.id ? formUser.id : 0) <= 0" type="password" v-model="formUser.password_confirmation" required></b-form-input>
                      </b-form-group>


                      <div class="col-md-6 mt-4">
                        <b-button type="submit" variant="primary" class="btn-block" style="margin-top: 5px">
                          <span v-if="formUser.id">Salvar alterações da conta de usuário</span>
                          <span v-if="!formUser.id">Cadastrar novo</span>
                        </b-button>
                        <br>
                        <br>
                        <br>
                        <br>
                      </div>

                    </b-form>
                  </b-overlay>

                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </Layout>
</template>

<style scoped lang="scss">

</style>
