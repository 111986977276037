<script>
import {menuItemsDefault} from "./horizontal-menu";
import PostsTypesStore from "@/modules/posts-types/posts-types-store";
import acl from "@/router/acl";
export default {
  data() {
    return {
      menuItems: null,
      contador: 0,
    };
  },
  mounted() {

    this.menuItems = JSON.parse(JSON.stringify(menuItemsDefault));

    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }
    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("active");
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.add("active");
        }
        const parent3 = parent2.parentElement;
        if (parent3) {
          parent3.classList.add("active");
          var childAnchor = parent3.querySelector(".has-dropdown");
          if (childAnchor) childAnchor.classList.add("active");
        }

        const parent4 = parent3.parentElement;
        if (parent4) parent4.classList.add("active");
        const parent5 = parent4.parentElement;
        if (parent5) parent5.classList.add("active");
      }
    }

    this.extrasMenus();
  },
  methods: {
    acl,
    /**
     * Menu clicked show the submenu
     */
    onMenuClick(event) {
      event.preventDefault();
      const nextEl = event.target.nextSibling;
      if (nextEl && !nextEl.classList.contains("show")) {
        const parentEl = event.target.parentNode;
        if (parentEl) {
          parentEl.classList.remove("show");
        }
        nextEl.classList.add("show");
      } else if (nextEl) {
        nextEl.classList.remove("show");
      }
      return false;
    },
    /**
     * Returns true or false if given menu item has child or not
     * @param item menuItem
     */
    hasItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false;
    },

    extrasMenus() {

      if (this.contador > 0) return;

      const totalDefault = menuItemsDefault.find(x => x.id === 'livros').subItems.length
      const total = this.menuItems.find(x => x.id === 'livros').subItems.length

      // console.log('totalDefault', totalDefault,total)

      if (totalDefault.length !== total.length) return;


      const menu = [];
      const taxonomies = [];

      PostsTypesStore.all().then((postsTypes) => {
        // console.log('postsTypes', postsTypes)

        postsTypes.data.data.forEach((item) => {

          const subMenu = [];
          localStorage.setItem("post_type_" + item.id, item.type);
          localStorage.setItem("post_type_" + item.type, item.id);
          localStorage.setItem("post_type_label_" + item.type, item.label_plural);
          subMenu.push({
            id: item.id + item.type,
            label: item.label_plural,
            link: "/posts/" + item.type,
            icon: "ri-dashboard-line",
            // acl: "posts.menu",
          });

          try {
            if (item.posts_configs && item.posts_configs.length > 0) {
              item.posts_configs.forEach((subItem) => {
                localStorage.setItem("taxonomy_" + subItem.taxonomies_types.id, subItem.taxonomies_types.type);
                localStorage.setItem("taxonomy_" + subItem.taxonomies_types.type, subItem.taxonomies_types.id);
                localStorage.setItem("taxonomy_label_" + subItem.taxonomies_types.type, subItem.taxonomies_types.label_plural);
                taxonomies.push(subItem.taxonomies_types.type);
                subMenu.push({
                  id: subItem.taxonomies_types.id + subItem.taxonomies_types.type + "-menu",
                  label: `-- ${subItem.taxonomies_types.label_plural}`,
                  link: "/taxonomies/" + subItem.taxonomies_types.type,
                  icon: "ri-dashboard-line",
                });
              });
            }
          } catch (e) {
            console.log('error', e)
          }


          menu.push({
            id: item.id + item.type + "-menu",
            label: item.label_plural,
            icon: "ri-dashboard-line",
            subItems: subMenu,
          });

        })


        const before = JSON.parse(JSON.stringify(this.menuItems.find(x => x.id === 'livros').subItems));
        // .find(x => x.id === 'posts'); = menu;

        this.menuItems.find(x => x.id === 'livros').subItems = [...before, ...menu];

        // console.log('livros', this.menuItems.find(x => x.id === 'livros').subItems.find(x => x.id === 'posts'))
        // console.log('menu', menu)

        this.contador++;


      })


    }


  }
};
</script>

<template>
  <div class="topnav" :key="contador">
    <div class="container-fluid">
      <nav class="navbar navbar-light navbar-expand-lg topnav-menu">
        <div class="collapse navbar-collapse" id="topnav-menu-content">
          <ul class="navbar-nav">
            <template v-for="(item, index) of menuItems" >
              <li class="nav-item dropdown" v-if="!item.acl || (item.acl && acl(item.acl) ) " :key="index">
                <router-link

                    v-if="!item.subItems"
                    :to="item.link"
                    class="nav-link dropdown-toggle arrow-none"
                >
                  <i :class="`${item.icon} mr-2`"></i>
                  {{ (item.label) }}
                </router-link>

                <a
                    v-if="item.subItems"
                    class="nav-link dropdown-toggle arrow-none"
                    @click="onMenuClick"
                    href="javascript: void(0);"
                    id="topnav-components"
                    role="button"
                >
                  <i :class="`${item.icon} mr-2`"></i>
                  {{ (item.label) }}
                  <div class="arrow-down"></div>
                </a>
                <div
                    class="dropdown-menu row"
                    aria-labelledby="topnav-dashboard"
                    v-if="hasItems(item)"
                >
                  <template v-for="(subitem) of item.subItems">
                    <router-link
                        :key="subitem.id"
                        class="col dropdown-item side-nav-link-ref"
                        v-if="!hasItems(subitem)"
                        :to="subitem.link"
                    >{{ (subitem.label) }}
                    </router-link>
                    <div class="dropdown" v-if="hasItems(subitem)" :key="subitem.id">
                      <a class="dropdown-item" href="javascript: void(0);" @click="onMenuClick">
                        {{ (subitem.label) }}
                        <div class="arrow-down"></div>
                      </a>
                      <div class="dropdown-menu">
                        <router-link
                            v-for="(subSubitem, index) of subitem.subItems"
                            :key="index"
                            :to="subSubitem.link"
                            class="dropdown-item side-nav-link-ref"
                        >{{ (subSubitem.label) }}
                        </router-link>
                      </div>
                    </div>
                  </template>
                </div>
              </li>
            </template>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</template>