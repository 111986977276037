<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import SchoolsStore from "@/modules/schools/schools-store";
import SchoolsPaginate from "@/components/paginate.vue";
import Swal from "sweetalert2";
import SchoolsDelete from "@/modules/schools/components/schools-delete.vue";
import SchoolsRemove from "@/modules/schools/components/schools-remove.vue";
import SchoolsShow from "@/modules/schools/components/schools-show.vue";

export default {
  name: "Escolas",
  data() {
    return {
      title: "Escolas",
      single: "Escola",
      items: [
        {text: "Home", to: {name: "home"}},
        {text: "Escolas", active: true},
      ],
      table: [
        {label: "ID", key: "id"},
        {label: "Nome", key: "name"},
        {label: "Identificador", key: "iduuid"},
        {label: "Criação", key: "created_at"},
        {label: "Atualização", key: "updated_at"},
        {label: "...", key: "btn"},
      ],
      list: [],
      showNewForm: false,
      loadingForm: false,
      paginate: {
        total_pages: 1,
        page: 1,
        perPage: 10,
        total: 0,
      },
      loadingPage: false,
      buscar: "",
      perfil: null,
    }
  },
  components: {SchoolsShow, SchoolsRemove, SchoolsDelete, SchoolsPaginate, Layout, PageHeader},
  mounted() {
    const perfilJson = localStorage.getItem("perfil");
    this.perfil = JSON.parse(atob(perfilJson));
    this.getPaginate(this.paginate.page);

  },
  watch: {
    'form.name': function (newValue) {
      // this.form.identifier = this.$slug_format(newValue);
    }
  }, methods: {
    getPaginate(e = 1) {
      this.paginate.page = e;
      this.loadingPage = true;
      SchoolsStore.paginate({
        page: this.paginate.page,
        limit: this.paginate.perPage,
        id_people: this.perfil.id_people,
        search: this.buscar,
      }).then((response) => {
        const {data, limit, page, total, total_pages} = response.data;
        this.list = data;
        this.paginate.total = total;
        this.paginate.perPage = limit;
        this.paginate.page = page;
        this.paginate.total_pages = total_pages;
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.loadingPage = false;
      });
    },
    cleanForm() {
      this.form = {
        id: null,
        name: null,
        iduuid: null,
        is_plataform: null,
        is_genesis: null,
        id_people: null,
        firebase_domain: null,
        firebase_domain_temp: null,
        firebase_storage: null,
        firebase_auth_tenant_id: null,
        firebase_project_id: null,
        firebase_measurement_id: null,
      }
    },
    edit(item) {
      this.form = JSON.parse(JSON.stringify(item));
      this.showNewForm = true;
    },
    save(event) {
      event.preventDefault();
      this.loadingForm = true;
      if (this.form.id) {
        SchoolsStore.update(this.form).then((response) => {
          this.form = response.data;
          this.getPaginate(1);
          this.cleanForm();
          this.showNewForm = false;
          Swal.fire({
            icon: "success",
            title: "Atualizado",
            text: "Escola atualizada com sucesso",
          });
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao atualizar",
            text: "Erro ao atualizar a escola",
          });
        }).finally(() => {
          this.loadingForm = false;
        });
      } else {
        SchoolsStore.create(this.form).then((response) => {
          this.form = response.data;
          this.getPaginate(1);
          this.cleanForm();
          this.showNewForm = false;
          Swal.fire({
            icon: "success",
            title: "Criado",
            text: "Escola criada com sucesso",
          });
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao criar",
            text: "Erro ao criar a escola",
          });
        }).finally(() => {
          this.loadingForm = false;
        });
      }
    }
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <b-overlay :show="loadingPage">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h1>{{ title }}</h1>

              <div class="row mb-4 align-items-center">
                <div class="col-md-5">
                  <div class="row align-items-center">
                    <div class="col-md-6">
                      <input
                          v-model="buscar"
                          type="text"
                          placeholder="Pesquisar...."
                          class="form-control"
                          @change="getPaginate(1)"
                      />
                      <span class="d-block d-md-none"></span>
                    </div>
                    <div class="col-6 col-md-3">
                      <button @click="getPaginate(1)" type="button" class="btn btn-light btn-block">
                        <i class="fa fa-search"></i> Buscar
                      </button>
                    </div>
                    <div class="col-6 col-md-3">
                      <button @click="buscar=null; getPaginate(1) " class="btn btn-light btn-block">
                        <i class="fa fa-broom"></i> Limpar
                      </button>
                    </div>

                  </div>
                </div>
                <div class="col-6 col-md-7 text-right">
                  <router-link :to="'/cliente/escola/--'" class="btn btn-primary">Novo</router-link>
                </div>

              </div>

              <schools-paginate :paginate="paginate" @change-page="getPaginate"></schools-paginate>
              <b-table striped hover :items="list" :fields="table">
                <template v-slot:cell(updated_at)="data">
                  {{ data.item.created_at | f_date_time }}
                </template>
                <template v-slot:cell(created_at)="data">
                  {{ data.item.created_at | f_date_time }}
                </template>
                <template v-slot:cell(btn)="data" class="float-right">
                  <router-link :to="'/cliente/escola/'+data.item.id" class="btn pl-4 pr-4 btn-sm btn-info"><i class="fa fa-edit"></i> Editar</router-link>

                </template>
              </b-table>
              <schools-paginate :paginate="paginate" @change-page="getPaginate"></schools-paginate>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </Layout>
</template>

<style scoped>

</style>

