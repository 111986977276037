<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import BudgetsStore from "@/modules/budgets/budgets-store";
import BudgetsPaginate from "@/components/paginate.vue";
import Swal from "sweetalert2";
import BudgetsDelete from "@/modules/budgets/components/budgets-delete.vue";
import BudgetsRemove from "@/modules/budgets/components/budgets-remove.vue";
import BudgetsShow from "@/modules/budgets/components/budgets-show.vue";
import SchoolsStore from "@/modules/schools/schools-store";
import SchoolsContractsStore from "@/modules/schools-contracts/schools-contracts-store";
import PeoplesStore from "@/modules/peoples/peoples-store";
import BudgetsStatusStore from "@/modules/budgets-status/budgets-status-store";
import BudgetsStepsStore from "@/modules/budgets-steps/budgets-steps-store";

export default {
  name: "Pedidos",
  data() {
    return {
      title: "Pedidos",
      single: "Pedido",
      items: [
        {text: "Home", to: {name: "home"}},
        {text: "Pedidos", active: true},
      ],
      table: [
        {label: "ID", key: "id"},
        {label: "Pessoa", key: "peoples.name"},
        {label: "Escola", key: "schools.name"},
        {label: "Contrato de escola", key: "schools_contracts.description"},
        {label: "Status de pedido", key: "budgets_status.type"},
        {label: "Titulo", key: "title"},
        {label: "Descrição", key: "description"},
        {label: "Quantidade", key: "qtd_slips"},
        {label: "Código de rastreamento", key: "tracking_code"},
        {label: "Criação", key: "created_at"},
        {label: "Atualização", key: "updated_at"},
        {label: "...", key: "btn"},
      ],
      list: [],
      showNewForm: false,
      loadingForm: false,
      paginate: {
        total_pages: 1,
        page: 1,
        perPage: 10,
        total: 0,
      },
      loadingPage: false,
      buscar: "",
      form: {
        id: null,
        id_peoples: null,
        id_schools: null,
        id_schools_contracts: null,
        id_budgets_status: null,
        title: null,
        description: null,
        qtd_slips: null,
        tracking_code: null,
        id_budget_step: null,
      },
      optionsPeoples: [],
      optionsSteps: [],
      optionsSchools: [],
      optionsSchoolsContracts: [],
      optionsBudgetsStatus: [],
      escolas: [],
      contratos: [],
    }
  },
  components: {BudgetsShow, BudgetsRemove, BudgetsDelete, BudgetsPaginate, Layout, PageHeader},
  mounted() {
    this.getPaginate(this.paginate.page);
  },
  watch: {
    'form.name': function (newValue) {
      this.form.identifier = this.$slug_format(newValue);
    }
  }, methods: {
    async getPaginate(e = 1) {
      this.paginate.page = e;
      this.loadingPage = true;


      await SchoolsStore.paginate({
        limit: 1000000
      }).then((response) => {
        const {data} = response.data;
        this.escolas = data;
        this.optionsSchools = data.map((escola) => {
          return {
            value: escola.id,
            text: escola.name
          }
        });
      }).catch((error) => {
        console.log(error);
      })

      await PeoplesStore.all().then((response) => {
        this.optionsPeoples = response.data.data.map((item) => {
          return {
            value: item.id,
            text: item.name,
          }
        });
      }).catch((error) => {
        console.log(error);
      });

      await BudgetsStepsStore.all().then((response) => {
        this.optionsSteps = response.data.map((item) => {
          return {
            value: item.id,
            text: item.label,
          }
        });
      }).catch((error) => {
        console.log(error);
      });

      await BudgetsStatusStore.all().then((response) => {
        this.optionsBudgetsStatus = response.data.map((item) => {
          return {
            value: item.id,
            text: item.label,
          }
        });
      }).catch((error) => {
        console.log(error);
      });


      await SchoolsContractsStore.all({

      }).then((response) => {
        const {data} = response.data;
        this.contratos = data;
        this.optionsSchoolsContracts = data.filter(x => x.status == 'publish').map((contrato) => {
          return {
            value: contrato.id,
            text: `${contrato.id} - ${contrato.schools.name}`
          }
        });
      }).catch((error) => {
        console.log(error);
      })


      BudgetsStore.paginate({
        page: this.paginate.page,
        limit: this.paginate.perPage,
        search: this.buscar,
      }).then((response) => {
        const {data, limit, page, total, total_pages} = response.data;
        this.list = data;
        this.paginate.total = total;
        this.paginate.perPage = limit;
        this.paginate.page = page;
        this.paginate.total_pages = total_pages;
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.loadingPage = false;
      });
    },
    cleanForm() {
      this.form = {
        id: null,
        id_peoples: null,
        id_schools: null,
        id_schools_contracts: null,
        id_budgets_status: null,
        title: null,
        description: null,
        qtd_slips: null,
        tracking_code: null,
      }
    },
    edit(item) {
      this.form = JSON.parse(JSON.stringify(item));
      this.showNewForm = true;
    },
    save(event) {
      event.preventDefault();
      this.loadingForm = true;
      if (this.form.id) {
        BudgetsStore.update(this.form).then((response) => {
          this.form = response.data;
          this.getPaginate(1);
          this.cleanForm();
          this.showNewForm = false;
          Swal.fire({
            icon: "success",
            title: "Atualizado",
            text: "Pedido atualizado com sucesso",
          });
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao atualizar",
            text: "Erro ao atualizar o pedido",
          });
        }).finally(() => {
          this.loadingForm = false;
        });
      } else {
        BudgetsStore.create(this.form).then((response) => {
          this.form = response.data;
          this.getPaginate(1);
          this.cleanForm();
          this.showNewForm = false;
          Swal.fire({
            icon: "success",
            title: "Criado",
            text: "Pedido criado com sucesso",
          });
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao criar",
            text: "Erro ao criar o pedido",
          });
        }).finally(() => {
          this.loadingForm = false;
        });
      }
    }
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <b-overlay :show="loadingPage">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h1>{{ title }}</h1>

              <div class="row mb-4 align-items-center">
                <div class="col-md-5">
                  <div class="row align-items-center">
                    <div class="col-md-6">
                      <input
                          v-model="buscar"
                          type="text"
                          placeholder="Pesquisar...."
                          class="form-control"
                          @change="getPaginate(1)"
                      />
                      <span class="d-block d-md-none"></span>
                    </div>
                    <div class="col-6 col-md-3">
                      <button @click="getPaginate(1)" type="button" class="btn btn-light btn-block">
                        <i class="fa fa-search"></i> Buscar
                      </button>
                    </div>
                    <div class="col-6 col-md-3">
                      <button @click="buscar=null; getPaginate(1) " class="btn btn-light btn-block">
                        <i class="fa fa-broom"></i> Limpar
                      </button>
                    </div>
                  </div>
                </div>

                <div class="col-md-7 mb-2 text-right align-items-center">
                  <span class="d-block d-md-none p-2"> </span>
                  <div>
                    <button class="btn btn-primary" @click="showNewForm = true">
                      <i class="fa fa-plus"></i>
                      <span v-if="form.id">Editando</span>
                      <span v-if="!form.id">Novo</span>
                    </button>
                  </div>
                  <div v-show="showNewForm">
                    <div class="box-form text-left">
                      <div id="form-novo-edit">
                        <i @click="showNewForm = false; cleanForm();" class="fa float-right fa-2x fa-eye-slash"></i>
                        <h1 class="mt-5 mb-5" v-if="!form.id">Nova {{ single }}</h1>
                        <h1 class="mt-5 mb-5" v-if="form.id">Editando {{ single }}</h1>
                        <div>
                          <b-overlay :show="loadingForm">
                            <b-form @submit="save" class="was-validated">


                              <!--id_peoples-->
                              <b-form-group label="Status de entrega" label-for="input-0" description="Selecione o step atual">
                                <b-form-select id="input-0" v-model="form.id_budget_step" :options="optionsSteps" required></b-form-select>
                              </b-form-group>
                              <b-form-group label="Pessoa" label-for="input-0" description="Selecione a pessoa do comentario">
                                <b-form-select id="input-0" v-model="form.id_peoples" :options="optionsPeoples" required></b-form-select>
                              </b-form-group>
                              <!--id_schools-->
                              <b-form-group label="Escola" label-for="input-1" description="Selecione a escola">
                                <b-form-select id="input-1" v-model="form.id_schools" :options="optionsSchools" required></b-form-select>
                              </b-form-group>
                              <!--id_schools_contracts-->
                              <b-form-group label="Contrato" label-for="input-1" description="Selecione o contrato">
                                <b-form-select id="input-1" v-model="form.id_schools_contracts" :options="optionsSchoolsContracts" required></b-form-select>
                              </b-form-group>
                              <!--id_budgets_status-->
                              <b-form-group label="Status" label-for="input-1" description="Selecione o status">
                                <b-form-select id="input-1" v-model="form.id_budgets_status" :options="optionsBudgetsStatus" required></b-form-select>
                              </b-form-group>
                              <!--title-->
                              <b-form-group label="Titulo" label-for="input-1" description="Titulo do pedido">
                                <b-form-input id="input-1" v-model="form.title" required></b-form-input>
                              </b-form-group>
                              <!--description-->
                              <b-form-group label="Descrição" label-for="input-1" description="Descrição do pedido">
                                <b-form-textarea id="input-1" v-model="form.description" required></b-form-textarea>
                              </b-form-group>
                              <!--qtd_slips-->
                              <b-form-group label="Quantidade de boletos" label-for="input-1" description="Quantidade de boletos">
                                <b-form-input id="input-1" v-model="form.qtd_slips"  ></b-form-input>
                              </b-form-group>
                              <!--tracking_code-->
                              <b-form-group label="Codigo de rastreio" label-for="input-1" description="Codigo de rastreio">
                                <b-form-input id="input-1" v-model="form.tracking_code"  ></b-form-input>
                              </b-form-group>

 
                              <b-button type="submit" variant="primary">
                                <span v-if="form.id">Salvar alterações</span>
                                <span v-if="!form.id">Cadastrar novo</span>
                              </b-button>
                            </b-form>
                          </b-overlay>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <budgets-paginate :paginate="paginate" @change-page="getPaginate"></budgets-paginate>
               <b-table striped hover :items="list" :fields="table" class="table-responsive">
                <template v-slot:cell(updated_at)="data">
                  {{ data.item.created_at | f_date_time }}
                </template>
                <template v-slot:cell(created_at)="data">
                  {{ data.item.created_at | f_date_time }}
                </template>
                <template v-slot:cell(btn)="data">
                  <router-link :to="'/cliente/pedido/'+data.item.id" class="btn btn-sm btn-success">Ver pedido</router-link>
                  <button @click="edit(data.item)" class="btn pl-4 pr-4 btn-sm btn-info"><i class="fa fa-edit"></i> Editar</button>
                  <budgets-delete :value="data.item" @change="getPaginate(1)"></budgets-delete>
<!--                  <budgets-remove :value="data.item" @change="getPaginate(1)"></budgets-remove>-->
                  <budgets-show :value="data.item"></budgets-show>
                </template>
              </b-table>
              <budgets-paginate :paginate="paginate" @change-page="getPaginate"></budgets-paginate>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </Layout>
</template>

<style scoped>

</style>

