<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import BudgetsStore from "@/modules/budgets/budgets-store";
import BudgetsStepsStore from "@/modules/budgets-steps/budgets-steps-store";
import {is_admin, is_genesis_admin} from "@/router/acl";
import Swal from "sweetalert2";
import SchoolsStore from "@/modules/schools/schools-store";
import PeoplesStore from "@/modules/peoples/peoples-store";
import BudgetsStatusStore from "@/modules/budgets-status/budgets-status-store";
import SchoolsContractsStore from "@/modules/schools-contracts/schools-contracts-store";
import BudgetsInvoicesStore from "@/modules/budgets-invoices/budgets-invoices-store";
import BudgetsInvoicesSlipsStore from "@/modules/budgets-invoices-slips/budgets-invoices-slips-store";
import ContractsCommentsStore from "@/modules/contracts-comments/contracts-comments-store";
import BudgetsCommentsStore from "@/modules/budgets-comments/budgets-comments-store";

export default {
  data: () => {
    return {
      title: "Meu pedido",
      items: [
        {
          text: "Genesis",
          href: "/",
        },
        {
          text: "Pedido",
          active: true,
        },
      ],
      loadingForm: false,
      isCampoComentario: false,

      campoComentario: null,
      perfil: null,
      pedido: null,
      isLoadPage: false,
      etapasEntregas: [],
      optionsPeoples: [],
      optionsSteps: [],
      optionsSchools: [],
      optionsSchoolsContracts: [],
      optionsBudgetsStatus: [],
      optionsBoletosPagoNaoPago: [
        {value: 0, text: "Não pago"},
        {value: 1, text: "Pago"},
      ],
      escolas: [],
      contratos: [],
      loadingFormBoleto: false,
      fileUploadBoleto: null,
      descriptionBoleto: null,
      statusBoleto: null,
      descriptionNota: null,
      loadingFormNota: false,
      fileUploadNota: null,
    }
  },
  components: {Layout, PageHeader},
  mounted() {
    const perfilJson = localStorage.getItem("perfil");
    this.perfil = JSON.parse(atob(perfilJson));
    if (is_admin() || is_genesis_admin()) {
      this.loadOptions();
    }
    this.getPedido();
  },
  methods: {
    async loadOptions() {

      await BudgetsStepsStore.all().then((response) => {
        this.optionsSteps = response.data.map((item) => {
          return {
            value: item.id,
            text: item.label,
          }
        });
      }).catch((error) => {
        console.log(error);
      });

      await SchoolsStore.paginate({
        limit: 1000000
      }).then((response) => {
        const {data} = response.data;
        this.escolas = data;
        this.optionsSchools = data.map((escola) => {
          return {
            value: escola.id,
            text: escola.name
          }
        });
      }).catch((error) => {
        console.log(error);
      })

      await PeoplesStore.all().then((response) => {
        this.optionsPeoples = response.data.data.map((item) => {
          return {
            value: item.id,
            text: item.name,
          }
        });
      }).catch((error) => {
        console.log(error);
      });

      await BudgetsStatusStore.all().then((response) => {
        this.optionsBudgetsStatus = response.data.map((item) => {
          return {
            value: item.id,
            text: item.label,
          }
        });
      }).catch((error) => {
        console.log(error);
      });


      await SchoolsContractsStore.all({}).then((response) => {
        const {data} = response.data;
        this.contratos = data;
        this.optionsSchoolsContracts = data.filter(x => x.status == 'publish').map((contrato) => {
          return {
            value: contrato.id,
            text: `${contrato.id} - ${contrato.schools.name}`
          }
        });
      }).catch((error) => {
        console.log(error);
      })
    },
    is_genesis_admin,
    is_admin,
    getValorTotal(item) {
      try {
        let total = 0;
        item.budgets_books.forEach((book) => {
          total += parseFloat(book.value);
        });
        return parseFloat(total).toFixed(2);

      } catch (e) {
        return parseFloat(0).toFixed(2);
      }
    },
    verificaStep(item, etapa) {
      try {

        const id_budget_step = item.id_budget_step;
        const step = this.etapasEntregas.find((step) => step.id == item.id_budget_step);


        if (step.id == etapa.id) {
          return true;
        }

        if (etapa.order <= step.order) {
          return true;
        }

        return false;

      } catch (e) {
        console.log(e);
        return false;
      }
    },
    async getPedido() {
      const id = this.$route.params.id;
      this.isLoadPage = true;

      await BudgetsStepsStore.all().then((response) => {
        const {data} = response;
        this.etapasEntregas = data;
      }).catch((error) => {
        console.log(error);
      });

      BudgetsStore.get(id).then((response) => {
        const {data} = response;
        this.pedido = data;
        this.isLoadPage = false;
      }).catch((error) => {
        this.isLoadPage = false;
        console.log(error);
      });
    },
    formatCurrentBrl(value) {
      return value.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    },
    save(event) {
      event.preventDefault();
      this.loadingForm = true;
      let formCopy = JSON.parse(JSON.stringify(this.pedido));

      formCopy = {
        id: formCopy.id,
        id_peoples: formCopy.id_peoples,
        id_budget_step: formCopy.id_budget_step,
        id_schools: formCopy.id_schools,
        id_schools_contracts: formCopy.id_schools_contracts,
        id_budgets_status: formCopy.id_budgets_status,
        title: formCopy.title,
        description: formCopy.description,
        qtd_slips: parseInt(formCopy.qtd_slips),
        tracking_code: formCopy.tracking_code,
      }


      if (formCopy.id) {
        BudgetsStore.update(formCopy).then((response) => {
          this.getPedido();
          this.loadingForm = false;
          Swal.fire({
            icon: "success",
            title: "Atualizado",
            text: "Pedido atualizado com sucesso",
          });
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao atualizar",
            text: "Erro ao atualizar o pedido",
          });
        }).finally(() => {
          this.loadingForm = false;
        });
      } else {
        BudgetsStore.create(formCopy).then((response) => {
          this.getPedido();
          this.loadingForm = false;
          Swal.fire({
            icon: "success",
            title: "Criado",
            text: "Pedido criado com sucesso",
          });
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao criar",
            text: "Erro ao criar o pedido",
          });
        }).finally(() => {
          this.loadingForm = false;
        });
      }
    },
    saveNota(event) {
      event.preventDefault();
      this.loadingFormNota = true;
      const formData = new FormData();
      formData.append("id_budgets", this.pedido.id);
      formData.append("local", this.fileUploadNota);
      formData.append("description", this.descriptionNota);
      BudgetsInvoicesStore.create(formData).then((response) => {
        this.getPedido();
        this.loadingFormNota = false;
        this.fileUploadNota = null;
        this.descriptionNota = null;

        Swal.fire({
          icon: "success",
          title: "Enviado",
          text: "Nota enviada com sucesso",
        });
      }).catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Erro ao enviar",
          text: "Erro ao enviar a nota",
        });
      }).finally(() => {
        this.loadingFormNota = false;
      });
    },
    saveBoleto(event) {
      event.preventDefault();
      this.loadingFormBoleto = true;
      const formData = new FormData();
      formData.append("id_budgets", this.pedido.id);
      formData.append("local", this.fileUploadBoleto);
      formData.append("description", this.descriptionBoleto);
      formData.append("status", this.statusBoleto);
      BudgetsInvoicesSlipsStore.create(formData).then((response) => {
        this.getPedido();
        this.loadingFormBoleto = false;
        this.fileUploadBoleto = null;
        this.descriptionBoleto = null;
        this.statusBoleto = 0;
        Swal.fire({
          icon: "success",
          title: "Enviado",
          text: "Boleto enviado com sucesso",
        });
      }).catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Erro ao enviar",
          text: "Erro ao enviar o boleto",
        });
      }).finally(() => {
        this.loadingFormBoleto = false;
      });
    },
    handlerUploadNota(event) {
      this.fileUploadNota = event.target.files[0];
    },
    handlerUploadBoleto(event) {
      this.fileUploadBoleto = event.target.files[0];
    },
    saveCommentario(e) {
      e.preventDefault();
      console.log(this.campoComentario);

      const form = {
        id_budgets: this.pedido.id,
        comment: this.campoComentario,
        id_peoples: this.perfil.id_people
      }

      this.isCampoComentario = true;

      BudgetsCommentsStore.create(form).then((response) => {
        this.campoComentario = null;
        this.getPedido();
        Swal.fire({
          icon: "success",
          title: "Criado",
          text: "Comentário do pedido criado com sucesso",
        });
        this.isCampoComentario = false;
      }).catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Erro ao criar",
          text: "Erro ao criar o Comentário do pedido",
        });
        this.isCampoComentario = false;
      });


    },
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>

    <b-overlay :show="isLoadPage">
      <div class="row" v-if="pedido">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h1>{{ title }}</h1>
            </div>
          </div>

          <br>
          <br>

          <div class="card" v-if="is_admin() || is_genesis_admin()">
            <div class="card-body">

              <b-overlay :show="loadingForm">
                <b-form @submit="save" class="was-validated row">

                  <b-form-group class="col-6 col-md-3" label="Status de entrega" label-for="input-0" description="Selecione o step atual">
                    <b-form-select id="input-0" v-model="pedido.id_budget_step" :options="optionsSteps" required></b-form-select>
                  </b-form-group>

                  <!--id_peoples-->
                  <b-form-group class="col-6 col-md-3" label="Pessoa" label-for="input-0" description="Selecione a pessoa do comentario">
                    <b-form-select id="input-0" v-model="pedido.id_peoples" :options="optionsPeoples" required></b-form-select>
                  </b-form-group>
                  <!--id_schools-->
                  <b-form-group class="col-6 col-md-3" label="Escola" label-for="input-1" description="Selecione a escola">
                    <b-form-select id="input-1" v-model="pedido.id_schools" :options="optionsSchools" required></b-form-select>
                  </b-form-group>
                  <!--id_schools_contracts-->
                  <b-form-group class="col-6 col-md-3" label="Contrato" label-for="input-1" description="Selecione o contrato">
                    <b-form-select id="input-1" v-model="pedido.id_schools_contracts" :options="optionsSchoolsContracts" required></b-form-select>
                  </b-form-group>
                  <!--id_budgets_status-->
                  <b-form-group class="col-6 col-md-3" label="Status" label-for="input-1" description="Selecione o status">
                    <b-form-select id="input-1" v-model="pedido.id_budgets_status" :options="optionsBudgetsStatus" required></b-form-select>
                  </b-form-group>
                  <!--title-->
                  <b-form-group class="col-6 col-md-3" label="Titulo" label-for="input-1" description="Titulo do pedido">
                    <b-form-input id="input-1" v-model="pedido.title" required></b-form-input>
                  </b-form-group>
                  <!--description-->

                  <!--qtd_slips-->
                  <b-form-group class="col-6 col-md-3" label="Quantidade de boletos" label-for="input-1" description="Quantidade de boletos">
                    <b-form-input id="input-1" v-model="pedido.qtd_slips"></b-form-input>
                  </b-form-group>
                  <!--tracking_code-->
                  <b-form-group class="col-6 col-md-3" label="Codigo de rastreio" label-for="input-1" description="Codigo de rastreio">
                    <b-form-input id="input-1" v-model="pedido.tracking_code"></b-form-input>
                  </b-form-group>
                  <b-form-group class="col-12" label="Descrição" label-for="input-1" description="Descrição do pedido">
                    <b-form-textarea id="input-1" v-model="pedido.description" required></b-form-textarea>
                  </b-form-group>

                  <div class="col">
                    <b-button type="submit" variant="primary">
                      <span v-if="pedido.id">Salvar alterações</span>
                      <span v-if="!pedido.id">Cadastrar novo</span>
                    </b-button>
                  </div>


                </b-form>
              </b-overlay>

            </div>
          </div>


          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-5">
                  <span class="btn btn-warning" v-if="pedido.budgets_status.type =='pending'">pendente</span>
                  <span class="btn btn-success" v-if="pedido.budgets_status.type =='concluded'">concluído</span>
                  <span class="btn btn-danger" v-if="pedido.budgets_status.type =='canceled'">cancelado</span>
                  <h2>Número do pedido: #{{ pedido.id }}</h2>
                  <p><strong>Data do pedido:</strong> {{ pedido.created_at | f_date }}<br>
                    <span v-if="pedido.budgets_books"><strong>Quantidade de itens:</strong> {{ pedido.budgets_books.length }}<br></span>
                    <strong>Valor total:</strong> {{ getValorTotal(pedido).replaceAll('.', ',') }}<br>
                    <!--                    <strong>Parcelas:</strong> {{ pedido.parcelas }}<br>-->
                    <!--                    <strong>Valor da parcela:</strong> {{ formatCurrentBrl(pedido.valor_parcela) }}-->
                  </p>

                </div>
                <div class="col-md-2">
                  <br>
                  <br>
                  <h4>Endereço de entrega</h4>
                  <p v-if="pedido.budgets_address && pedido.budgets_address[0]">
                    {{ pedido.budgets_address[0].address }}, {{ pedido.budgets_address[0].number }}, {{ pedido.budgets_address[0].complement }}<br>
                    {{ pedido.budgets_address[0].neighborhood }}<br>
                    {{ pedido.budgets_address[0].address2 }}<br>
                    {{ pedido.budgets_address[0].zipcode }}
                  </p>

                  <p v-if="pedido.tracking_code">
                    <strong>Código de rastreio:</strong> {{ pedido.tracking_code }}
                  </p>


                </div>
                <div class="col">
                  <br><br>
                  <h4>Status da entrega</h4>


                  <div class="row">
                    <div v-for="etapa in etapasEntregas" :key="etapa.id">
                      <div :class=" ' status-entrega ' + (verificaStep(pedido,etapa) ? 'verde':'') ">
                        <i class="fa fa-step-forward"></i>
                        <p>{{ etapa.label }}</p>
                      </div>
                    </div>

                  </div>


                </div>

              </div>
            </div>
          </div>


        </div>
      </div>


      <div class="row">
        <div class="col-md-8">
          <h3>Itens do pedido</h3>
          <div class="row" v-if="pedido && pedido.budgets_books">


            <template v-for="item in pedido.budgets_books">
              <div class="col-md-6" :key="item.id">
                <div class="card">
                  <div class="card-body">
                    <img :src="item.books.cover" width="200" alt="" class="float-left mr-5">
                    <h2>{{ item.books.label }}</h2>
                    <p>{{ item.books.bookschool }}</p>
                    <p><strong class="fa-2x">Quantidade:</strong> <span class="fa-3x">{{ item.dtq }}</span></p>
                    <p><strong class="fa-2x">Valor:</strong> <span class="fa-3x">R$ {{ parseFloat(item.value).toFixed(2).replaceAll('.', ',') }}</span></p>
                  </div>
                </div>
              </div>
            </template>


          </div>
        </div>
        <div class="col">
          <div class="card">
            <div class="card-body">
              <b-tabs content-class="mt-3">
                <b-tab title="Comentários" active>
                  <h3>Comentários</h3>

                  <b-form @submit="saveCommentario">
                    <h4>Novo comentario</h4>
                    <b-overlay :show="isCampoComentario">
                      <textarea v-model="campoComentario" style="height: 150px;" placeholder="Escreva algo..." cols="30" rows="10" class="form-control"></textarea>
                      <br>
                      <button class="btn btn-primary">Enviar</button>
                    </b-overlay>
                  </b-form>
                  <br>


                  <div v-for="item in pedido.budgets_comments" :key="item.id">
                    <h4>{{ item.peoples.name }}</h4>
                    <p>{{ item.comment }}</p>
                    <p><i>{{ item.created_at | f_date_time }}</i></p>
                    <hr>
                  </div>


                </b-tab>
                <b-tab title="Notas fiscais">
                  <h3>Notas fiscais</h3>

                  <b-overlay :show="loadingFormNota">
                    <b-form @submit="saveNota" class="was-validated" v-if="is_admin() || is_genesis_admin()">

                      <b-form-group label="Arquivo" label-for="local" description="Selecione um arquivo">
                        <b-form-file
                            v-model="fileUploadNota"
                            @change="handlerUploadNota"
                            required
                            placeholder="Selecione um arquivo"
                        ></b-form-file>
                      </b-form-group>

                      <b-form-group label="Descrição" label-for="description" description="Descrição da nota">
                        <b-form-input v-model="descriptionNota" required></b-form-input>
                      </b-form-group>

                      <b-button type="submit" variant="primary">
                        Enviar arquivo
                      </b-button>
                      <br>
                      <br>
                      <hr>
                    </b-form>
                  </b-overlay>

                  <div v-for="item in pedido.budgets_invoices" :key="item.id">
                    <a :href="item.local" target="_blank" class="btn btn-success float-right">Ver arquivo </a>
                    <span>
                         <strong>Data: </strong>  {{ item.created_at | f_date_time }}<br><strong>Descrição: </strong>{{ item.description }}
                         </span>
                    <hr>
                  </div>

                </b-tab>
                <b-tab title="Boletos">

                  <h3>Boletos</h3>

                  <b-overlay :show="loadingFormBoleto">
                    <b-form @submit="saveBoleto" class="was-validated" v-if="is_admin() || is_genesis_admin()">

                      <b-form-group label="Arquivo" label-for="local" description="Selecione um arquivo">
                        <b-form-file
                            v-model="fileUploadBoleto"
                            @change="handlerUploadBoleto"
                            required
                            placeholder="Selecione um arquivo"
                        ></b-form-file>
                      </b-form-group>

                      <b-form-group label="Descrição" label-for="description" description="Descrição do boleto">
                        <b-form-input v-model="descriptionBoleto" required></b-form-input>
                      </b-form-group>

                      <b-form-group label="Status" label-for="status" description="Status do boleto">
                        <b-form-select v-model="statusBoleto" :options="optionsBoletosPagoNaoPago" required></b-form-select>
                      </b-form-group>

                      <b-button type="submit" variant="primary">
                        Enviar arquivo
                      </b-button>
                      <br>
                      <br>
                      <hr>
                    </b-form>
                  </b-overlay>


                  <div v-for="item in pedido.budgets_invoices_slips" :key="item.id">
                    <a :href="item.local" target="_blank" class="btn btn-success float-right">Ver arquivo </a>
                    <span>
                         <strong>Data: </strong>  {{ item.created_at | f_date_time }}
                      <br><strong>Descrição: </strong>{{ item.description }}
                      <br><strong class="">Status: </strong><span :class="'badge '+(item.status == 0 ? 'badge-danger' : 'badge-success')">{{ item.status == 0 ? 'Não pago' : 'Pago' }}</span>
                         </span>

                    <hr>
                  </div>

                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>

    </b-overlay>

  </Layout>
</template>

<style>
.status-entrega {
  background: #ccc;
  border-radius: 110px;
  width: 110px;
  height: 110px;
  display: block;
  text-align: center;
  color: #fff;
  padding: 20px 5px 5px 5px;
  line-height: 100%;
  margin: 0 10px;
}

.status-entrega i {
  font-size: 40px;
  margin-bottom: 5px;
}

.status-entrega.verde {
  background: #1cbb8c;
}
</style>
