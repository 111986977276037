<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

export default {
    data: () => {
        return {
            title: "Meu contrato x",
            items: [
                {
                    text: "Genesis",
                    href: "/",
                },
                {
                    text: "Contrato",
                    active: true,
                },
            ],
            contratos: [
                {
                    id: 'e23t45y6u7i8o9p0',
                    created_at: '01/01/2021',
                    updated_at: '01/01/2021',
                    deleted_at: null,
                    cliente_id: 'e23t45y6u7i8o9p0',
                    validate_at: '01/01/2022',
                    status: 'ativo',
                    assinaturas: [
                        {
                            id: 1,
                            parte: 'locador',
                            nome: 'João da Silva Junior',
                            cpf: '123.456.789-00',
                            rg: '123456789',
                            orgao_expedidor: 'SSP',
                            data_expedicao: '01/01/2021',
                            estado_civil: 'solteiro',
                            profissao: 'programador',
                            nacionalidade: 'brasileiro',
                            endereco: 'Rua teste, 123',
                            cep: '12345-678',
                            cidade: 'São Paulo',
                            estado: 'SP',
                            telefone: '(11) 1234-5678',
                            email: 'teste@teste.com.br',
                            data_nascimento: '01/01/2021',
                            data_assinatura: '01/01/2021',
                            status: 'assinado',
                            validate: 'valido',
                        },
                        {
                            id: 2,
                            parte: 'locatario',
                            nome: 'Maria da Silva Junior',
                            cpf: '123.456.789-00',
                            rg: '123456789',
                            orgao_expedidor: 'SSP',
                            data_expedicao: '01/01/2021',
                            estado_civil: 'solteiro',
                            profissao: 'programador',
                            nacionalidade: 'brasileiro',
                            endereco: 'Rua teste, 123',
                            cep: '12345-678',
                            cidade: 'São Paulo',
                            estado: 'SP',
                            telefone: '(11) 1234-5678',
                            email: 'teste@teste.com.br',
                            data_nascimento: '01/01/2021',
                            data_assinatura: '01/01/2021',
                            status: 'assinado',
                            validate: 'valido',
                        },
                        {
                            id: 3,
                            parte: 'locatario',
                            nome: 'Joana da Silva Junior',
                            cpf: '123.456.789-00',
                            rg: '123456789',
                            orgao_expedidor: 'SSP',
                            data_expedicao: '01/01/2021',
                            estado_civil: 'solteiro',
                            profissao: 'programador',
                            nacionalidade: 'brasileiro',
                            endereco: 'Rua teste, 123',
                            cep: '12345-678',
                            cidade: 'São Paulo',
                            estado: 'SP',
                            telefone: '(11) 1234-5678',
                            email: 'teste@teste.com.br',
                            data_nascimento: '01/01/2021',
                            data_assinatura: '01/01/2021',
                            status: 'assinado',
                            validate: 'valido',
                        },
                    ]

                },
            ],
            comentarios : [
                {
                    id: 1,
                    comentario: 'Peço que seja feito a entrega no dia 01/01/2021',
                    data: '01/01/2021',
                    usuario: 'João da Silva Junior',
                },
                {
                    id: 2,
                    comentario: 'Ajuste o valor da parcela para R$ 500,00',
                    data: '01/01/2021',
                    usuario: 'Pedro J.',
                },
                {
                    id: 3,
                    comentario: 'Ajuste o texto do contrato no item 1.1',
                    data: '01/01/2021',
                    usuario: 'Mario Alencar',
                },
            ]
        }
    },
    components: {Layout, PageHeader},
}
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items"/>
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <h1>{{ title }}</h1>
                    </div>
                </div>

                <br>
                <br>

                <div v-for="item in contratos" :key="item.id">

                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-4">
                                    <span class="btn btn-success" v-if="item.status=='ativo'">ativo</span>
                                    <span class="btn btn-warning" v-if="item.status=='pendente'">pendente</span>
                                    <span class="btn btn-dark" v-if="item.status=='vencido'">vencido</span>
                                    <span class="btn btn-warning" v-if="item.status=='cancelado'">cancelado</span>
                                    <h2>#{{ item.id }}</h2>
                                    <h6 class="color-red">Data de vigencia: {{ item.created_at }} - {{ item.validate_at }}</h6>

                                </div>
                                <div class="col" v-if="item.status!='cancelado'">
                                    <h3>Assinaturas</h3>
                                    <div class="row">
                                        <div v-for="pessoa in item.assinaturas" :key="pessoa.id" class="col">
                                            <h5>{{ pessoa.nome }}</h5>
                                            <span class="badge badge-warning" v-if="pessoa.status=='pendente'">pendente</span>
                                            <span class="badge badge-info" v-if="pessoa.status=='assinado'">assinado</span>
                                            <span class="badge badge-light ml-2" v-if="pessoa.validate=='valido'">assinatura aprovada</span>
                                            <span class="badge badge-danger ml-2" v-if="pessoa.validate=='pendente'">assinatura pendente</span>
                                            <p>CPF: {{ pessoa.cpf }}<br>
                                                RG: {{ pessoa.rg }}<br>
                                                Data assinatura: {{ pessoa.data_assinatura }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="row">
                    <div class="col-lg-8">
                        <div class="card">
                            <div class="card-body">

                                <h1>CONTRATO</h1>
                                <br>

                                <h3>CONTRATO DE COMPRA DE MATERIAL ESCOLAR</h3>

                                <p>CONTRATO DE COMPRA DE MATERIAL ESCOLAR, que entre si fazem, de um lado, como CONTRATANTE, a pessoa física ou jurídica, devidamente qualificada na ficha cadastral, e de outro lado, como CONTRATADA, a empresa GENESIS, inscrita no CNPJ sob o nº 00.000.000/0000-00, com sede na Rua teste, 123, São Paulo, SP, CEP 12345-678, neste ato representada por seu representante legal, Sr. João da Silva Junior, brasileiro, casado, portador da cédula de identidade RG nº 123456789, expedida pela SSP/SP, inscrito no CPF sob o nº 123.456.789-00, residente e domiciliado na Rua teste, 123, São Paulo, SP, CEP 12345-678, doravante denominada simplesmente CONTRATADA, têm entre si, justo e contratado, o que segue:</p>

                                <h3>CLÁUSULA PRIMEIRA - DO OBJETO</h3>

                                <p>1.1. O presente contrato tem como objeto a compra de material escolar, conforme descrito na ficha cadastral, que é parte integrante deste instrumento.</p>

                                <h3>CLÁUSULA SEGUNDA - DO PREÇO</h3>

                                <p>2.1. O preço total do objeto deste contrato é de R$ 1.000,00 (um mil reais).</p>

                                <h3>CLÁUSULA TERCEIRA - DO PAGAMENTO</h3>

                                <p>3.1. O pagamento do preço será realizado da seguinte forma:</p>

                                <p>3.1.1. 1ª parcela no valor de R$ 500,00 (quinhentos reais), a ser paga no ato da assinatura deste contrato.</p>

                                <p>3.1.2. 2ª parcela no valor de R$ 500,00 (quinhentos reais), a ser paga no dia 01/01/2022.</p>

                                <h3>CLÁUSULA QUARTA - DA ENTREGA</h3>

                                <p>4.1. A entrega do objeto deste contrato será realizada no endereço indicado na ficha cadastral, no dia 01/01/2021.</p>

                                <h3>CLÁUSULA QUINTA - DA VIGÊNCIA</h3>

                                <p>5.1. O presente contrato terá vigência de 1 (um) ano, contado a partir da data de sua assinatura.</p>


                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="card">
                            <div class="card-body">

                                <h3>Comentários</h3>
                                <br>

                                <form action="">
                                    <h4>Novo comentario</h4>
                                    <textarea name="" id="" style="height: 150px;" placeholder="Solicite algum ajuste ao contrato." cols="30" rows="10" class="form-control"></textarea>
                                <br>    <button class="btn btn-primary">Enviar</button>
                                </form>
                                <br>


                                <div v-for="item in comentarios" :key="item.id">
                                    <h4>{{ item.usuario }}</h4>
                                    <p>{{ item.comentario }}</p>
                                    <p><i>{{item.data}}</i></p>
                                    <hr>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </Layout>
</template>

<style scoped lang="scss">

</style>
