<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import SchoolsContractsStore from "@/modules/schools-contracts/schools-contracts-store";
import SchoolsContractsPaginate from "@/components/paginate.vue";
import Swal from "sweetalert2";
import SchoolsContractsDelete from "@/modules/schools-contracts/components/schools-contracts-delete.vue";
import SchoolsContractsRemove from "@/modules/schools-contracts/components/schools-contracts-remove.vue";
import SchoolsContractsShow from "@/modules/schools-contracts/components/schools-contracts-show.vue";
import SchoolsStore from "@/modules/schools/schools-store";
import ContractsStore from "@/modules/contracts/contracts-store";
import PeoplesStore from "@/modules/peoples/peoples-store";

export default {
  name: "Contratos de escolas",
  data() {
    return {
      title: "Contratos de escolas",
      single: "Contrato de escola",
      items: [
        {text: "Home", to: {name: "home"}},
        {text: "Contratos de escolas", active: true},
      ],
      table: [
        {label: "ID", key: "id"},
        {label: "Escola", key: "schools.name"},
        {label: "Contrato", key: "contracts.name"},
        {label: "Pessoa", key: "peoples.name"},
        {label: "Status", key: "status"},
        {label: "Criação", key: "created_at"},
        {label: "Atualização", key: "updated_at"},
        {label: "...", key: "btn"},
      ],
      list: [],
      showNewForm: false,
      loadingForm: false,
      paginate: {
        total_pages: 1,
        page: 1,
        perPage: 10,
        total: 0,
      },
      loadingPage: false,
      buscar: "",
      filter_id_schools: null,
      filter_id_contracts: null,
      filter_id_peoples: null,
      form: {
        id: null,
        id_schools: null,
        id_contracts: null,
        id_peoples: null,
        id_schoolS_contracts: null,
        id_schoolS_contracts_final: null,
        version: null,
        description: null,
        status: null,
        data_start: null,
        data_stop: null,
      },
      optionsSchools: [{value: null, text: "Selecione a escola"}],
      optionsContracts: [{value: null, text: "Selecione o contrato"}],
      optionsPeoples: [{value: null, text: "Selecione a pessoa"}],
      optionsStatus: [
        {value: "pending", text: "Pendente"},
        {value: "resume", text: "Resumo"},
        {value: "publish", text: "Publicado"},
      ],
    }
  },
  components: {SchoolsContractsShow, SchoolsContractsRemove, SchoolsContractsDelete, SchoolsContractsPaginate, Layout, PageHeader},
  mounted() {
    this.getPaginate(this.paginate.page);

  },
  watch: {
    'form.name': function (newValue) {
      this.form.identifier = this.$slug_format(newValue);
    }
  }, methods: {
    async loadOptionsSchools() {
      await SchoolsStore.all().then((response) => {
        this.optionsSchools.push(...response.data.map((item) => {
          return {value: item.id, text: item.name};
        }));
      }).catch((error) => {
        console.log(error);
      });
    },
    async loadOptionsContracts() {
      await ContractsStore.all().then((response) => {
        console.log('response  xx1', response);
        this.optionsContracts.push(...response.data.data.map((item) => {
          return {value: item.id, text: item.name};
        }));
      }).catch((error) => {
        console.log(error);
      });
    },
    async loadOptionsPeoples() {
      await PeoplesStore.all().then((response) => {
        this.optionsPeoples.push(...response.data.data.map((item) => {
          return {value: item.id, text: item.name};
        }));
      }).catch((error) => {
        console.log(error);
      });
    },
    async getPaginate(e = 1) {
      this.paginate.page = e;
      this.loadingPage = true;

      await this.loadOptionsSchools();
      await this.loadOptionsContracts();
      await this.loadOptionsPeoples();

      SchoolsContractsStore.paginate({
        page: this.paginate.page,
        limit: this.paginate.perPage,
        search: this.buscar,
        id_schools: this.filter_id_schools,
        id_contracts: this.filter_id_contracts,
        id_peoples: this.filter_id_peoples,
      }).then((response) => {
        const {data, limit, page, total, total_pages} = response.data;
        this.list = data;
        this.paginate.total = total;
        this.paginate.perPage = limit;
        this.paginate.page = page;
        this.paginate.total_pages = total_pages;
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.loadingPage = false;
      });
    },
    cleanForm() {
      this.form = {
        id: null,
        id_schools: null,
        id_contracts: null,
        id_peoples: null,
        id_schoolS_contracts: null,
        id_schoolS_contracts_final: null,
        version: null,
        description: null,
        status: null,
      }
    },
    edit(item) {
      this.form = JSON.parse(JSON.stringify(item));
      this.showNewForm = true;
    },
    save(event) {
      event.preventDefault();
      this.loadingForm = true;

      delete this.form.contracts;
      delete this.form.peoples;
      delete this.form.schools;
      delete this.form.schools_contracts;

      if (this.form.id) {
        SchoolsContractsStore.update(this.form).then((response) => {
          this.form = response.data;
          this.getPaginate(1);
          this.cleanForm();
          this.showNewForm = false;
          Swal.fire({
            icon: "success",
            title: "Atualizado",
            text: "Contrato de escola atualizado com sucesso",
          });
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao atualizar",
            text: "Erro ao atualizar o Contrato de escola",
          });
        }).finally(() => {
          this.loadingForm = false;
        });
      } else {
        SchoolsContractsStore.create(this.form).then((response) => {
          this.form = response.data;
          this.getPaginate(1);
          this.cleanForm();
          this.showNewForm = false;
          Swal.fire({
            icon: "success",
            title: "Criado",
            text: "Contrato de escola criado com sucesso",
          });
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao criar",
            text: "Erro ao criar o Contrato de escola",
          });
        }).finally(() => {
          this.loadingForm = false;
        });
      }
    }
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <b-overlay :show="loadingPage">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h1>{{ title }}</h1>

              <div class="row mb-4 align-items-center">
                <div class="col-md-9">
                  <div class="row align-items-center">
                    <div class="col-md-8">
                      <div class="row">
                        <div class="col-md-3">
                          <input
                              v-model="buscar"
                              type="text"
                              placeholder="Pesquisar...."
                              class="form-control"
                              @change="getPaginate(1)"
                          />
                          <span class="d-block d-md-none"></span>
                        </div>
                        <div class="col-md-3">
                          <b-form-select
                              v-model="filter_id_schools"
                              :options="optionsSchools"
                              placeholder="Selecione a escola"
                              @change="getPaginate(1)"
                          ></b-form-select>
                        </div>
                        <div class="col-md-3">
                          <b-form-select
                              v-model="filter_id_contracts"
                              :options="optionsContracts"
                              placeholder="Selecione o contrato"
                              @change="getPaginate(1)"
                          ></b-form-select>
                        </div>
                        <div class="col-md-3">
                          <b-form-select
                              v-model="filter_id_peoples"
                              :options="optionsPeoples"
                              placeholder="Selecione a pessoa"
                              @change="getPaginate(1)"
                          ></b-form-select>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 col-md-2">
                      <button @click="getPaginate(1)" type="button" class="btn btn-light btn-block">
                        <i class="fa fa-search"></i> Buscar
                      </button>
                    </div>
                    <div class="col-6 col-md-2">
                      <button @click="buscar=null; getPaginate(1) " class="btn btn-light btn-block">
                        <i class="fa fa-broom"></i> Limpar
                      </button>
                    </div>
                  </div>
                </div>

                <div class="col-md-3 mb-2 text-right align-items-center">
                  <span class="d-block d-md-none p-2"> </span>
                  <div>
                    <button class="btn btn-primary" @click="showNewForm = true">
                      <i class="fa fa-plus"></i>
                      <span v-if="form.id">Editando</span>
                      <span v-if="!form.id">Novo</span>
                    </button>
                  </div>
                  <div v-show="showNewForm">
                    <div class="box-form text-left">
                      <div id="form-novo-edit">
                        <i @click="showNewForm = false; cleanForm();" class="fa float-right fa-2x fa-eye-slash"></i>
                        <h1 class="mt-5 mb-5" v-if="!form.id">Nova {{ single }}</h1>
                        <h1 class="mt-5 mb-5" v-if="form.id">Editando {{ single }}</h1>
                        <div>
                          <b-overlay :show="loadingForm">
                            <b-form @submit="save" class="was-validated">

                              <b-form-group label="Escola" label-for="id_schools" description="Seleciona a escola">
                                <b-form-select
                                    id="id_schools"
                                    v-model="form.id_schools"
                                    :options="optionsSchools"
                                    required
                                ></b-form-select>
                              </b-form-group>

                              <b-form-group label="Contrato" label-for="id_contracts" description="Seleciona o contrato">
                                <b-form-select
                                    id="id_contracts"
                                    v-model="form.id_contracts"
                                    :options="optionsContracts"
                                    required
                                ></b-form-select>
                              </b-form-group>

                              <b-form-group label="Pessoa" label-for="id_peoples" description="Seleciona a pessoa">
                                <b-form-select
                                    id="id_peoples"
                                    v-model="form.id_peoples"
                                    :options="optionsPeoples"
                                    required
                                ></b-form-select>
                              </b-form-group>

                              <b-form-group label="Status" label-for="status" description="Seleciona o status">
                                <b-form-select
                                    v-model="form.status"
                                    :options="optionsStatus"
                                    required
                                ></b-form-select>
                              </b-form-group>

                              <b-form-group label="Descrição" label-for="description" description="Descrição do contrato">
                                <b-form-textarea
                                    id="description"
                                    v-model="form.description"
                                    required
                                ></b-form-textarea>
                              </b-form-group>

                              <b-form-group label="Versão" label-for="version" description="Versão do contrato">
                                <b-form-input
                                    id="version"
                                    v-model="form.version"
                                    required
                                ></b-form-input>
                              </b-form-group>

                              <b-form-group label="Data de início" label-for="data_start" description="Data de início do contrato">
                                <b-form-input
                                    type="date"
                                    v-model="form.date_start"
                                ></b-form-input>
                              </b-form-group>

                              <b-form-group label="Data de término" label-for="data_stop" description="Data de término do contrato">
                                <b-form-input
                                    type="date"
                                    v-model="form.date_stop"
                                ></b-form-input>
                              </b-form-group>


                              <b-button type="submit" variant="primary">
                                <span v-if="form.id">Salvar alterações</span>
                                <span v-if="!form.id">Cadastrar novo</span>
                              </b-button>
                            </b-form>
                          </b-overlay>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <schools-contracts-paginate :paginate="paginate" @change-page="getPaginate"></schools-contracts-paginate>
               <b-table striped hover :items="list" :fields="table" class="table-responsive">
                <template v-slot:cell(updated_at)="data">
                  {{ data.item.created_at | f_date_time }}
                </template>
                <template v-slot:cell(status)="data">
                  <span v-if="data.item.status === 'pending'" class="badge badge-warning">Pendente</span>
                  <span v-if="data.item.status === 'resume'" class="badge badge-info">Resumo</span>
                  <span v-if="data.item.status === 'publish'" class="badge badge-success">Publicado</span>
                </template>
                <template v-slot:cell(created_at)="data">
                  {{ data.item.created_at | f_date_time }}
                </template>
                <template v-slot:cell(btn)="data">
                  <router-link :to="'/cliente/contrato/'+data.item.id" class="btn btn-sm btn-warning">Abrir contrato</router-link>
                  <button @click="edit(data.item)" class="btn pl-4 pr-4 btn-sm btn-info"><i class="fa fa-edit"></i> Editar</button>
                  <schools-contracts-delete :value="data.item" @change="getPaginate(1)"></schools-contracts-delete>
                  <!--                  <schools-contracts-remove :value="data.item" @change="getPaginate(1)"></schools-contracts-remove>-->
                  <schools-contracts-show :value="data.item"></schools-contracts-show>
                </template>
              </b-table>
              <schools-contracts-paginate :paginate="paginate" @change-page="getPaginate"></schools-contracts-paginate>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </Layout>
</template>

<style scoped>

</style>

