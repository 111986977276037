<script>


export default {
  props: {
    config: Object,
    options: Array,
  },
  emits: ['changeUpdate'],
  methods: {
    changeFile (e, value)  {
      const valueX = JSON.parse(JSON.stringify(value));
      this.$emit('changeUpdate', valueX, e.target.files[0])
    },
    changeEvent (value) {
      this.$emit('changeUpdate', JSON.parse(JSON.stringify(value)))
    }
  }
}

</script>

<template>
  <b-overlay v-if="config" :show="config.isLoad">
    <b-form-group :label="config.label" :class=" (config.required ? ' required ' : '') + ' '" :description="config.description">
      <b-form-file v-if="config.type==='file'" @change="changeFile($event,config)" maxlength="255" :required="config.required"></b-form-file>
      <b-form-select v-else-if="config.type==='select'" :options="options" @change="changeEvent(config)" v-model="config.form.value" maxlength="255" :required="config.required"></b-form-select>
      <b-form-input v-else :type="config.type" :min="config.min" @change="changeEvent(config)" v-model="config.form.value" maxlength="255" :required="config.required"></b-form-input>
      <span v-if="config.request === true" class="badge badge-pill badge-success"><i class="fa fa-wifi"></i></span>
      <span v-if="config.request === false" @click="changeEvent(config)" class="badge badge-pill badge-danger text-white"><i class="fa fa-exclamation-triangle"></i><i class="ri ri-refresh-line"></i></span>
      <a v-if="config.type==='file'" class="btn btn-sm btn-default mt-2 mr-2" :href="config.form.value" target="_blank">Download arquivo</a>


      <span class="btn btn-sm btn-default mt-2" v-if="config.type==='file'" v-b-modal="'campo-'+config.key">Ver arquivo</span>
      <b-modal v-if="config.type==='file'" :id="'campo-'+config.key" hide-footer title="Ver arquivo">
        <img :src="config.form.value"/>
      </b-modal>
    </b-form-group>
  </b-overlay>
</template>

<style>
.form-group {
  position: relative;
}

.form-group.required::before {
  content: "*";
  color: red;
  position: absolute;
  top: -28px;
  left: 0;
}

.form-group.required legend {
  margin-left: 10px !important;
}

.form-group .badge {
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 999;
  cursor: pointer;
}
</style>