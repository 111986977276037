<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

export default {
    data: () => {
        return {
            title: "Meus contratos x",
            items: [
                {
                    text: "Genesis",
                    href: "/",
                },
                {
                    text: "Contratos",
                    active: true,
                },
            ],
            contratos: [
                {
                    id: 'e23t45y6u7i8o9p0',
                    created_at: '01/01/2021',
                    updated_at: '01/01/2021',
                    deleted_at: null,
                    cliente_id: 'e23t45y6u7i8o9p0',
                    validate_at: '01/01/2022',
                    status: 'ativo',
                    assinaturas: [
                        {
                            id: 1,
                            parte: 'locador',
                            nome: 'João da Silva Junior',
                            cpf: '123.456.789-00',
                            rg: '123456789',
                            orgao_expedidor: 'SSP',
                            data_expedicao: '01/01/2021',
                            estado_civil: 'solteiro',
                            profissao: 'programador',
                            nacionalidade: 'brasileiro',
                            endereco: 'Rua teste, 123',
                            cep: '12345-678',
                            cidade: 'São Paulo',
                            estado: 'SP',
                            telefone: '(11) 1234-5678',
                            email: 'teste@teste.com.br',
                            data_nascimento: '01/01/2021',
                            data_assinatura: '01/01/2021',
                            status: 'assinado',
                            validate: 'valido',
                        },
                        {
                            id: 2,
                            parte: 'locatario',
                            nome: 'Maria da Silva Junior',
                            cpf: '123.456.789-00',
                            rg: '123456789',
                            orgao_expedidor: 'SSP',
                            data_expedicao: '01/01/2021',
                            estado_civil: 'solteiro',
                            profissao: 'programador',
                            nacionalidade: 'brasileiro',
                            endereco: 'Rua teste, 123',
                            cep: '12345-678',
                            cidade: 'São Paulo',
                            estado: 'SP',
                            telefone: '(11) 1234-5678',
                            email: 'teste@teste.com.br',
                            data_nascimento: '01/01/2021',
                            data_assinatura: '01/01/2021',
                            status: 'assinado',
                            validate: 'valido',
                        },
                        {
                            id: 3,
                            parte: 'locatario',
                            nome: 'Joana da Silva Junior',
                            cpf: '123.456.789-00',
                            rg: '123456789',
                            orgao_expedidor: 'SSP',
                            data_expedicao: '01/01/2021',
                            estado_civil: 'solteiro',
                            profissao: 'programador',
                            nacionalidade: 'brasileiro',
                            endereco: 'Rua teste, 123',
                            cep: '12345-678',
                            cidade: 'São Paulo',
                            estado: 'SP',
                            telefone: '(11) 1234-5678',
                            email: 'teste@teste.com.br',
                            data_nascimento: '01/01/2021',
                            data_assinatura: '01/01/2021',
                            status: 'assinado',
                            validate: 'valido',
                        },
                    ]

                },
                {
                    id: 'e23t45y6u7i8o9p0',
                    created_at: '01/01/2021',
                    updated_at: '01/01/2021',
                    validate_at: '01/01/2022',
                    deleted_at: null,
                    cliente_id: 'e23t45y6u7i8o9p0',
                    status: 'pendente',
                    assinaturas: [
                        {
                            id: 1,
                            parte: 'locador',
                            nome: 'João da Silva Junior',
                            cpf: '123.456.789-00',
                            rg: '123456789',
                            orgao_expedidor: 'SSP',
                            data_expedicao: '01/01/2021',
                            estado_civil: 'solteiro',
                            profissao: 'programador',
                            nacionalidade: 'brasileiro',
                            endereco: 'Rua teste, 123',
                            cep: '12345-678',
                            cidade: 'São Paulo',
                            estado: 'SP',
                            telefone: '(11) 1234-5678',
                            email: 'teste@teste.com.br',
                            data_nascimento: '01/01/2021',
                            data_assinatura: '01/01/2021',
                            status: 'pendente',
                            validate: 'pendente',
                        },
                        {
                            id: 2,
                            parte: 'locatario',
                            nome: 'Maria da Silva Junior',
                            cpf: '123.456.789-00',
                            rg: '123456789',
                            orgao_expedidor: 'SSP',
                            data_expedicao: '01/01/2021',
                            estado_civil: 'solteiro',
                            profissao: 'programador',
                            nacionalidade: 'brasileiro',
                            endereco: 'Rua teste, 123',
                            cep: '12345-678',
                            cidade: 'São Paulo',
                            estado: 'SP',
                            telefone: '(11) 1234-5678',
                            email: 'teste@teste.com.br',
                            data_nascimento: '01/01/2021',
                            data_assinatura: '01/01/2021',
                            status: 'pendente',
                            validate: 'pendente',
                        },
                        {
                            id: 3,
                            parte: 'locatario',
                            nome: 'Joana da Silva Junior',
                            cpf: '123.456.789-00',
                            rg: '123456789',
                            orgao_expedidor: 'SSP',
                            data_expedicao: '01/01/2021',
                            estado_civil: 'solteiro',
                            profissao: 'programador',
                            nacionalidade: 'brasileiro',
                            endereco: 'Rua teste, 123',
                            cep: '12345-678',
                            cidade: 'São Paulo',
                            estado: 'SP',
                            telefone: '(11) 1234-5678',
                            email: 'teste@teste.com.br',
                            data_nascimento: '01/01/2021',
                            data_assinatura: '01/01/2021',
                            status: 'assinado',
                            validate: 'valido',
                        },
                    ]

                },
                {
                    id: 'e23t45y6u7i8o9p0',
                    created_at: '01/01/2021',
                    updated_at: '01/01/2021',
                    validate_at: '01/01/2022',
                    deleted_at: null,
                    cliente_id: 'e23t45y6u7i8o9p0',
                    status: 'vencido',
                    assinaturas: [
                        {
                            id: 1,
                            parte: 'locador',
                            nome: 'João da Silva Junior',
                            cpf: '123.456.789-00',
                            rg: '123456789',
                            orgao_expedidor: 'SSP',
                            data_expedicao: '01/01/2021',
                            estado_civil: 'solteiro',
                            profissao: 'programador',
                            nacionalidade: 'brasileiro',
                            endereco: 'Rua teste, 123',
                            cep: '12345-678',
                            cidade: 'São Paulo',
                            estado: 'SP',
                            telefone: '(11) 1234-5678',
                            email: 'teste@teste.com.br',
                            data_nascimento: '01/01/2021',
                            data_assinatura: '01/01/2021',
                            status: 'assinado',
                            validate: 'valido',
                        },
                        {
                            id: 2,
                            parte: 'locatario',
                            nome: 'Maria da Silva Junior',
                            cpf: '123.456.789-00',
                            rg: '123456789',
                            orgao_expedidor: 'SSP',
                            data_expedicao: '01/01/2021',
                            estado_civil: 'solteiro',
                            profissao: 'programador',
                            nacionalidade: 'brasileiro',
                            endereco: 'Rua teste, 123',
                            cep: '12345-678',
                            cidade: 'São Paulo',
                            estado: 'SP',
                            telefone: '(11) 1234-5678',
                            email: 'teste@teste.com.br',
                            data_nascimento: '01/01/2021',
                            data_assinatura: '01/01/2021',
                            status: 'assinado',
                            validate: 'valido',
                        },
                        {
                            id: 3,
                            parte: 'locatario',
                            nome: 'Joana da Silva Junior',
                            cpf: '123.456.789-00',
                            rg: '123456789',
                            orgao_expedidor: 'SSP',
                            data_expedicao: '01/01/2021',
                            estado_civil: 'solteiro',
                            profissao: 'programador',
                            nacionalidade: 'brasileiro',
                            endereco: 'Rua teste, 123',
                            cep: '12345-678',
                            cidade: 'São Paulo',
                            estado: 'SP',
                            telefone: '(11) 1234-5678',
                            email: 'teste@teste.com.br',
                            data_nascimento: '01/01/2021',
                            data_assinatura: '01/01/2021',
                            status: 'assinado',
                            validate: 'valido',
                        },
                    ]

                },
                {
                    id: 'e23t45y6u7i8o9p0',
                    created_at: '01/01/2021',
                    updated_at: '01/01/2021',
                    validate_at: '01/01/2022',
                    deleted_at: null,
                    cliente_id: 'e23t45y6u7i8o9p0',
                    status: 'cancelado',
                    assinaturas: [
                        {
                            id: 1,
                            parte: 'locador',
                            nome: 'João da Silva Junior',
                            cpf: '123.456.789-00',
                            rg: '123456789',
                            orgao_expedidor: 'SSP',
                            data_expedicao: '01/01/2021',
                            estado_civil: 'solteiro',
                            profissao: 'programador',
                            nacionalidade: 'brasileiro',
                            endereco: 'Rua teste, 123',
                            cep: '12345-678',
                            cidade: 'São Paulo',
                            estado: 'SP',
                            telefone: '(11) 1234-5678',
                            email: 'teste@teste.com.br',
                            data_nascimento: '01/01/2021',
                            data_assinatura: '01/01/2021',
                            status: 'assinado',
                            validate: 'valido',
                        },
                        {
                            id: 2,
                            parte: 'locatario',
                            nome: 'Maria da Silva Junior',
                            cpf: '123.456.789-00',
                            rg: '123456789',
                            orgao_expedidor: 'SSP',
                            data_expedicao: '01/01/2021',
                            estado_civil: 'solteiro',
                            profissao: 'programador',
                            nacionalidade: 'brasileiro',
                            endereco: 'Rua teste, 123',
                            cep: '12345-678',
                            cidade: 'São Paulo',
                            estado: 'SP',
                            telefone: '(11) 1234-5678',
                            email: 'teste@teste.com.br',
                            data_nascimento: '01/01/2021',
                            data_assinatura: '01/01/2021',
                            status: 'assinado',
                            validate: 'valido',
                        },
                        {
                            id: 3,
                            parte: 'locatario',
                            nome: 'Joana da Silva Junior',
                            cpf: '123.456.789-00',
                            rg: '123456789',
                            orgao_expedidor: 'SSP',
                            data_expedicao: '01/01/2021',
                            estado_civil: 'solteiro',
                            profissao: 'programador',
                            nacionalidade: 'brasileiro',
                            endereco: 'Rua teste, 123',
                            cep: '12345-678',
                            cidade: 'São Paulo',
                            estado: 'SP',
                            telefone: '(11) 1234-5678',
                            email: 'teste@teste.com.br',
                            data_nascimento: '01/01/2021',
                            data_assinatura: '01/01/2021',
                            status: 'assinado',
                            validate: 'valido',
                        },
                    ]

                },
            ]
        }
    },
    components: {Layout, PageHeader},
}
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items"/>
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <h1 class="float-left">{{ title }}</h1>


                        <b-button class="btn btn-info float-right" v-b-modal.modal-1>Solicitar novo contrato</b-button>

                        <b-modal id="modal-1" title="Pedido de novo contrato enviado!">
                            <p class="my-4">
                                Seu pedido de novo contrato foi enviado com sucesso, em breve entraremos em contato.
                            </p>
                        </b-modal>
                    </div>
                </div>

                <br>
                <br>

                <div v-for="item in contratos" :key="item.id">

                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-4">
                                    <span class="btn btn-success" v-if="item.status=='ativo'">ativo</span>
                                    <span class="btn btn-warning" v-if="item.status=='pendente'">pendente</span>
                                    <span class="btn btn-dark" v-if="item.status=='vencido'">vencido</span>
                                    <span class="btn btn-warning" v-if="item.status=='cancelado'">cancelado</span>
                                    <h2>#{{ item.id }}</h2>
                                    <h6 class="color-red">Data de vigencia: {{ item.created_at }} - {{ item.validate_at}}</h6>
                                    <router-link to="/cliente/contrato" class="btn btn-outline-warning" >Ver contrato</router-link>
                                    <button v-if="item.status=='pendente'" class="btn btn-danger ml-4" >Assinar</button>
                                </div>
                                <div class="col" v-if="item.status!='cancelado'">
                                    <h3>Assinaturas</h3>
                                    <div class="row">
                                        <div v-for="pessoa in item.assinaturas" :key="pessoa.id" class="col">
                                            <h5>{{pessoa.nome}}</h5>
                                            <span class="badge badge-warning" v-if="pessoa.status=='pendente'">pendente</span>
                                            <span class="badge badge-info" v-if="pessoa.status=='assinado'">assinado</span>
                                            <span class="badge badge-light ml-2" v-if="pessoa.validate=='valido'">assinatura aprovada</span>
                                            <span class="badge badge-danger ml-2" v-if="pessoa.validate=='pendente'">assinatura pendente</span>
                                            <p>CPF: {{pessoa.cpf}}<br>
                                                RG: {{pessoa.rg}}<br>
                                                Data assinatura: {{pessoa.data_assinatura}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </Layout>
</template>

<style scoped lang="scss">

</style>
