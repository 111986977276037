<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div
              class="authentication-page-content p-4 d-flex align-items-center min-vh-100"
            >
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img
                              src="@/assets/images/logo-dark.png"
                              height="20"
                              alt="logo"
                            />
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-4">Nova Senha</h4>
                        <p class="text-muted">Digite sua nova senha.</p>
                      </div>

                      <div class="p-2 mt-5">
                        <b-alert
                          v-model="registerSuccess"
                          class="mt-3"
                          variant="success"
                          dismissible
                          >Registration successfull.</b-alert
                        >

                        <b-alert
                          variant="danger"
                          class="mt-3"
                          v-if="notification.message"
                          show
                          dismissible
                          >{{ notification.message }}</b-alert
                        >

                        <form class="form-horizontal">
                          <div class="form-group auth-form-group-custom mb-4">
                            <i
                              class="ri-lock-2-line auti-custom-input-icon"
                            ></i>
                            <label for="username">Senha</label>
                            <input
                              v-model="user.password"
                              type="password"
                              class="form-control"
                              id="username"
                              :class="{
                                'is-invalid':
                                  submitted && $v.user.password.$error,
                              }"
                              placeholder="Digite sua senha"
                            />
                            <div
                              v-if="submitted && !$v.user.password.required"
                              class="invalid-feedback"
                            >
                              Senha é obrigatória.
                            </div>
                            <div
                              v-if="submitted && !$v.user.password.minLength"
                              class="invalid-feedback"
                            >
                              A senha precisa ter no mínimo 8 dígitos.
                            </div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-4">
                            <i
                              class="ri-lock-2-line auti-custom-input-icon"
                            ></i>
                            <label for="userpassword">Confirme sua senha</label>
                            <input
                              v-model="user.confirmPassword"
                              type="password"
                              class="form-control"
                              id="userpassword"
                              placeholder="Confirme sua senha"
                              :class="{
                                'is-invalid':
                                  submitted && $v.user.confirmPassword.$error,
                              }"
                            />
                            <div
                              v-if="
                                submitted && !$v.user.confirmPassword.required
                              "
                              class="invalid-feedback"
                            >
                              Confirmação de senha é obrigatória.
                            </div>
                            <div
                              v-if="
                                submitted &&
                                !$v.user.confirmPassword.sameAsPassword
                              "
                              class="invalid-feedback"
                            >
                              As senhas precisam ser iguais.
                            </div>
                            <div
                              v-if="
                                submitted && !$v.user.confirmPassword.minLength
                              "
                              class="invalid-feedback"
                            >
                              A senha precisa ter no mínimo 8 dígitos.
                            </div>
                          </div>

                          <div class="text-center">
                            <button
                              :disabled="this.$v.$invalid"
                              class="btn btn-primary w-md waves-effect waves-light"
                              @click.prevent="sendNewPassword"
                            >
                              Salvar senha
                            </button>
                          </div>
                        </form>
                      </div>

                      <div class="mt-5 text-center">
                        <p>
                          Problemas com seu código ? Solicite outro código
                          <router-link

                            to="/forgot-password"
                            class="font-weight-medium text-primary"
                            >aqui !</router-link
                          >
                        </p>
                        <p>
                          Já possui uma conta ?
                          <router-link

                            to="/login"
                            class="font-weight-medium text-primary"
                            >Login</router-link
                          >
                        </p>
                        <p>© {{ new Date().getFullYear() }} Gestio School</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ERROR_MESSAGES from "../../../utils/ERROR_MESSAGES";
import { authMethods, notificationMethods } from "@/state/helpers";
import { minLength, required, sameAs } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      user: { confirmPassword: "", password: "" },
      submitted: false,
      regError: null,
      tryingToRegister: false,
      isRegisterError: false,
      registerSuccess: false,
    };
  },

  mounted() {
    document.body.classList.add("auth-body-bg");

    this.validateCode();
  },

  validations: {
    user: {
      password: { required, minLength: minLength(8) },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password"),
        minLength: minLength(8),
      },
    },
  },

  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },

  methods: {
    ...authMethods,
    ...notificationMethods,

    validateCode() {
      const { code } = this.$route.query;

      return this.verifyPasswordResetCode(code)
        .then(() => {})
        .catch((e) => {
          this.notification.message = ERROR_MESSAGES[e.code];
        });
    },

    sendNewPassword() {
      this.submitted = true;

      const code = this.$route.query.oobCode;
      const password = this.user.password;

      return this.confirmResetPassword({ code, password })
        .then(() => {
          this.$router.push("/login");
          this.registerSuccess = "Senha trocada com sucesso !";
        })
        .catch((e) => {
          this.notification.message = ERROR_MESSAGES[e.code];
        });
    },
  },
};
</script>
