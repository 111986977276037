import firebase from "firebase/app";
// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";
import axios from "axios";

class FirebaseAuthBackend {
    constructor(firebaseConfig) {
        if (firebaseConfig) {
            // Initialize Firebase
            firebase.initializeApp(firebaseConfig);
            firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    sessionStorage.setItem("authUser", JSON.stringify(user));
                } else {
                    sessionStorage.removeItem("authUser");
                }
            });
        }
    }

    /**
     * Registers the user with given details
     */
    registerUser = (email, password) => {
        return new Promise((resolve, reject) => {
            firebase
                .auth()
                .createUserWithEmailAndPassword(email, password)
                .then(
                    (user) => {
                        // eslint-disable-next-line no-redeclare
                        var user = firebase.auth().currentUser;
                        resolve(user);
                    },
                    (error) => {
                        reject(this._handleError(error));
                    }
                );
        });
    };

    /**
     * Login user with given details
     */
    loginUser = (email, password) => {
        return new Promise((resolve, reject) => {
            firebase
                .auth()
                .signInWithEmailAndPassword(email, password)
                .then(
                    async (user) => {
                        // eslint-disable-next-line no-redeclare
                        var user = firebase.auth().currentUser;

                        localStorage.setItem("uid", user.uid);

                        user.getIdToken().then((token) => {
                            localStorage.setItem("jwtToken", token);
                        });
                        const domain = process.env.VUE_APP_DOMAIN
                        const urlPerfil = `${domain}/v1_genesis/v1/users/fire/${user.uid}`
                        await axios.get(urlPerfil).then((response) => {
                            const perfil = response.data;
                            console.log('perfil', perfil);
                            const base64 = btoa(JSON.stringify(perfil));
                            localStorage.setItem("perfil", base64);
                            localStorage.setItem("type_user", perfil.type);
                        });

                        resolve(user);
                    },
                    (error) => {
                        reject(this._handleError(error));
                    }
                );
        });
    };

    /**
     * forget Password user with given details
     */
    forgetPassword = (email) => {
        let host = window.location.host;
        host = host.split(":")[0] ? host.split(":")[0] : host;

        return new Promise((resolve, reject) => {
            firebase
                .auth()
                .sendPasswordResetEmail(email, {
                    url: window.location.protocol + "//" + host,
                })
                .then(() => {
                    resolve(true);
                })
                .catch((error) => {
                    reject(this._handleError(error));
                });
        });
    };

    verifyPasswordResetCode = (code) => {
        return new Promise((resolve, reject) => {
            firebase
                .auth()
                .verifyPasswordResetCode(code)
                .then(() => {
                    resolve(true);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    confirmResetPassword = (token, newPassword) => {
        return new Promise((resolve, reject) => {
            firebase
                .auth()
                .confirmPasswordReset(token, newPassword)
                .then(() => {
                    resolve(true);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    /**
     * Logout the user
     */
    logout = () => {
        return new Promise((resolve, reject) => {
            firebase
                .auth()
                .signOut()
                .then(() => {
                    resolve(true);
                })
                .catch((error) => {
                    reject(this._handleError(error));
                });
        });
    };

    setLoggeedInUser = (user) => {
        sessionStorage.setItem("authUser", JSON.stringify(user));
    };

    /**
     * Returns the authenticated user
     */
    getAuthenticatedUser = () => {
        if (!sessionStorage.getItem("authUser")) return null;
        return JSON.parse(sessionStorage.getItem("authUser"));
    };

    /**
     * Handle the error
     * @param {*} error
     */
    _handleError(error) {
        const errorMessage = error.message;
        return errorMessage;
    }
}

let _fireBaseBackend = null;

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = (config) => {
    if (!_fireBaseBackend) {
        _fireBaseBackend = new FirebaseAuthBackend(config);
    }
    return _fireBaseBackend;
};

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
    return _fireBaseBackend;
};

export {initFirebaseBackend, getFirebaseBackend};
