<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import BooksValuesStore from "@/modules/books-values/books-values-store";
import BudgetsStore from "@/modules/budgets/budgets-store";
import SchoolsTypesPaginate from "@/components/paginate.vue";
import BudgetsStepsStore from "@/modules/budgets-steps/budgets-steps-store";

export default {
  data: () => {
    return {
      title: "Meus pedidos",
      items: [
        {
          text: "Genesis",
          href: "/",
        },
        {
          text: "Pedidos",
          active: true,
        },
      ],

      loadingPage: false,
      list: [],
      paginate: {
        total_pages: 1,
        page: 1,
        perPage: 10,
        total: 0,
      },
      perfil: null,
      etapasEntregas: []

    }
  },
  components: {SchoolsTypesPaginate, Layout, PageHeader},
  mounted() {
    const perfilJson = localStorage.getItem("perfil");
    this.perfil = JSON.parse(atob(perfilJson));
    this.getPaginate(this.paginate.page);
  },
  methods: {
    formatCurrentBrl(value) {
      try {
        return value.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
      } catch (e) {
        return value;
      }
    },

    async getPaginate(e = 1) {
      this.paginate.page = e;
      this.loadingPage = true;


      await BudgetsStepsStore.all().then((response) => {
        const {data} = response;
        this.etapasEntregas = data;
      }).catch((error) => {
        console.log(error);
      });


      BudgetsStore.paginate({
        page: this.paginate.page,
        limit: this.paginate.perPage,
        id_peoples: this.perfil.id_people,
        search: this.buscar,
      }).then((response) => {
        const {data, limit, page, total, total_pages} = response.data;
        this.list = data;
        this.paginate.total = total;
        this.paginate.perPage = limit;
        this.paginate.page = page;
        this.paginate.total_pages = total_pages;
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.loadingPage = false;
      });
    },

    getValorTotal(item) {
      try {
        let total = 0;
        item.budgets_books.forEach((book) => {
          total += parseFloat(book.value);
        });
        return parseFloat(total).toFixed(2);

      } catch (e) {
        return parseFloat(0).toFixed(2);
      }
    },
    verificaStep(item, etapa) {
      try {

        const id_budget_step = item.id_budget_step;
        const step = this.etapasEntregas.find((step) => step.id == item.id_budget_step);


        if (step.id == etapa.id) {
          return true;
        }

        if (etapa.order <= step.order) {
          return true;
        }

        return false;

      } catch (e) {
        return false;
      }
    }

  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <b-overlay :show="loadingPage">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h1 class="float-left">{{ title }}</h1>
              <router-link to="/cliente/pedido-novo" class="btn btn-primary float-right">Novo pedido</router-link>
            </div>
          </div>

          <br>
          <br>


          <div v-for="item in list" :key="item.id">


            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-5">
                    <span class="btn btn-warning" v-if="item.budgets_status.type =='pending'">pendente</span>
                    <span class="btn btn-success" v-if="item.budgets_status.type =='concluded'">concluído</span>
                    <span class="btn btn-danger" v-if="item.budgets_status.type =='canceled'">cancelado</span>
                    <h2>Número do pedido: #{{ item.id }}</h2>
                    <p><strong>Data do pedido:</strong> {{ item.created_at | f_date }}<br>
                      <strong>Quantidade de itens:</strong> {{ item.budgets_books.length }}<br>
                      <strong>Valor total:</strong> {{ getValorTotal(item).replaceAll('.', ',') }}<br>
                      <!--                    <strong>Parcelas:</strong> {{ item.parcelas }}<br>-->
                      <!--                    <strong>Valor da parcela:</strong> {{ formatCurrentBrl(item.valor_parcela) }}-->
                    </p>

                  </div>
                  <div class="col-2">
                    <br>
                    <br>
                    <h4>Endereço de entrega</h4>
                    <p v-if="item.budgets_address && item.budgets_address[0]">
                      {{ item.budgets_address[0].address }}, {{ item.budgets_address[0].number }}, {{ item.budgets_address[0].complement }}<br>
                      {{ item.budgets_address[0].neighborhood }}<br>
                      {{ item.budgets_address[0].address2 }}<br>
                      {{ item.budgets_address[0].zipcode }}
                    </p>

                  </div>
                  <div class="col">
                    <br><br>
                    <h4>Status da entrega</h4>


                    <div class="row">
                      <div v-for="etapa in etapasEntregas" :key="etapa.id">
                        <div :class=" ' status-entrega ' + (verificaStep(item,etapa) ? 'verde':'') ">
                          <i class="fa fa-step-forward"></i>
                          <p>{{ etapa.label }}</p>
                        </div>
                      </div>

                    </div>


                  </div>
                  <div class="col-1">
                    <br>
                    <br>
                    <br>
                    <br>
                    <br>
                    <router-link :to="'/cliente/pedido/'+item.id" class="btn btn-block btn-dark">Ver pedido</router-link>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <schools-types-paginate :paginate="paginate" @change-page="getPaginate"></schools-types-paginate>
        </div>
      </div>
    </b-overlay>
  </Layout>
</template>

<style>
.status-entrega {
  background: #ccc;
  border-radius: 110px;
  width: 110px;
  height: 110px;
  display: block;
  text-align: center;
  color: #fff;
  padding: 20px 5px 5px 5px;
  line-height: 100%;
  margin: 0 10px;
}

.status-entrega i {
  font-size: 40px;
  margin-bottom: 5px;
}

.status-entrega.verde {
  background: #1cbb8c;
}
</style>
