<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import UsersStore from "@/modules/users/users-store";
import UsersPaginate from "@/components/paginate.vue";
import Swal from "sweetalert2";
import UsersDelete from "@/modules/users/components/users-delete.vue";
import UsersRemove from "@/modules/users/components/users-remove.vue";
import UsersShow from "@/modules/users/components/users-show.vue";
import PeoplesStore from "@/modules/peoples/peoples-store";

export default {
  name: "Usuários",
  data() {
    return {
      title: "Usuários",
      single: "Usuário",
      items: [
        {text: "Home", to: {name: "home"}},
        {text: "Usuários", active: true},
      ],
      table: [
        {label: "ID", key: "id"},
        {label: "Nome", key: "name"},
        {label: "E-mail", key: "email"},
        {label: "Documento", key: "document"},
        {label: "Criação", key: "created_at"},
        {label: "Atualização", key: "updated_at"},
        {label: "...", key: "btn"},
      ],
      list: [],
      showNewForm: false,
      loadingForm: false,
      optionsType: [
        {value: 1, text: "Administrador"},
        {value: 2, text: "Cliente"},
        {value: 3, text: "Gênesis Admin"},
        {value: 4, text: "Gênesis Usuário"},
      ],
      optionsPeoples:[],
      paginate: {
        total_pages: 1,
        page: 1,
        perPage: 10,
        total: 0,
      },
      loadingPage: false,
      buscar: "",
      form: {
        id: null,
        id_people: null,
        name: null,
        email: null,
        type: null,
        password: null,
        document: null,
        password_confirmation: null,
      }
    }
  },
  components: {UsersShow, UsersRemove, UsersDelete, UsersPaginate, Layout, PageHeader},
  mounted() {
    this.getPaginate(this.paginate.page);
  },
  watch: {
    'form.name': function (newValue) {
      this.form.identifier = this.$slug_format(newValue);
    }
  }, methods: {
    loadPoples(){

      PeoplesStore.all().then((response) => {
        this.optionsPeoples = response.data.data.map((item) => {
          return {
            value: item.id,
            text: item.name,
          }
        });
      }).catch((error) => {
        console.log(error);
      });

    },
    getPaginate(e = 1) {
      this.loadPoples();
      this.paginate.page = e;
      this.loadingPage = true;
      UsersStore.paginate({
        page: this.paginate.page,
        limit: this.paginate.perPage,
        search: this.buscar,
      }).then((response) => {
        const {data, limit, page, total, total_pages} = response.data;
        this.list = data;
        this.paginate.total = total;
        this.paginate.perPage = limit;
        this.paginate.page = page;
        this.paginate.total_pages = total_pages;
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.loadingPage = false;
      });
    },
    cleanForm() {
      this.form = {
        id: null,
        id_people: null,
        name: null,
        email: null,
        type: null,
        password: null,
        document: null,
        password_confirmation: null,
      }
    },
    edit(item) {
      this.form = JSON.parse(JSON.stringify(item));
      delete this.form.password;
      delete this.form.password_confirmation;
      this.showNewForm = true;
    },
    save(event) {
      event.preventDefault();
      this.loadingForm = true;



      if (this.form.id) {
        UsersStore.update(this.form).then((response) => {
          this.form = response.data;
          this.getPaginate(1);
          this.cleanForm();
          this.showNewForm = false;
          Swal.fire({
            icon: "success",
            title: "Atualizado",
            text: "Usuário atualizado com sucesso",
          });
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao atualizar",
            text: "Erro ao atualizar o Usuário",
          });
        }).finally(() => {
          this.loadingForm = false;
        });
      } else {
        UsersStore.create(this.form).then((response) => {
          this.form = response.data;
          this.getPaginate(1);
          this.cleanForm();
          this.showNewForm = false;
          Swal.fire({
            icon: "success",
            title: "Criado",
            text: "Usuário criado com sucesso",
          });
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao criar",
            text: "Erro ao criar o Usuário",
          });
        }).finally(() => {
          this.loadingForm = false;
        });
      }
    }
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <b-overlay :show="loadingPage">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h1>{{ title }}</h1>

              <div class="row mb-4 align-items-center">
                <div class="col-md-5">
                  <div class="row align-items-center">
                    <div class="col-md-6">
                      <input
                          v-model="buscar"
                          type="text"
                          placeholder="Pesquisar...."
                          class="form-control"
                          @change="getPaginate(1)"
                      />
                      <span class="d-block d-md-none"></span>
                    </div>
                    <div class="col-6 col-md-3">
                      <button @click="getPaginate(1)" type="button" class="btn btn-light btn-block">
                        <i class="fa fa-search"></i> Buscar
                      </button>
                    </div>
                    <div class="col-6 col-md-3">
                      <button @click="buscar=null; getPaginate(1) " class="btn btn-light btn-block">
                        <i class="fa fa-broom"></i> Limpar
                      </button>
                    </div>
                  </div>
                </div>

                <div class="col-md-7 mb-2 text-right align-items-center">
                  <span class="d-block d-md-none p-2"> </span>
                  <div>
                    <button class="btn btn-primary" @click="showNewForm = true">
                      <i class="fa fa-plus"></i>
                      <span v-if="form.id">Editando</span>
                      <span v-if="!form.id">Novo</span>
                    </button>
                  </div>
                  <div v-show="showNewForm">
                    <div class="box-form text-left">
                      <div id="form-novo-edit">
                        <i @click="showNewForm = false; cleanForm();" class="fa float-right fa-2x fa-eye-slash"></i>
                        <h1 class="mt-5 mb-5" v-if="!form.id">Nova {{ single }}</h1>
                        <h1 class="mt-5 mb-5" v-if="form.id">Editando {{ single }}</h1>
                        <div>
                          <b-overlay :show="loadingForm">
                            <b-form @submit="save" class="was-validated">


                              <b-form-group label="Pessoa" label-for="input-1" description="Selecione uma pessoa">
                                <b-form-select :options="optionsPeoples" v-model="form.id_people" required></b-form-select>
                              </b-form-group>
                              <b-form-group label="Tipo" label-for="input-1" description="tipo de usuário">
                                <b-form-select :options="optionsType" v-model="form.type" required></b-form-select>
                              </b-form-group>
                              <b-form-group label="Nome" label-for="input-1" description="Nome completo">
                                <b-form-input v-model="form.name" required></b-form-input>
                              </b-form-group>
                              <b-form-group label="Documento" label-for="input-1" description="Documento (CPF)">
                                <b-form-input v-model="form.document" required></b-form-input>
                              </b-form-group>
                              <b-form-group label="E-mail" label-for="input-2" description="E-mail">
                                <b-form-input v-model="form.email" required></b-form-input>
                              </b-form-group>
                              <b-form-group label="Senha" label-for="input-2" description="********">

                                <b-form-input v-if="parseInt(form && form.id ? form.id : 0) > 0" type="password" v-model="form.password"   ></b-form-input>
                                <b-form-input v-if="parseInt(form && form.id ? form.id : 0) <= 0" type="password" v-model="form.password"  required ></b-form-input>
                              </b-form-group>
                              <b-form-group label="Confirmação de senha" label-for="input-2" description="********">
                                <b-form-input v-if="parseInt(form && form.id ? form.id : 0) > 0"   type="password" v-model="form.password_confirmation"  ></b-form-input>
                                <b-form-input v-if="parseInt(form && form.id ? form.id : 0) <= 0"  type="password" v-model="form.password_confirmation" required ></b-form-input>
                              </b-form-group>


                              <b-button type="submit" variant="primary">
                                <span v-if="form.id">Salvar alterações</span>
                                <span v-if="!form.id">Cadastrar novo</span>
                              </b-button>
                            </b-form>
                          </b-overlay>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <users-paginate :paginate="paginate" @change-page="getPaginate"></users-paginate>
               <b-table striped hover :items="list" :fields="table" class="table-responsive">
                <template v-slot:cell(updated_at)="data">
                  {{ data.item.created_at | f_date_time }}
                </template>
                <template v-slot:cell(created_at)="data">
                  {{ data.item.created_at | f_date_time }}
                </template>
                <template v-slot:cell(btn)="data">
                  <button @click="edit(data.item)" class="btn pl-4 pr-4 btn-sm btn-info"><i class="fa fa-edit"></i> Editar</button>
                  <users-delete :value="data.item" @change="getPaginate(1)"></users-delete>
                  <users-remove :value="data.item" @change="getPaginate(1)"></users-remove>
                  <users-show :value="data.item"></users-show>
                </template>
              </b-table>
              <users-paginate :paginate="paginate" @change-page="getPaginate"></users-paginate>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </Layout>
</template>

<style scoped>

</style>

