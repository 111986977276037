
<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import BudgetsDevolutionsBooksStore from "@/modules/budgets-devolutions-books/budgets-devolutions-books-store";
import BudgetsDevolutionsBooksPaginate from "@/components/paginate.vue";
import Swal from "sweetalert2";
import BudgetsDevolutionsBooksDelete from "@/modules/budgets-devolutions-books/components/budgets-devolutions-books-delete.vue";
// import BudgetsDevolutionsBooksRemove from "@/modules/budgets-devolutions-books/components/budgets-devolutions-books-remove.vue";
import BudgetsDevolutionsBooksShow from "@/modules/budgets-devolutions-books/components/budgets-devolutions-books-show.vue";
import BudgetsStore from "@/modules/budgets/budgets-store";
import BooksStore from "@/modules/books/books-store";
import BudgetsDevolutionsStore from "@/modules/budgets-devolutions/budgets-devolutions-store";

export default {
  name: "Livros de devoluções de pedidos",
  data() {
    return {
      title: "Livros de devoluções de pedidos",
      single: "Livros de devoluções de pedidos",
      items: [
        {text: "Home", to: {name: "home"}},
        {text: "Livros de devoluções de pedidos", active: true},
      ],
      table: [
        {label: "Quantidade devolvida", key: "dtq"},
        {label: "Valor do item", key: "budgets_devolutions.value"},
        {label: "Pedido", key: "budgets_devolutions.budgets.title"},
        {label: "Livro", key: "budgets_devolutions.budgets.budgets_books"},
        {label: "Criação", key: "budgets_devolutions.created_at"},
        {label: "Atualização", key: "budgets_devolutions.updated_at"},
        {label: "...", key: "btn"},
      ],
      list: [],
      showNewForm: false,
      loadingForm: false,
      paginate: {
        total_pages: 1,
        page: 1,
        perPage: 10,
        total: 0,
      },
      loadingPage: false,
      buscar: "",
      form: {
        id_budgets_devolutions:null,
        id_budgets_books_books:null,
        id_budgets_books_budgets:null,
        dtq:null,
      },
      optionsDevolution: [],
      optionsBooks: [],
      optionsBudgets: [],
      editando: false,
    }
  },
  components: {BudgetsDevolutionsBooksShow, /*BudgetsDevolutionsBooksRemove,*/ BudgetsDevolutionsBooksDelete, BudgetsDevolutionsBooksPaginate, Layout, PageHeader},
  mounted() {
    this.getPaginate(this.paginate.page);
    this.getBudgets();
    this.getBooks();
    this.getDevolution();
  },
  watch: {
    'form.name': function (newValue) {
      this.form.identifier = this.$slug_format(newValue);
    }
  }, methods: {
    getBudgets() {
      BudgetsStore.all().then((response) => {
        this.optionsBudgets = response.data.map((item) => {
          return {value: item.id, text: item.title};
        });
      }).catch((error) => {
        console.log(error);
      });
    },
    getBooks() {
      BooksStore.all().then((response) => {
        this.optionsBooks = response.data.data.map((item) => {
          return {value: item.id, text: item.label};
        });
      }).catch((error) => {
        console.log(error);
      });
    },
    getDevolution(){
      BudgetsDevolutionsStore.all().then((response) => {
        this.optionsDevolution = response.data.map((item) => {
          return {value: item.id, text: `Devolução do pedido: ${item.budgets.title}`};
        });
      }).catch((error) => {
        console.log(error);
      });
    },
    getPaginate(e = 1) {
      this.paginate.page = e;
      this.loadingPage = true;
      BudgetsDevolutionsBooksStore.paginate({
        page: this.paginate.page,
        limit: this.paginate.perPage,
        search: this.buscar,
      }).then((response) => {
        const {data, limit, page, total, total_pages} = response.data;
        this.list = data;
        this.paginate.total = total;
        this.paginate.perPage = limit;
        this.paginate.page = page;
        this.paginate.total_pages = total_pages;
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.loadingPage = false;
      });
    },
    cleanForm() {
      this.form = {
        id_budgets_devolutions:null,
        id_budgets_books_books:null,
        id_budgets_books_budgets:null,
        dtq:null,
      }
    },
    edit(item) {
      this.form = JSON.parse(JSON.stringify(item));
      this.showNewForm = true;
      this.editando = true;
    },
    save(event) {
      event.preventDefault();
      this.loadingForm = true;
      if (this.editando) {
        BudgetsDevolutionsBooksStore.update(this.form).then((response) => {
          this.form = response.data;
          this.getPaginate(1);
          this.cleanForm();
          this.showNewForm = false;
          Swal.fire({
            icon: "success",
            title: "Atualizado",
            text: "Livros de devoluções de pedidos atualizado com sucesso",
          });
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao atualizar",
            text: "Erro ao atualizar",
          });
        }).finally(() => {
          this.loadingForm = false;
        });
      } else {
        BudgetsDevolutionsBooksStore.create(this.form).then((response) => {
          this.form = response.data;
          this.getPaginate(1);
          this.cleanForm();
          this.showNewForm = false;
          Swal.fire({
            icon: "success",
            title: "Criado",
            text: "Livros de devoluções de pedidos criado com sucesso",
          });
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao criar",
            text: "Erro ao criar",
          });
        }).finally(() => {
          this.loadingForm = false;
        });
      }
    }
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <b-overlay :show="loadingPage">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h1>{{ title }}</h1>

              <div class="row mb-4 align-items-center">
                <div class="col-md-5">
                  <div class="row align-items-center">
                    <div class="col-md-6">
                      <input
                          v-model="buscar"
                          type="text"
                          placeholder="Pesquisar...."
                          class="form-control"
                          @change="getPaginate(1)"
                      />
                      <span class="d-block d-md-none"></span>
                    </div>
                    <div class="col-6 col-md-3">
                      <button @click="getPaginate(1)" type="button" class="btn btn-light btn-block">
                        <i class="fa fa-search"></i> Buscar
                      </button>
                    </div>
                    <div class="col-6 col-md-3">
                      <button @click="buscar=null; getPaginate(1) " class="btn btn-light btn-block">
                        <i class="fa fa-broom"></i> Limpar
                      </button>
                    </div>
                  </div>
                </div>

                <div class="col-md-7 mb-2 text-right align-items-center">
                  <span class="d-block d-md-none p-2"> </span>
                  <div>
                    <button class="btn btn-primary" @click="showNewForm = true">
                      <i class="fa fa-plus"></i>
                      <span v-if="editando">Editando</span>
                      <span v-if="!editando">Novo</span>
                    </button>
                  </div>
                  <div v-show="showNewForm">
                    <div class="box-form text-left">
                      <div id="form-novo-edit">
                        <i @click="showNewForm = false; cleanForm();" class="fa float-right fa-2x fa-eye-slash"></i>
                        <h1 class="mt-5 mb-5" v-if="!editando">Nova {{ single }}</h1>
                        <h1 class="mt-5 mb-5" v-if="editando">Editando {{ single }}</h1>
                        <div>
                          <b-overlay :show="loadingForm">
                            <b-form @submit="save" class="was-validated">

                              <b-form-group label="Devolução" label-for="input-0" description="Selecione a devolução">
                                <b-form-select id="input-0" v-model="form.id_budgets_devolutions" :options="optionsDevolution" required></b-form-select>
                              </b-form-group>
                              <b-form-group label="Livro" label-for="input-0" description="Selecione o livro">
                                <b-form-select id="input-0" v-model="form.id_budgets_books_books" :options="optionsBooks" required></b-form-select>
                              </b-form-group>
                              <b-form-group label="Pedido" label-for="input-0" description="Selecione o pedido">
                                <b-form-select id="input-0" v-model="form.id_budgets_books_budgets" :options="optionsBudgets" required></b-form-select>
                              </b-form-group>


                              <b-form-group label="Quantidade" label-for="input-2" description="Quantidade devolvida">
                                <b-form-input id="input-2" v-model="form.dtq" required></b-form-input>
                              </b-form-group>

                              <b-button type="submit" variant="primary">
                                <span v-if="editando">Salvar alterações</span>
                                <span v-if="!editando">Cadastrar novo</span>
                              </b-button>
                            </b-form>
                          </b-overlay>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <budgets-devolutions-books-paginate :paginate="paginate" @change-page="getPaginate"></budgets-devolutions-books-paginate>
              <b-table striped hover :items="list" :fields="table">
                <template v-slot:cell(budgets_devolutions.budgets.budgets_books)="data">
                  {{ data.item.budgets_devolutions.budgets.budgets_books.find(x=>x.id_books == data.item.id_budgets_books_books).books.label }}
                </template>
                <template v-slot:cell(budgets_devolutions.updated_at)="data">
                  {{ data.item.budgets_devolutions.updated_at | f_date_time }}
                </template>
                <template v-slot:cell(budgets_devolutions.created_at)="data">
                  {{ data.item.budgets_devolutions.created_at | f_date_time }}
                </template>
                <template v-slot:cell(btn)="data">
                  <button @click="edit(data.item)"  class="btn pl-4 pr-4 btn-sm btn-info" ><i class="fa fa-edit"></i> Editar </button>
                  <budgets-devolutions-books-delete :value="data.item" @change="getPaginate(1)"></budgets-devolutions-books-delete>
                  <budgets-devolutions-books-remove :value="data.item" @change="getPaginate(1)"></budgets-devolutions-books-remove>
                  <budgets-devolutions-books-show :value="data.item"></budgets-devolutions-books-show>
                </template>
              </b-table>
              <budgets-devolutions-books-paginate :paginate="paginate" @change-page="getPaginate"></budgets-devolutions-books-paginate>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </Layout>
</template>

<style scoped>

</style>

