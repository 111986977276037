<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import SchoolInput from "@/components/SchoolInput.vue";
import SchoolsStore from "@/modules/schools/schools-store";
import SchoolsInfosStore from "@/modules/schools-infos/schools-infos-store";
import Swal from "sweetalert2";
import inputsSchools from "@/modules/schools/inputsSchools";

export default {
  data: () => {
    return {
      title: "Cadastro da escola",
      items: [
        {
          text: "Escola",
          href: "/",
        },
        {
          text: "Dados",
          active: true,
        },
      ],
      form: {
        id: null,
        name: '',
      },
      fields: inputsSchools,
      perfil: null,
    }
  },
  mounted() {
    const perfilJson = localStorage.getItem("perfil");
    this.perfil = JSON.parse(atob(perfilJson));

    const id = this.$route.params.id;
    console.log('id', id);
    if (id) {
      SchoolsStore.get(id).then((response) => {
        this.form = response.data;
      });
      SchoolsInfosStore.all({id_schools: id}).then((response) => {
        response.data.data.forEach((item) => {
          console.log('item', item);
          if (this.fields[item.key]) {
            this.fields[item.key].form = item;
          }
        });
      });
    }

  },
  methods: {
    async updateConfig(value, file = false) {

      const item = this.fields[value.key];
      value.form.id_schools = this.form.id;
      value.form.key = value.key;

      if (file) {
        value.form.value = file;
      } else {
        value.form.value = value.form.value.toString();
      }

      console.log('updateConfig', value, item, file);

      item.isLoad = true;
      item.request = true;
      let response = null;

      if (value.form.id) {
        response = await SchoolsInfosStore.update({
          id: value.form.id,
          key: value.form.key,
          value: value.form.value,
        });
      } else {
        response = await SchoolsInfosStore.create(value.form);
      }
      item.isLoad = false;
      item.request = null;
      if (response.success) {
        this.fields[value.key].form = response.data;
      } else {
        Swal.fire({
          title: 'Erro',
          text: `Erro ao salvar o campo ${item.label}`,
          icon: 'error',
          confirmButtonText: 'OK',
          toast: true,
          timer: 2500,
          timerProgressBar: true,
          position: "top-end",
          showConfirmButton: false,
        });
      }

    },

    save(event) {
      event.preventDefault();
      this.loadingForm = true;
      if (this.form.id) {
        SchoolsStore.update(this.form).then((response) => {
          this.form = response.data;
          Swal.fire({
            icon: "success",
            title: "Atualizado",
            text: "Escola atualizada com sucesso",
          });
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao atualizar",
            text: "Erro ao atualizar a escola",
          });
        }).finally(() => {
          this.loadingForm = false;
        });
      } else {
        this.form.id_people = this.perfil.id_people;
        SchoolsStore.create(this.form).then((response) => {
          this.form = response.data;
          Swal.fire({
            icon: "success",
            title: "Criado",
            text: "Escola criada com sucesso",
          });
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao criar",
            text: "Erro ao criar a escola",
          });
        }).finally(() => {
          this.loadingForm = false;
        });
      }
    }

  },
  components: {Layout, PageHeader, SchoolInput},
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h1>{{ title }}</h1>


            <b-form @submit="save" class="was-validated">
              <b-form-group label="Nome da escola" label-for="input-1" description="Nome da escola completo">
                <b-form-input id="input-1" v-model="form.name" required></b-form-input>
              </b-form-group>
              <b-form-group label="Identificador unico da escola" label-for="input-2" description="Identificador da escola">
                <b-form-input id="input-2" v-model="form.iduuid" required></b-form-input>
              </b-form-group>
              <b-button type="submit" variant="primary">
                <span v-if="form.id">Salvar alterações</span>
                <span v-if="!form.id">Cadastrar novo</span>
              </b-button>
            </b-form>

            <div class="row" v-if="form.id">
              <template v-for="item in fields">
                <div v-if="item.breakLine" class="col-12" :key="item.key">
                  <br><br>
                  <h3>{{ item.titleSession }}</h3><br>
                </div>
                <div class="col-12 col-md-4 col-lg-3 col-xl-3" :key="item.key">
                  <SchoolInput :config="item" @changeUpdate="updateConfig"/>
                </div>


              </template>
            </div>

          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>

<style scoped lang="scss">

</style>
