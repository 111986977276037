<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import BudgetsInvoicesStore from "@/modules/budgets-invoices/budgets-invoices-store";
import BudgetsInvoicesPaginate from "@/components/paginate.vue";
import Swal from "sweetalert2";
import BudgetsInvoicesDelete from "@/modules/budgets-invoices/components/budgets-invoices-delete.vue";
// import BudgetsInvoicesRemove from "@/modules/budgets-invoices/components/budgets-invoices-remove.vue";
import BudgetsInvoicesShow from "@/modules/budgets-invoices/components/budgets-invoices-show.vue";
import BudgetsStore from "@/modules/budgets/budgets-store";

export default {
  name: "Notas fiscais de pedidos",
  data() {
    return {
      title: "Notas fiscais de pedidos",
      single: "Nota fiscal de pedido",
      items: [
        {text: "Home", to: {name: "home"}},
        {text: "Notas fiscais de pedidos", active: true},
      ],
      table: [
        {label: "ID", key: "id"},
        {label: "Pedido", key: "budgets.title"},
        {label: "Nota fiscal", key: "local"},
        {label: "Criação", key: "created_at"},
        {label: "Atualização", key: "updated_at"},
        {label: "...", key: "btn"},
      ],
      list: [],
      showNewForm: false,
      loadingForm: false,
      paginate: {
        total_pages: 1,
        page: 1,
        perPage: 10,
        total: 0,
      },
      loadingPage: false,
      buscar: "",
      form: {
        id: null,
        id_budgets: null,
        local: null,
      },
      optionsBudgets: [],
    }
  },
  components: {BudgetsInvoicesShow, /*BudgetsInvoicesRemove,*/ BudgetsInvoicesDelete, BudgetsInvoicesPaginate, Layout, PageHeader},
  mounted() {
    this.getPaginate(this.paginate.page);
    this.getBudgets();
  },
  watch: {
    'form.name': function (newValue) {
      this.form.identifier = this.$slug_format(newValue);
    }
  }, methods: {
    getBudgets() {
      BudgetsStore.all().then((response) => {
        this.optionsBudgets = response.data.map((item) => {
          return {value: item.id, text: item.title};
        });
      }).catch((error) => {
        console.log(error);
      });
    },
    handlerUpload(event) {
      this.form.local = event.target.files[0];
      console.log(this.form.local);
    },
    getPaginate(e = 1) {
      this.paginate.page = e;
      this.loadingPage = true;
      BudgetsInvoicesStore.paginate({
        page: this.paginate.page,
        limit: this.paginate.perPage,
        search: this.buscar,
      }).then((response) => {
        const {data, limit, page, total, total_pages} = response.data;
        this.list = data;
        this.paginate.total = total;
        this.paginate.perPage = limit;
        this.paginate.page = page;
        this.paginate.total_pages = total_pages;
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.loadingPage = false;
      });
    },
    cleanForm() {
      this.form = {
        id: null,
        id_budgets: null,
        local: null,
      }
    },
    edit(item) {
      this.form = JSON.parse(JSON.stringify(item));
      delete this.form.local;
      this.showNewForm = true;
    },
    save(event) {
      event.preventDefault();
      this.loadingForm = true;

      const formData = new FormData();
      formData.append("id_budgets", this.form.id_budgets);
      if (this.form.local)
        formData.append("local", this.form.local);
      formData.append("id", this.form.id);

      if (this.form.id) {
        BudgetsInvoicesStore.update(this.form.id, formData).then((response) => {
          this.form = response.data;
          this.getPaginate(1);
          this.cleanForm();
          this.showNewForm = false;
          Swal.fire({
            icon: "success",
            title: "Atualizado",
            text: "Nota fiscal de pedido atualizada com sucesso",
          });
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao atualizar",
            text: "Erro ao atualizar a nota fiscal de pedido",
          });
        }).finally(() => {
          this.loadingForm = false;
        });
      } else {
        BudgetsInvoicesStore.create(formData).then((response) => {
          this.form = response.data;
          this.getPaginate(1);
          this.cleanForm();
          this.showNewForm = false;
          Swal.fire({
            icon: "success",
            title: "Criado",
            text: "Nota fiscal de pedido criada com sucesso",
          });
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao criar",
            text: "Erro ao criar a nota fiscal de pedido",
          });
        }).finally(() => {
          this.loadingForm = false;
        });
      }
    }
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <b-overlay :show="loadingPage">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h1>{{ title }}</h1>

              <div class="row mb-4 align-items-center">
                <div class="col-md-5">
                  <div class="row align-items-center">
                    <div class="col-md-6">
                      <input
                          v-model="buscar"
                          type="text"
                          placeholder="Pesquisar...."
                          class="form-control"
                          @change="getPaginate(1)"
                      />
                      <span class="d-block d-md-none"></span>
                    </div>
                    <div class="col-6 col-md-3">
                      <button @click="getPaginate(1)" type="button" class="btn btn-light btn-block">
                        <i class="fa fa-search"></i> Buscar
                      </button>
                    </div>
                    <div class="col-6 col-md-3">
                      <button @click="buscar=null; getPaginate(1) " class="btn btn-light btn-block">
                        <i class="fa fa-broom"></i> Limpar
                      </button>
                    </div>
                  </div>
                </div>

                <div class="col-md-7 mb-2 text-right align-items-center">
                  <span class="d-block d-md-none p-2"> </span>
                  <div>
                    <button class="btn btn-primary" @click="showNewForm = true">
                      <i class="fa fa-plus"></i>
                      <span v-if="form.id">Editando</span>
                      <span v-if="!form.id">Novo</span>
                    </button>
                  </div>
                  <div v-show="showNewForm">
                    <div class="box-form text-left">
                      <div id="form-novo-edit">
                        <i @click="showNewForm = false; cleanForm();" class="fa float-right fa-2x fa-eye-slash"></i>
                        <h1 class="mt-5 mb-5" v-if="!form.id">Nova {{ single }}</h1>
                        <h1 class="mt-5 mb-5" v-if="form.id">Editando {{ single }}</h1>
                        <div>
                          <b-overlay :show="loadingForm">
                            <b-form @submit="save" class="was-validated">

                              <b-form-group label="Pedido" label-for="input-0" description="Selecione o pedido">
                                <b-form-select id="input-0" v-model="form.id_budgets" :options="optionsBudgets" required></b-form-select>
                              </b-form-group>

                              <b-form-group label="Upload do boleto" label-for="input-1" description="anexo do boleto">
                                <b-form-file @change="handlerUpload" v-model="form.local"></b-form-file>
                              </b-form-group>
                              <b-button type="submit" variant="primary">
                                <span v-if="form.id">Salvar alterações</span>
                                <span v-if="!form.id">Cadastrar novo</span>
                              </b-button>
                            </b-form>
                          </b-overlay>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <budgets-invoices-paginate :paginate="paginate" @change-page="getPaginate"></budgets-invoices-paginate>
              <b-table striped hover :items="list" :fields="table">
                <template v-slot:cell(updated_at)="data">
                  {{ data.item.created_at | f_date_time }}
                </template>
                <template v-slot:cell(local)="data">
                  <a :href="data.item.local" class="btn btn-primary" download target="_blank">Baixar nota</a>
                </template>
                <template v-slot:cell(created_at)="data">
                  {{ data.item.created_at | f_date_time }}
                </template>
                <template v-slot:cell(btn)="data">
                  <button @click="edit(data.item)" class="btn pl-4 pr-4 btn-sm btn-info"><i class="fa fa-edit"></i> Editar</button>
                  <budgets-invoices-delete :value="data.item" @change="getPaginate(1)"></budgets-invoices-delete>
                  <!--                  <budgets-invoices-remove :value="data.item" @change="getPaginate(1)"></budgets-invoices-remove>-->
                  <budgets-invoices-show :value="data.item"></budgets-invoices-show>
                </template>
              </b-table>
              <budgets-invoices-paginate :paginate="paginate" @change-page="getPaginate"></budgets-invoices-paginate>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </Layout>
</template>

<style scoped>

</style>

