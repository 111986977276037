// {value: 1, text: "Administrador"},
// {value: 2, text: "Cliente"},
// {value: 3, text: "Gênesis Admin"},
// {value: 4, text: "Gênesis Usuário"},

export const is_user = () => {
    try {
        return parseInt(localStorage.getItem('type_user')) === 2 ? true : false
    } catch (e) {
        return false
    }
}

export const is_admin = () => {
    try {
        return parseInt(localStorage.getItem('type_user')) === 1 ? true : false
    } catch (e) {
        return false
    }
}

export const is_genesis_user = () => {
    try {
        return parseInt(localStorage.getItem('type_user')) === 4 ? true : false
    } catch (e) {
        return false
    }
}

export const is_genesis_admin = () => {
    try {
        return parseInt(localStorage.getItem('type_user')) === 3 ? true : false
    } catch (e) {
        return false
    }
}

export const checkAcl = (acl) => {
    switch (acl) {
        case 'is_admin':
        case 'admin':
            return is_admin()
        case 'is_user':
        case 'user':
            return is_user()
        case 'is_genesis_user':
        case 'genesis_user':
            return is_genesis_user()
        case 'is_genesis_admin':
        case 'genesis_admin':
            return is_genesis_admin()
        default:
            return false
    }
}

export default (acl) => {
    try {
        // console.log('acl',acl,parseInt(localStorage.getItem('type_user')),is_admin())
        const isArray = Array.isArray(acl)
        if (isArray) {
            for (let i = 0; i < acl.length; i++) {
                if (checkAcl(acl[i])) {
                    return true
                }
            }
        }
        return checkAcl(acl)
    } catch (e) {
        return false
    }
}