export default {
    school_document: {key: 'school_document', request: null, label: "Documento da escola (CNPJ)", isLoad: false, form: {value: null}, type: "text", required: false, breakLine: true, titleSession: "Dados da escola"},
    school_date_foundation: {key: 'school_date_foundation', request: null, label: "Data de abertura", isLoad: false, form: {value: null}, type: "date", required: false},
    school_name: {key: 'school_name', request: null, label: "Razão social", isLoad: false, form: {value: null}, type: "text", required: false},
    school_fantasy_name: {key: 'school_fantasy_name', request: null, label: "Nome fantasia", isLoad: false, form: {value: null}, type: "text", required: false},
    school_comecial_name: {key: 'school_comecial_name', request: null, label: "Nome comercial", isLoad: false, form: {value: null}, type: "text", required: false},
    school_code: {key: 'school_code', request: null, label: "Código e descrição da atividade econômica principal", isLoad: false, form: {value: null}, type: "text", required: false},
    school_email: {key: 'school_email', request: null, label: "E-mail", isLoad: false, form: {value: null}, type: "email", required: false},
    school_site: {key: 'school_site', request: null, label: "Site", isLoad: false, form: {value: null}, type: "text", required: false},
    school_phone: {key: 'school_phone', request: null, label: "Telefone", isLoad: false, form: {value: null}, type: "text", required: false},
    school_whatsapp: {key: 'school_whatsapp', request: null, label: "Whatsapp", isLoad: false, form: {value: null}, type: "text", required: false},

    school_zip_code: {key: 'school_zip_code', request: null, label: "CEP", isLoad: false, form: {value: null}, type: "text", required: false, breakLine: true, titleSession: "Endereço da escola"},
    school_address: {key: 'school_address', request: null, label: "Endereço", isLoad: false, form: {value: null}, type: "text", required: false},
    school_number: {key: 'school_number', request: null, label: "Número", isLoad: false, form: {value: null}, type: "text", required: false},
    school_complement: {key: 'school_complement', request: null, label: "Complemento", isLoad: false, form: {value: null}, type: "text", required: false},
    school_neighborhood: {key: 'school_neighborhood', request: null, label: "Bairro", isLoad: false, form: {value: null}, type: "text", required: false},
    school_city: {key: 'school_city', request: null, label: "Cidade", isLoad: false, form: {value: null}, type: "text", required: false},
    school_state: {key: 'school_state', request: null, label: "Estado", isLoad: false, form: {value: null}, type: "text", required: false},
}