
import SchoolsInfosService from "@/modules/schools-infos/schools-infos-service";

export default {
    create(payload) {
        return new Promise((resolve, reject) => {
            SchoolsInfosService.create(payload).then(response => {
                resolve({
                    message: "SchoolsInfos criada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao criar SchoolsInfos",
                    success: false,
                    error
                });
            })
        })
    },
    update(payload) {
        return new Promise((resolve, reject) => {
            SchoolsInfosService.update(payload.id,payload).then(response => {
                resolve({
                    message: "SchoolsInfos atualizada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao atualizar SchoolsInfos",
                    success: false,
                    error
                });
            })
        })
    },
    remove(id) {
        return new Promise((resolve, reject) => {
            SchoolsInfosService.remove(id).then(response => {
                resolve({
                    message: "SchoolsInfos removida com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao remover SchoolsInfos",
                    success: false,
                    error
                });
            })
        })
    },
    delete(id) {
        return new Promise((resolve, reject) => {
            SchoolsInfosService.delete(id).then(response => {
                resolve({
                    message: "SchoolsInfos deletada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao deletar SchoolsInfos",
                    success: false,
                    error
                });
            })
        })
    },
    get(id) {
        return new Promise((resolve, reject) => {
            SchoolsInfosService.get(id).then(response => {
                resolve({
                    message: "SchoolsInfos encontrada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar SchoolsInfos",
                    success: false,
                    error
                });
            })
        })
    },
    paginate(query) {
        return new Promise((resolve, reject) => {
            SchoolsInfosService.paginate(query).then(response => {
                resolve({
                    message: "SchoolsInfos encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar SchoolsInfos",
                    success: false,
                    error
                });
            })
        })
    },
    all(query) {
        return new Promise((resolve, reject) => {
            SchoolsInfosService.all(query).then(response => {
                resolve({
                    message: "SchoolsInfos encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar SchoolsInfos",
                    success: false,
                    error
                });
            })
        })
    }
}
