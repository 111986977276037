
<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import BudgetsBooksStore from "@/modules/budgets-books/budgets-books-store";
import BudgetsBooksPaginate from "@/components/paginate.vue";
import Swal from "sweetalert2";
import BudgetsBooksDelete from "@/modules/budgets-books/components/budgets-books-delete.vue";
import BudgetsBooksRemove from "@/modules/budgets-books/components/budgets-books-remove.vue";
import BudgetsBooksShow from "@/modules/budgets-books/components/budgets-books-show.vue";

export default {
  name: "Livros de pedidos",
  data() {
    return {
      title: "Livros de pedidos",
      single: "Livro de pedido",
      items: [
        {text: "Home", to: {name: "home"}},
        {text: "Livros de pedidos", active: true},
      ],
      table: [
        {label: "Livro", key: "books.label"},
        {label: "Pedido", key: "budgets.title"},
        {label: "Quantidade", key: "qtd"},
        {label: "Valor", key: "value"},
        {label: "...", key: "btn"},
      ],
      list: [],
      showNewForm: false,
      loadingForm: false,
      paginate: {
        total_pages: 1,
        page: 1,
        perPage: 10,
        total: 0,
      },
      loadingPage: false,
      buscar: "",
      form: {
        id_books: null,
        id_budgets: null,
        dtq: null,
        value: null,
      },
      optionsBooks: [],
      optionsBudgets: [],
    }
  },
  components: {BudgetsBooksShow, BudgetsBooksRemove, BudgetsBooksDelete, BudgetsBooksPaginate, Layout, PageHeader},
  mounted() {
    this.getPaginate(this.paginate.page);
  },
  watch: {
    'form.name': function (newValue) {
      this.form.identifier = this.$slug_format(newValue);
    }
  }, methods: {
    getPaginate(e = 1) {
      this.paginate.page = e;
      this.loadingPage = true;
      BudgetsBooksStore.paginate({
        page: this.paginate.page,
        limit: this.paginate.perPage,
        search: this.buscar,
      }).then((response) => {
        const {data, limit, page, total, total_pages} = response.data;
        this.list = data;
        this.paginate.total = total;
        this.paginate.perPage = limit;
        this.paginate.page = page;
        this.paginate.total_pages = total_pages;
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.loadingPage = false;
      });
    },
    cleanForm() {
      this.form = {
        id_books: null,
        id_budgets: null,
        dtq: null,
        value: null,
      }
    },
    edit(item) {
      this.form = JSON.parse(JSON.stringify(item));
      this.showNewForm = true;
    },
    save(event) {
      event.preventDefault();
      this.loadingForm = true;
      if (this.form.id) {
        BudgetsBooksStore.update(this.form).then((response) => {
          this.form = response.data;
          this.getPaginate(1);
          this.cleanForm();
          this.showNewForm = false;
          Swal.fire({
            icon: "success",
            title: "Atualizado",
            text: "Livro de pedido atualizado com sucesso",
          });
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao atualizar",
            text: "Erro ao atualizar o livro de pedido",
          });
        }).finally(() => {
          this.loadingForm = false;
        });
      } else {
        BudgetsBooksStore.create(this.form).then((response) => {
          this.form = response.data;
          this.getPaginate(1);
          this.cleanForm();
          this.showNewForm = false;
          Swal.fire({
            icon: "success",
            title: "Criado",
            text: "Livro de pedido criado com sucesso",
          });
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao criar",
            text: "Erro ao criar o livro de pedido",
          });
        }).finally(() => {
          this.loadingForm = false;
        });
      }
    }
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <b-overlay :show="loadingPage">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h1>{{ title }}</h1>

              <div class="row mb-4 align-items-center">
                <div class="col-md-5">
                  <div class="row align-items-center">
                    <div class="col-md-6">
                      <input
                          v-model="buscar"
                          type="text"
                          placeholder="Pesquisar...."
                          class="form-control"
                          @change="getPaginate(1)"
                      />
                      <span class="d-block d-md-none"></span>
                    </div>
                    <div class="col-6 col-md-3">
                      <button @click="getPaginate(1)" type="button" class="btn btn-light btn-block">
                        <i class="fa fa-search"></i> Buscar
                      </button>
                    </div>
                    <div class="col-6 col-md-3">
                      <button @click="buscar=null; getPaginate(1) " class="btn btn-light btn-block">
                        <i class="fa fa-broom"></i> Limpar
                      </button>
                    </div>
                  </div>
                </div>

                <div class="col-md-7 mb-2 text-right align-items-center">
                  <span class="d-block d-md-none p-2"> </span>
                  <div>
                    <button class="btn btn-primary" @click="showNewForm = true">
                      <i class="fa fa-plus"></i>
                      <span v-if="form.id">Editando</span>
                      <span v-if="!form.id">Novo</span>
                    </button>
                  </div>
                  <div v-show="showNewForm">
                    <div class="box-form text-left">
                      <div id="form-novo-edit">
                        <i @click="showNewForm = false; cleanForm();" class="fa float-right fa-2x fa-eye-slash"></i>
                        <h1 class="mt-5 mb-5" v-if="!form.id">Nova {{ single }}</h1>
                        <h1 class="mt-5 mb-5" v-if="form.id">Editando {{ single }}</h1>
                        <div>
                          <b-overlay :show="loadingForm">
                            <b-form @submit="save" class="was-validated">


                              <b-form-group label="Pedido" label-for="input-0" description="Selecione o pedido">
                                <b-form-select id="input-0" v-model="form.id_budgets" :options="optionsBudgets" required></b-form-select>
                              </b-form-group>


                              <b-form-group label="Livro" label-for="input-0" description="Selecione o livro">
                                <b-form-select id="input-0" v-model="form.id_budgets" :options="optionsBooks" required></b-form-select>
                              </b-form-group>


                              <b-form-group label="Quantidade" label-for="input-1" description="Quantidade de livros">
                                <b-form-input id="input-1" v-model="form.dtq" required></b-form-input>
                              </b-form-group>
                              <b-form-group label="Valor vendido" label-for="input-2" description="Valor do livro que foi vendido">
                                <b-form-input id="input-2" v-model="form.value" required></b-form-input>
                              </b-form-group>
                              <b-button type="submit" variant="primary">
                                <span v-if="form.id">Salvar alterações</span>
                                <span v-if="!form.id">Cadastrar novo</span>
                              </b-button>
                            </b-form>
                          </b-overlay>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <budgets-books-paginate :paginate="paginate" @change-page="getPaginate"></budgets-books-paginate>
               <b-table striped hover :items="list" :fields="table" class="table-responsive">
                <template v-slot:cell(updated_at)="data">
                  {{ data.item.created_at | f_date_time }}
                </template>
                <template v-slot:cell(created_at)="data">
                  {{ data.item.created_at | f_date_time }}
                </template>
                <template v-slot:cell(btn)="data">
                  <button @click="edit(data.item)"  class="btn pl-4 pr-4 btn-sm btn-info" ><i class="fa fa-edit"></i> Editar </button>
                  <budgets-books-delete :value="data.item" @change="getPaginate(1)"></budgets-books-delete>
                  <budgets-books-remove :value="data.item" @change="getPaginate(1)"></budgets-books-remove>
                  <budgets-books-show :value="data.item"></budgets-books-show>
                </template>
              </b-table>
              <budgets-books-paginate :paginate="paginate" @change-page="getPaginate"></budgets-books-paginate>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </Layout>
</template>

<style scoped>

</style>

